import React, { useMemo, useState } from 'react'
import { Text, Title, Space, Group, Anchor, Collapse } from '@mantine/core'
import { Prism } from '@mantine/prism'
import { useMediumScreen } from '@/hooks'
import { StandardSimulationError, StandardTransactionError } from '@/wallet-utils'

export interface StepperModalContentProps {
  title: string
  description: React.ReactNode
  actions?: React.ReactNode
  error?: StandardTransactionError | StandardSimulationError
}

const StepperModalContent: React.FC<StepperModalContentProps> = ({ title, description, actions, error }) => {
  const { isMediumScreen } = useMediumScreen()

  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = () => {
    setIsExpanded((collapsed) => !collapsed)
  }

  const formattedJsonError = useMemo(() => {
    if (error == null) return ''
    // Allow bigint support for stringify
    // @source https://github.com/GoogleChromeLabs/jsbi/issues/30#issuecomment-521460510
    return JSON.stringify(error.raw, (_key, value) => (typeof value === 'bigint' ? value.toString() : value), 2)
  }, [error])

  return (
    <>
      <Title
        order={4}
        sx={(t) => ({
          color: t.colors.gray[9],
          paddingRight: isMediumScreen ? t.other.stepperModal.contentPaddingRightCompensationDesktop : 0
        })}>
        {title}
      </Title>

      <Space h="xs" />

      <Text
        color="gray"
        sx={(t) => ({
          paddingRight: isMediumScreen ? t.other.stepperModal.contentPaddingRightCompensationDesktop : 0
        })}>
        {description}
        {error ? (
          <>
            &nbsp;
            <Anchor href="#" onClick={handleToggle}>
              {isExpanded ? 'Collapse' : 'Expand'} raw error
            </Anchor>
            .
          </>
        ) : null}
      </Text>

      <Collapse in={isExpanded}>
        <Space h="xs" />

        <Prism
          language="json"
          copyLabel="Copy transaction error to clipboard"
          copiedLabel="Transaction error copied to clipboard">
          {formattedJsonError}
        </Prism>
      </Collapse>

      {Boolean(actions) && (
        <>
          <Space h="md" />
          <Group spacing="xs">{actions}</Group>
        </>
      )}
    </>
  )
}

export { StepperModalContent }
