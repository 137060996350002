import { useEffect } from 'react'
import { useLatestValue } from './useLatestValue'

const useTimeout = (delay: number | null, callback: Function) => {
  const ref = useLatestValue<Function>(callback)

  useEffect(() => {
    if (delay !== null) {
      const timeout = setTimeout(() => ref.current?.(), delay || 0)
      return () => clearTimeout(timeout)
    }

    return undefined
  }, [delay])
}

export { useTimeout }
