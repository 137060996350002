import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const useMediumScreen = () => {
  const theme = useMantineTheme()
  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)
  return { isMediumScreen }
}

export { useMediumScreen }
