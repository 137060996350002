import * as React from 'react'
import { SVGProps } from 'react'
const SvgBridge = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.5 14.5 1 9.75m0 0h.5m-.5 0h15.5M14.5 1 19 5.75m0 0h-.5m.5 0H3.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgBridge
