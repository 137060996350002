import { DeliverTxResponse, Event } from '@cosmjs/stargate'
import { CHAIN_IS_COSMOS_SDK_v50 } from '@/config'
import { assert, fatal } from '@/utils/errors'
import { env } from '@/utils/env'
import { Buffer } from 'buffer'
import { SelectedCoinDenom, TxEvent } from '../types'
import { parseTxRawLogs, isSendPacketEvent, isSendPacketAttributeKey, SendPacketEventObject } from './type-guards'

const TIMEOUT_MS =
  env('IBC_TIMEOUT_TESTING') === 'true'
    ? 5 * 1000 * 1000 * 1000 // 5s
    : 5 * 60 * 1000 * 1000 * 1000 // 5m

export const generateIbcTimeoutTimestamp = () => {
  return new Date().getTime() * 1_000_000 + TIMEOUT_MS
}

export const convertIbcTimeoutTimestampToDate = (timeoutTimestamp: string) => {
  return new Date(Number(timeoutTimestamp) / 1_000_000)
}

// Get the attributes of the send_packet event
// I honestly forgot why we ever introduced TxEvent - it's probably because dealing with readonly objects
// is a huge pain in the ass. For now, let's slowly revert onto using Stargate's type
export const getSendPacketAttributes = (events: (TxEvent | Event)[]): SendPacketEventObject | null => {
  // @TODO: Consider using the `convertAttributesToObject` function
  // located in @/page-components/Stake/utils.
  for (const event of events) {
    if (isSendPacketEvent(event)) {
      return event.attributes.reduce((object, attr) => {
        object[attr.key] = attr.value
        return object
      }, {} as SendPacketEventObject)
    }
  }

  // Failed transactions likely have no send_packet data.
  return null
}

// Similar to the above function to be used for events that are not yet decoded.
// @TODO: Consider using the `convertAttributesToObject` function
// located in @/page-components/Stake/utils.
export const getHashedSendPacketAttributes = (events: TxEvent[]): SendPacketEventObject | null => {
  for (const event of events) {
    if (isSendPacketEvent(event)) {
      return event.attributes.reduce((object, attr) => {
        const key = Buffer.from(attr.key, 'base64').toString()
        if (!isSendPacketAttributeKey(key)) {
          return object
        }
        object[key] = Buffer.from(attr.value, 'base64').toString()
        return object
      }, {} as SendPacketEventObject)
    }
  }

  // Failed transactions likely have no send_packet data.
  return null
}

// Previously, event attributes would be base64-hashed. However, recent changes
// to the Cosmos SDK have made it so that the attributes are no longer hashed.
// This function is a *temporary* fix to support both hashed and unhashed attributes,
// as we're not able to keep track of chains that have older versions of the Cosmos SDK.
// @TODO: Consider using the `convertAttributesToObject` function
// located in @/page-components/Stake/utils.
export const getElaborateSendPacketAttributes = (events: TxEvent[]) => {
  const hashedPackets = getHashedSendPacketAttributes(events)

  // We'll check for keys because `getHashedSendPacketAttributes` still returns a value
  // if there happens to be a send_packet event despite the lack of attributes.
  // In the future, let's consider improving `getHashedSendPacketAttributes` by making
  // it return null if there are no attributes.
  if (hashedPackets && Object.keys(hashedPackets).length) {
    return hashedPackets
  }

  return getSendPacketAttributes(events)
}

// From the return value of `stargate.broadcastTx(...)`, parse tx.logs and get the attributes of the send_packet event
// This is not intended to be used externally - you likely want to use `getSendPacketAttributesFromRawLogsOrEvents` instead.
const getTxRawLogSendPacketAttributes = (tx: DeliverTxResponse): SendPacketEventObject => {
  const logs = parseTxRawLogs(tx)

  const events = logs.flatMap((log) => {
    return log.events
  })

  const packets = getElaborateSendPacketAttributes(events)

  if (packets == null) {
    throw fatal(
      `Attempt to trace ibc status of ${tx.transactionHash} failed. 'send_packet' attributes are missing from the transaction raw log.`
    )
  }

  return packets
}

// Cosmos SDK v50 deprecated a few tx attributes like `tx.rawLog` in favor of `tx.events`
// This means we can simply reach out for the send packet attributes from the events.
// This also means that raw log parsing does not work anymore.
export const getSendPacketAttributesFromRawLogsOrEvents = (
  tx: DeliverTxResponse,
  denom: SelectedCoinDenom
): SendPacketEventObject => {
  // v50 guarantees that tx.rawLog is fully succeeded by tx.events. However, Osmosis (despite being on v0.47.8)
  // has the the same behavior as v50. Until we can guarantee that tx.rawLog being empty means that it has the
  // v50 behavior, we'll keep the `CHAIN_IS_COSMOS_SDK_v50` check here.
  if (CHAIN_IS_COSMOS_SDK_v50[denom] || tx.rawLog === '') {
    // We'll spread because dealing with `readonly` types suck
    const attributes = getSendPacketAttributes([...tx.events])
    assert(attributes, `send_packet attributes are missing from the transaction events of tx ${tx.transactionHash}.`)
    return attributes
  }

  return getTxRawLogSendPacketAttributes(tx)
}
