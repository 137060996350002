import { StdFee } from '@cosmjs/amino'
import { SignerData, DeliverTxResponse } from '@cosmjs/stargate'
import { EncodeObject } from '@cosmjs/proto-signing'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { EVMOS_CHAIN_INFO, INJ_CHAIN_INFO, ISLM_CHAIN_INFO } from '@/config'
import { Account } from '../types'
import { IbcReturnType } from './signer-types'
import { broadcastEvmosIbcTransaction, signEvmosIbcTransaction } from './signer-evmos'
import { broadcastInjectiveIbcTransaction, signInjectiveIbcTransaction } from './signer-injective'
import { assert } from '@/utils'
import { Keplr } from '@keplr-wallet/types'
import { broadcastTx, simulate } from '../utils'
import { broadcastHaqqIbcTransaction, signHaqqIbcTransaction, simulateHaqqIbcTransaction } from './signer-haqq'

// @TODO: I don't know why this is here, but consider moving to `src/types/wallet-extensions.d.ts`
declare global {
  interface Window {
    wallet: Keplr
    keplr: Keplr
    leap: Keplr
    cosmostation: {
      providers: {
        keplr: Keplr
      }
    }
  }
}

const simulateIbcTransaction = async (
  account: Account,
  address: string,
  messages: EncodeObject[],
  memo: string
): Promise<number> => {
  // It seems that `simulateEvmosIbcTransaction` is a direct replicate (at least from my
  // perspective) of `SigningStargateClient#simulate` function. It seems things are working
  // without this, but we'll keep this here for context. In case we need it soon, we can
  // always bring it back again. If we never do, let's consider removing this in the future.
  //
  // The fix was simply increasing the multiplier from `calculateFee`
  // if (account.currency.coinDenom === EVMOS_CHAIN_INFO.stakeCurrency.coinDenom) {
  //   return simulateEvmosIbcTransaction(account, address, messages, memo)
  // }

  // It seems that `simulateInjectiveIbcTransaction` is a direct replicate (at least from my
  // perspective) of `SigningStargateClient#simulate` function. It seems things are working
  // without this, but we'll keep this here for context. In case we need it soon, we can
  // always bring it back again. If we never do, let's consider removing this in the future.
  //
  // The fix was simply increasing the multiplier from `calculateFee`
  // if (account.currency.coinDenom === INJ_CHAIN_INFO.stakeCurrency.coinDenom) {
  //   return simulateInjectiveIbcTransaction(account, address, messages, memo)
  // }

  if (account.currency.coinDenom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom) {
    return simulateHaqqIbcTransaction(account, address, messages, memo)
  }

  return simulate(account.client, address, messages, memo)
}

// These are the recommended functions to sign and broadcast
// ibc transactions that could come from Evmos or Injective to Stride.

// Almost 1:1 API parity with `SigningStargateClient.sign`
//
// While this seemingly sets up Evmos + Ledger, it does not work.
// Evmos does not support Ledger yet (at least until the v12 upgrade)
// The generated endpoint function only generates endpoint to mainnet.
const signIbcTransaction = async (
  account: Account,
  address: string,
  messages: EncodeObject[],
  fee: StdFee,
  memo: string,
  explicitSignerData?: SignerData
): Promise<IbcReturnType> => {
  if (account.currency.coinDenom === EVMOS_CHAIN_INFO.stakeCurrency.coinDenom) {
    return await signEvmosIbcTransaction(account, address, messages, fee, memo, explicitSignerData)
  }

  if (account.currency.coinDenom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom) {
    return await signHaqqIbcTransaction(account, address, messages, fee, memo, explicitSignerData)
  }

  if (account.currency.coinDenom === INJ_CHAIN_INFO.stakeCurrency.coinDenom) {
    return await signInjectiveIbcTransaction(account, address, messages, fee, memo, explicitSignerData)
  }

  assert(account.client, 'Stargate client does not exist')

  const raw = await account.client.sign(address, messages, fee, memo, explicitSignerData)

  return { type: 'default', value: raw }
}

// Almost 1:1 API parity with StargateClient.broadcastTx
const broadcastIbcTransaction = async (account: Account, payload: IbcReturnType): Promise<DeliverTxResponse> => {
  if (payload.type === 'evmos' || payload.type === 'evmos-ledger') {
    return broadcastEvmosIbcTransaction(account, payload)
  }

  if (payload.type === 'haqq' || payload.type === 'haqq-ledger') {
    return broadcastHaqqIbcTransaction(account, payload)
  }

  if (payload.type === 'injective' || payload.type === 'injective-ledger') {
    return broadcastInjectiveIbcTransaction(account, payload)
  }

  assert(account.client, 'Stargate client does not exist')

  const bytes = TxRaw.encode(payload.value).finish()

  return broadcastTx(account.client, bytes)
}

export { simulateIbcTransaction, signIbcTransaction, broadcastIbcTransaction }
