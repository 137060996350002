import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { fatal } from '@/utils'
import { CosmWasmClient } from 'cosmwasm'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { SelectedCoinDenom, isSelectedCoinDenom } from '@/wallet-utils'

interface KujiraBluePosition {
  deposit_amount: string
  mint_amount: string
  interest_amount: string
  owner: string
}

const POOL_MAP = {
  stATOM: {
    contract: 'kujira19kl9ma0u7e9vdhw54mjahh052hcdwzpxmm840phffrff7v3perjsqxfajc',
    denom: 'ibc/0306D6B66EAA2EDBB7EAD23C0EC9DDFC69BB43E80B398035E90FBCFEF3FD1A87'
  },
  stOSMO: {
    contract: 'kujira1x5yjm80asepzk5w8gaswux6vul7pgpjl90q3j58xzjajls7s4kjq3vnzy9',
    denom: 'ibc/F97BDCE220CCB52139C73066E36C45EC7EDCEEF1DAFF891A34F4FBA195A2E6E8'
  }
}

const adapter =
  () =>
  async ({ pools, address }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const chain = CHAIN_INFO_LIST.KUJI
    const client = await CosmWasmClient.connect(chain.rpc)

    return await Promise.all(
      pools.map(async (pool) => {
        // @ts-ignore
        let poolConfig = POOL_MAP[pool.denom] || POOL_MAP[pool.stakedDenom]

        const position: KujiraBluePosition = await client.queryContractSmart(poolConfig.contract, {
          position: { address }
        })

        const stakedDenomUsdValue = await redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.stakedDenom)

        if (!stakedDenomUsdValue) {
          throw fatal(`Missing price for ${pool.stakedDenom}`)
        }

        const denomIsSelectedCoinDenom = isSelectedCoinDenom(pool.denom)

        return {
          poolId: pool.poolId,
          bondedAmount: new BigNumber(position.mint_amount || 0)
            .dividedBy(
              10 **
                (denomIsSelectedCoinDenom
                  ? CHAIN_INFO_LIST[pool.denom as SelectedCoinDenom].stakeCurrency.coinDecimals
                  : 6)
            )
            .times(1)
            .toNumber(),
          unbondedAmount: new BigNumber(position.deposit_amount || 0)
            .dividedBy(
              10 **
                (denomIsSelectedCoinDenom
                  ? CHAIN_INFO_LIST[pool.denom as SelectedCoinDenom].stakeCurrency.coinDecimals
                  : 6)
            )
            .times(stakedDenomUsdValue)
            .toNumber()
        }
      })
    )
  }

export { adapter }
