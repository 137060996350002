import React, { createContext, useContext, useState } from 'react'

interface ContextType {
  isConnectWalletDropdownOpen: boolean
  openConnectWalletDropdown: () => void
  closeConnectWalletDropdown: () => void
  toggleConnectWalletDropdown: () => void
}

const Context = createContext<ContextType | null>(null)

const GlobalLayoutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isConnectWalletDropdownOpen, setIsConnectWalletDropdownOpen] = useState(false)
  const openConnectWalletDropdown = () => setIsConnectWalletDropdownOpen(true)
  const closeConnectWalletDropdown = () => setIsConnectWalletDropdownOpen(false)
  const toggleConnectWalletDropdown = () => setIsConnectWalletDropdownOpen(!isConnectWalletDropdownOpen)

  return (
    <Context.Provider
      value={{
        isConnectWalletDropdownOpen,
        openConnectWalletDropdown,
        closeConnectWalletDropdown,
        toggleConnectWalletDropdown
      }}>
      {children}
    </Context.Provider>
  )
}

const useGlobalLayout = () => {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useGlobalLayout must be used within a GlobalLayoutProvider')
  }

  return context
}

export { GlobalLayoutProvider, useGlobalLayout }
