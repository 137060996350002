import React, { useState } from 'react'
import { QueryClient, QueryClientProvider as OriginalQueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export interface QueryClientContext {
  client: QueryClient
}

const QueryClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [client] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
          }
        }
      })
  )
  return (
    <OriginalQueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </OriginalQueryClientProvider>
  )
}

export default QueryClientProvider
