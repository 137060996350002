import * as React from 'react'
import { SVGProps } from 'react'
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.684 0 0 4.684v6.632L4.684 16h6.632C12.889 14.436 16 11.316 16 11.316V4.684L11.316 0M5.422 1.778h5.156l3.644 3.644v5.156l-3.644 3.644H5.422l-3.644-3.644V5.422m5.333 5.245H8.89v1.777H7.11v-1.777Zm0-7.111H8.89v5.333H7.11V3.556Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgWarning
