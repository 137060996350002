/* eslint-disable @typescript-eslint/naming-convention */
import { pubkeyType as originalPubkeyType, Pubkey, SinglePubkey } from '@cosmjs/amino'
import { encodePubkey as originalEncodePubkey, decodePubkey as originalDecodePubkey } from '@cosmjs/proto-signing'
import { fromBase64, toBase64 } from '@cosmjs/encoding'
import { PubKey as CosmosCryptoSecp256k1Pubkey } from 'cosmjs-types/cosmos/crypto/secp256k1/keys'
import { Any } from 'cosmjs-types/google/protobuf/any'

export const pubkeyType = {
  ...originalPubkeyType,
  // https://github.com/skip-mev/skip-router-sdk/pull/94/files
  ethsecp256k1: 'ethermint/PubKeyEthSecp256k1' as const
}

export interface EthSecp256k1Pubkey extends SinglePubkey {
  readonly type: 'ethermint/PubKeyEthSecp256k1'
  readonly value: string
}

// https://github.com/skip-mev/skip-router-sdk/pull/94/files
export function isEthSecp256k1Pubkey(pubkey: Pubkey): pubkey is EthSecp256k1Pubkey {
  return (pubkey as EthSecp256k1Pubkey).type === 'ethermint/PubKeyEthSecp256k1'
}

/**
 * Takes a pubkey in the Amino JSON object style (type/value wrapper)
 * and convertes it into a protobuf `Any`.
 *
 * This is the reverse operation to `decodePubkey`.
 */
export function encodePubkey(pubkey: Pubkey): Any {
  if (isEthSecp256k1Pubkey(pubkey)) {
    // https://github.com/skip-mev/skip-router-sdk/pull/94/files
    const pubkeyProto = CosmosCryptoSecp256k1Pubkey.fromPartial({
      key: fromBase64(pubkey.value)
    })
    return Any.fromPartial({
      typeUrl: '/ethermint.crypto.v1.ethsecp256k1.PubKey',
      value: Uint8Array.from(CosmosCryptoSecp256k1Pubkey.encode(pubkeyProto).finish())
    })
  }

  return originalEncodePubkey(pubkey)
}

// https://github.com/skip-mev/skip-router-sdk/pull/94/files
export function encodeEthSecp256k1Pubkey(pubkey: Uint8Array): EthSecp256k1Pubkey {
  if (pubkey.length !== 33 || (pubkey[0] !== 0x02 && pubkey[0] !== 0x03)) {
    throw new Error('Public key must be compressed secp256k1, i.e. 33 bytes starting with 0x02 or 0x03')
  }
  return {
    type: pubkeyType.ethsecp256k1,
    value: toBase64(pubkey)
  }
}

function decodeAnyToPubkey(pubkey: Any): SinglePubkey {
  switch (pubkey.typeUrl) {
    case '/ethermint.crypto.v1.ethsecp256k1.PubKey': {
      const { key } = CosmosCryptoSecp256k1Pubkey.decode(pubkey.value)
      return encodeEthSecp256k1Pubkey(key)
    }
    default:
      throw new Error(`Pubkey type_url ${pubkey.typeUrl} not recognized as single public key type`)
  }
}

export function decodePubkey(pubkey?: Any | null): Pubkey | null {
  if (!pubkey || !pubkey.value) {
    return null
  }

  switch (pubkey.typeUrl) {
    case '/ethermint.crypto.v1.ethsecp256k1.PubKey': {
      return decodeAnyToPubkey(pubkey)
    }
    default:
      return originalDecodePubkey(pubkey)
  }
}
