import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Anchor, Badge, Group, Sx } from '@mantine/core'
import { useNavigationMobileMenu } from './NavigationMobileMenuProvider'

interface NavigationMobileMenuItemProps {
  href: string
  external?: boolean
  new?: boolean
  children: React.ReactNode
}

const NavigationMobileMenuItem: React.FC<NavigationMobileMenuItemProps> = (props) => {
  const { close } = useNavigationMobileMenu()

  const { pathname } = useRouter()

  const active = pathname === props.href

  const styles: Sx = (t) => ({
    lineHeight: 1,
    color: active ? t.white : t.colors.dark[1],
    fontWeight: 'bold',
    textDecoration: 'none',
    textTransform: 'uppercase'
  })

  if (props.external) {
    return (
      <Anchor href={props.href} target="_blank" sx={styles}>
        {props.children}
      </Anchor>
    )
  }

  return (
    <Link href={props.href} passHref legacyBehavior>
      <Anchor onClick={close} sx={styles}>
        <Group spacing="xs">
          {props.children}

          {props.new && <Badge size="xs">New!</Badge>}
        </Group>
      </Anchor>
    </Link>
  );
}

export { NavigationMobileMenuItem }
