import React from 'react'
import { Button } from '@mantine/core'
import { Icon } from '@/components/Icon'
import { ellipsis } from '@/utils'
import { useGlobalLayout } from '../GlobalLayoutProvider'
import { useNavigationMobileMenu } from './NavigationMobileMenuProvider'
import { useStrideWallet, useWallet } from '@/contexts/wallet'

const NavigationMobileMenuConnectWallet: React.FC = () => {
  const strideAccount = useStrideWallet()
  const { connect, isFetchingStrideAccount: isConnectingWallet } = useWallet()

  const { openConnectWalletDropdown } = useGlobalLayout()

  const { close } = useNavigationMobileMenu()

  const handleConnect = () => {
    connect()
    close()
  }

  const handleClick = () => {
    openConnectWalletDropdown()
    close()
  }

  if (strideAccount) {
    return (
      <Button
        fullWidth
        leftIcon={<Icon name="wallet" />}
        color="dark"
        sx={{ background: '#373A40' }}
        loading={isConnectingWallet}
        onClick={handleClick}>
        {strideAccount.nsName || ellipsis(strideAccount.address, 20)}
      </Button>
    )
  }

  return (
    <Button fullWidth onClick={handleConnect} leftIcon={<Icon name="wallet" />} loading={isConnectingWallet}>
      Connect wallet
    </Button>
  )
}

export { NavigationMobileMenuConnectWallet }
