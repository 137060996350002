import axios from 'axios'
import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { redis } from '@/redis'
import { CHAIN_INFO_LIST } from '@/config'

interface OsmosisBankBalancesResponse {
  balances: Array<{ denom: string; amount: string }>
}

interface OsmosisLockedBalancesResponse {
  coins: Array<{ denom: string; amount: string }>
}

const adapter = async ({
  pools,
  address
}: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
  const chain = CHAIN_INFO_LIST.OSMO
  const [balancesResponse, lockedBalancesResponse] = await Promise.all([
    axios.get<OsmosisBankBalancesResponse>(`${chain.rest}/cosmos/bank/v1beta1/balances/${address}`),
    axios.get<OsmosisLockedBalancesResponse>(`${chain.rest}/osmosis/lockup/v1beta1/account_locked_coins/${address}`)
  ])

  return await Promise.all(
    pools.map(async (pool) => {
      // e.g., sheet_OSMOSIS_DEFI_EXCH_RATE_stOSMO/OSMO
      // it seems that the rate here is between the gamma tokens to usd
      const rate = await redis.get<number>(`sheet_OSMOSIS_DEFI_EXCH_RATE_${pool.stakedDenom}/${pool.denom}`)

      // if (rate == null) {
      //   throw fatal(`Rate for ${pool.stakedDenom}/${pool.denom} is null.`)
      // }

      const relevantBalances = balancesResponse.data.balances.find((balance) => {
        return balance.denom === `gamm/pool/${pool.poolId}`
      })

      const relevantLockedBalances = lockedBalancesResponse.data.coins.find((balance) => {
        return balance.denom === `gamm/pool/${pool.poolId}`
      })

      if (
        relevantBalances &&
        relevantLockedBalances &&
        new BigNumber(relevantLockedBalances.amount).gt(relevantBalances.amount)
      ) {
        // This should never happen, but just in case
        return {
          poolId: pool.poolId,
          unbondedAmount: 0,
          bondedAmount: new BigNumber(0).toNumber()
        }
      }

      return {
        poolId: pool.poolId,
        unbondedAmount: new BigNumber(relevantBalances ? relevantBalances.amount : 0)
          .times(rate || 0)
          .dividedBy(1e18)
          .toNumber(), // precision for gamma pool tokens is 18
        bondedAmount: new BigNumber(relevantLockedBalances ? relevantLockedBalances.amount : 0)
          .times(rate || 0)
          .dividedBy(1e18)
          .toNumber()
      }
    })
  )
}

export { adapter }
