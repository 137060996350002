import * as React from 'react'
import { SVGProps } from 'react'
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 3.6h.9A2.7 2.7 0 0 1 18 6.3v7.2a2.7 2.7 0 0 1-2.7 2.7H2.7A2.7 2.7 0 0 1 0 13.5V2.7A2.7 2.7 0 0 1 2.7 0h9a2.7 2.7 0 0 1 2.7 2.7v.9Zm-2.7-1.8h-9a.9.9 0 0 0 0 1.8h9.9v-.9a.9.9 0 0 0-.9-.9Zm4.5 9h-.9a.9.9 0 1 1 0-1.8h.9v1.8Zm-.9-3.6h.9v-.9a.9.9 0 0 0-.9-.9H2.7a2.7 2.7 0 0 1-.9-.153V13.5a.9.9 0 0 0 .9.9h12.6a.9.9 0 0 0 .9-.9v-.9h-.9a2.7 2.7 0 0 1 0-5.4Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgWallet
