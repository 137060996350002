import * as React from 'react'
import { SVGProps } from 'react'
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.81 0C19.466 0 20 .56 20 1.25s-.533 1.25-1.19 1.25H1.19C.533 2.5 0 1.94 0 1.25S.533 0 1.19 0h17.62Zm0 6.75c.657 0 1.19.56 1.19 1.25s-.533 1.25-1.19 1.25H1.19C.533 9.25 0 8.69 0 8s.533-1.25 1.19-1.25h17.62Zm0 6.75c.657 0 1.19.56 1.19 1.25S19.467 16 18.81 16H1.19C.533 16 0 15.44 0 14.75s.533-1.25 1.19-1.25h17.62Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMenu
