import React from 'react'
import { MantineProvider as OriginalMantineProvider } from '@mantine/core'
import { theme, themeDefaultStyles, themeDefaultProps } from '@/config/mantine-theme'
import { useColorScheme } from './color-scheme'

const MantineProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { colorScheme } = useColorScheme()

  return (
    <OriginalMantineProvider
      theme={{ ...theme, colorScheme }}
      styles={themeDefaultStyles}
      defaultProps={themeDefaultProps}
      withGlobalStyles
      withNormalizeCSS>
      {children}
    </OriginalMantineProvider>
  )
}

export default MantineProvider
