/**
 * IBCX portfolio config means ibc denom on Osmosis chain
 */

import { z } from 'zod'
import { SelectedCoinDenom, SelectedStakedCoinDenom } from '@/wallet-utils'

// Staking or redeeming <= 0.003 causes `simulateGas` to fail
export const IBCX_LS_MIN_THRESHOLD = 0.01

export const IBCX_CURRENCY = {
  coinDenom: 'IBCX',
  coinMinimalDenom: 'factory/osmo14klwqgkmackvx2tqa0trtg69dmy0nrg4ntq4gjgw2za4734r5seqjqm4gm/uibcx',
  coinDecimals: 6,
  coinGeckoId: 'ibcx'
} as const

export const STAKED_IBCX_CURRENCY = {
  coinDenom: 'stIBCX',
  coinMinimalDenom: 'factory/osmo1xqw2sl9zk8a6pch0csaw78n4swg5ws8t62wc5qta4gnjxfqg6v2qcs243k/stuibcx',
  coinDecimals: 6,
  // This is currently enough, but if in the future stibcx is on coin gecko, we should consider updating this.
  coinGeckoId: 'ibcx'
} as const

export const IBCX_CONTRACTS = {
  ibcx: 'osmo14klwqgkmackvx2tqa0trtg69dmy0nrg4ntq4gjgw2za4734r5seqjqm4gm',
  stIbcx: 'osmo1xqw2sl9zk8a6pch0csaw78n4swg5ws8t62wc5qta4gnjxfqg6v2qcs243k',
  periphery: 'osmo1w2kz2pmmvmvjkrah98xuw5lun5gljfr4nfw87le23ke0e8p4yp8s6la2mr',
  peripheryV2: 'osmo1wsjce2duwvuczusee7r80wnh7mlxnlj6x8kegjhtjhhwysdmsyvqqgef6k'
} as const

const IBCX_ASSETS_IBC_DENOMS = {
  AKT: 'ibc/1480B8FD20AD5FCAE81EA87584D269547DD4D436843C1D20F15E00EB64743EF4',
  ATOM: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
  AXL: 'ibc/903A61A498756EA560B85A85132D3AEE21B5DEDD41213725D22ABF276EA6945E',
  EVMOS: 'ibc/6AE98883D4D5D5FF9E50D7130F1305DA2FFA0C652D1DD9C123657C6B4EB2DF8A',
  ION: 'uion',
  JUNO: 'ibc/46B44899322F3CD854D2D46DEEF881958467CDD4B3B10086DA49296BBED94BED',
  REGEN: 'ibc/1DCC8A6CB5689018431323953344A9F6CC4D0BFB261E88C9F7777372C10CD076',
  USCRT: 'ibc/0954E1C28EB7AF5B72D24F3BC2B47BBB2FDF91BDDFD57B74B99E133AED40972A',
  STARS: 'ibc/987C17B11ABC2B20019178ACE62929FE9840202CE79498E29FE8E5CB02B7C0A4',
  STRD: 'ibc/A8CA5EE328FA10C9519DF6057DA1F69682D28F7D0F5CCC7ECB72E3DCA2D157A4',
  UMEE: 'ibc/67795E528DF67C5606FC20F824EA39A6EF55BA133F4DC79C90A8C47A0901E17C',
  OSMO: 'uosmo'
} as const

const IBCX_HAS_ST_ASSETS = ['ATOM', 'EVMOS', 'OSMO', 'JUNO', 'STARS', 'UMEE'] as const

const IBCX_ST_TOKENS = ['STATOM', 'STEVMOS', 'STJUNO', 'STOSMO', 'STSTARS', 'STUMEE'] as const

const IbcxHasStAssetsSchema = z.enum(IBCX_HAS_ST_ASSETS)

const IbcxStTokenSchema = z.enum(IBCX_ST_TOKENS)

const IBCX_FILTERED_IBC_DENOMS: Record<string, string> = Object.entries(IBCX_ASSETS_IBC_DENOMS).reduce(
  (object, [denom, ibcDenom]) => {
    return IbcxHasStAssetsSchema.safeParse(denom).success ? object : { ...object, [denom]: ibcDenom }
  },
  {}
)

const IBCX_ST_TOKEN_IBC_DENOMS = {
  STATOM: 'ibc/C140AFD542AE77BD7DCC83F13FDD8C5E5BB8C4929785E6EC2F4C636F98F17901',
  STEVMOS: 'ibc/C5579A9595790017C600DD726276D978B9BF314CF82406CE342720A9C7911A01',
  STJUNO: 'ibc/84502A75BCA4A5F68D464C00B3F610CE2585847D59B52E5FFB7C3C9D2DDCD3FE',
  STOSMO: 'ibc/D176154B0C63D1F9C6DCFB4F70349EBF2E2B5A87A05902F57A6AE92B863E9AEC',
  STSTARS: 'ibc/5DD1F95ED336014D00CE2520977EC71566D282F9749170ADC83A392E0EA7426A',
  STUMEE: 'ibc/02F196DA6FD0917DD5FEA249EE61880F4D941EE9059E7964C5C9B50AF103800F'
} as const

export const SELECTED_COIN_DENOM_BY_ST_TOKEN_DENOM: Record<keyof typeof IBCX_ST_TOKEN_IBC_DENOMS, SelectedCoinDenom> = {
  STATOM: 'ATOM',
  STEVMOS: 'EVMOS',
  STJUNO: 'JUNO',
  STOSMO: 'OSMO',
  STSTARS: 'STARS',
  STUMEE: 'UMEE'
}

const ST_IBCX_ASSETS_IBC_DENOMS = {
  ...IBCX_ST_TOKEN_IBC_DENOMS,
  ...IBCX_FILTERED_IBC_DENOMS
}

// Display-only assets in the stats component
export const IBCX_PORTFOLIO_ASSETS: Partial<SelectedStakedCoinDenom>[] = [
  'stATOM',
  'stOSMO',
  'stSTARS',
  'stJUNO',
  'stEVMOS',
  'stUMEE'
]

export const getIbcxPortfolioAssets = ({ isStIbcx = false }: { isStIbcx: boolean }) => {
  return isStIbcx ? ST_IBCX_ASSETS_IBC_DENOMS : IBCX_ASSETS_IBC_DENOMS
}

const IBCX_ST_TOKEN_IBC_DENOMS_ARRAY = Object.entries(IBCX_ST_TOKEN_IBC_DENOMS)

export const getStIbcxDenomByStTokenIbcDenom = (stTokenIbcDenom: string) => {
  const entry = IBCX_ST_TOKEN_IBC_DENOMS_ARRAY.find(([_, ibcDenom]) => {
    return ibcDenom === stTokenIbcDenom
  })

  return entry ? IbcxStTokenSchema.parse(entry[0]) : undefined
}
