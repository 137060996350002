import {
  DEFAULT_THEME,
  MantineThemeOverride,
  MantineThemeColors,
  MantineTheme,
  StepperStylesParams,
  MantineProviderProps
} from '@mantine/core'

const colors = (): MantineThemeColors => {
  const colors = { ...DEFAULT_THEME.colors }
  colors.pink = [...colors.pink]
  colors.pink[6] = '#E50571'
  colors.cyan = [...colors.cyan]
  colors.cyan[0] = '#E9FCFF'

  // tinted gray palette
  colors.gray = [
    '#FAF8F9',
    '#F5F1F3',
    '#EFE9EC',
    '#E6DEE2',
    '#DACED3',
    '#BDADB5',
    '#96868F',
    '#6E5763',
    '#40343A',
    '#3C001D'
  ]

  colors.pink = [
    '#FFF8FD',
    '#FFEDF9',
    '#FFD8F3',
    '#FFAEE6',
    '#FF5FC9',
    '#F935AF',
    '#E50571',
    '#E6007A',
    '#C7026B',
    '#B0045D'
  ]

  return colors
}

const themeOther = {
  components: {
    StaticActionIcon: {
      sizes: {
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
        xl: 40
      }
    }
  },

  headingFontFamily: `'PP Telegraf','Inter',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`,

  root: {
    // We need to put some allowance on our root element to cover for pages or states that would cause a page to have no sidebar.
    // Otherwise, it would cause layout to shift a little bit as soon as  the sidebar becomes available. This is not obvious to mac machines,
    // but very obvious if you enable scrollbars on them or use a Windows machine.
    minHeight: '105vh'
  },

  textHeading: {
    fontSizeDesktop: 48,
    fontSizeMobile: 48,
    letterSpacing: '-3%'
  },

  errorBoundary: {
    logoMargin: 80,
    titleFontSize: 64,
    titleLineHeight: 1,
    titleFontSpacing: -4,
    descriptionFontSize: 24
  },

  stepperModal: {
    bodyWidth: 712,
    wizardWidth: 304,
    contentWidth: 408,
    contentPaddingDesktop: 64,
    // Make space for the "Go to Astroport" for "Add to DEX" feature
    // because both buttons on the last step take too much space.
    // To do that, we'll decrease the padding a bit and compensate
    // by applying it directly on the children who don't need the space.
    contentPaddingRightDesktop: 56,
    // contentPaddingDesktop - contentPaddingRightDesktop
    // Makes it so padding is technically still 64, but not for the buttons.
    contentPaddingRightCompensationDesktop: 8,
    separatorMinHeightMobile: 16,
    stepsHeightMobile: '20%'
  },

  stakeForm: {
    // Ensures that resulting amount is aligned with the input value
    amountPaddingRight: 20
  },

  footer: {
    bg: '#212529',
    // @TODO: https://stridelabs.atlassian.net/jira/software/projects/IN/boards/5?selectedIssue=IN-83
    fontFamily: `'Space Grotesk', sans-serif`,
    contentPaddingTopDesktop: 48,
    contentPaddingTopMobile: 24,
    contentPaddingBottomDesktop: 64,
    contentPaddingBottomMobile: 48,
    logoMarginTop: 30,
    logoOpacity: 0.24,
    logoMaxHeight: 64,
    dividerColor: '#525252',
    linkTransition: 'color 250ms ease',
    copyrightPaddingDesktop: 48,
    copyrightColor: '#7a7a7a'
  },

  zIndex: {
    // Make it stand out in scenarios where we have multi-modal like
    // in the lsm tutorial flow; step 2 shows a 'connect wallet' cta.
    walletModal: 250,
    // Modal is 200, and we need it to be above it
    // So the banner is clickable while in the staking flow.
    topBanner: 300,
    notifications: 3000
  },

  statusBadge: {
    fontSizeMobile: 11,
    fontSizeDesktop: 12
  },

  stakeStatsCard: {
    paddingBottom: 20
  },

  globalWalletConnectionSelectWalletModal: {
    logoSize: 48,
    textLineHeight: 1
  },

  globalWalletConnectionWalletInstallModal: {
    logoMaxWidth: 140,
    titleLineHeight: 1
  },

  globalWalletConnectionWcQrModal: {
    logoMaxWidth: 140,
    titleLineHeight: 1
  },

  airdropPage: {
    width: 700,
    // 540 is the actual width to take into account for the padding
    narrowWidth: 604
  },

  airdropProgress: {
    ringSize: 134,
    ringThickness: 12,
    labelFontSize: 20
  },

  airdropTask: {
    desktopCardVerticalPadding: 20,
    iconSize: 56,
    titleMargin: 6
  },

  airdropWalletConnect: {
    mobileGap: 16,
    desktopGap: 32,
    contentMaxWidth: 320,
    headingMobileWidth: 248,
    headingFontSize: 40,
    headingMobileFontSize: 32,
    durationFontSize: 46,
    secondsFontSize: 18,
    secondsLetterSpacing: -1
  },

  airdropClaim: {
    newEntrySize: 6
  },

  airdropQualifiedChainCard: {
    iconDesktopSize: 56,
    iconMobileSize: 40
  },

  globalAnnouncement: {
    padding: 16
  }
}

const themeDefaultProps = {
  Container: {
    sizes: {
      xs: 320,
      sm: 560,
      md: 960,
      lg: 1140,
      xl: 1320
    }
  },

  Text: {
    size: 'sm'
  },

  Tabs: {
    variant: 'unstyled'
  },

  Stepper: {
    color: 'cyan'
  }
}

const themeDefaultStyles: MantineProviderProps['styles'] = {
  Button: (t: MantineTheme) => ({
    root: {
      fontWeight: 700,
      borderRadius: t.radius.md
    },
    outline: {
      '&:hover': {
        background: 'transparent'
      }
    }
  }),

  NumberInput: (t: MantineTheme) => ({
    defaultVariant: {
      '&:focus:not(.mantine-NumberInput-invalid)': {
        borderColor: t.fn.variant({ variant: 'filled', color: 'blue' }).background
      }
    },

    error: {
      fontSize: t.fontSizes.sm
    }
  }),

  TextInput: (t: MantineTheme) => ({
    defaultVariant: {
      '&:focus:not(.mantine-TextInput-invalid)': {
        borderColor: t.fn.variant({ variant: 'filled', color: 'blue' }).background
      },
      '&.mantine-TextInput-invalid::placeholder': {
        color: t.fn.rgba(t.colors.red[6], 0.4)
      }
    },

    error: {
      fontSize: t.fontSizes.sm
    }
  }),

  Tabs: (t: MantineTheme) => ({
    tabsListWrapper: { padding: 4, background: t.colors.gray[1], borderRadius: t.radius.xl },
    tabControl: { width: '50%', fontSize: t.fontSizes.md, borderRadius: t.radius.xl },
    tabActive: { background: t.white, boxShadow: t.shadows.xs }
  }),

  Stepper: (t: MantineTheme, params: StepperStylesParams) => ({
    stepIcon: {
      color: t.colors.gray[6],
      background: t.white,
      borderColor: t.colors.gray[2]
    },

    stepProgress: {
      [`& div[class$="stepIcon"]`]: {
        color:
          params.color === 'red' || params.color === 'post-error-yellow'
            ? t.white
            : t.fn.variant({ variant: 'filled', color: params.color }).background,
        background:
          params.color === 'red' ? t.colors.red[8] : params.color === 'post-error-yellow' ? t.colors.yellow[6] : '',
        borderColor:
          params.color === 'red' ? t.colors.red[8] : params.color === 'post-error-yellow' ? t.colors.yellow[6] : ''
      }
    },

    stepDescription: {
      marginTop: 0,
      lineHeight: 1.5,
      color: t.colors.gray[7]
    },

    separator: {
      [t.fn.smallerThan('md')]: {
        minHeight: t.other.stepperModal.separatorMinHeightMobile
      }
    },

    steps: {
      [t.fn.smallerThan('sm')]: {
        height: t.other.stepperModal.stepsHeightMobile,
        maxHeight: 100,
        overflowY: 'scroll'
      }
    }
  }),

  Divider: (t: MantineTheme) => ({
    horizontal: {
      borderTopColor: t.colors.gray[2]
    }
  }),

  Popover: (t: MantineTheme) => ({
    body: {
      borderRadius: t.radius.md
    }
  })
}

const theme: MantineThemeOverride = {
  ...DEFAULT_THEME,

  defaultRadius: 'md',

  fontFamily: `'Inter', -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`,

  headings: {
    fontFamily: `'Inter',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`
  },

  colors: {
    ...colors()
  },

  primaryColor: 'pink',

  primaryShade: {
    light: 6,
    dark: 8
  },

  spacing: {
    xs: 8,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40
  },

  breakpoints: {
    xs: 320,
    sm: 480,
    lg: 1120,
    md: 882,
    xl: 1400
  },

  other: themeOther
}

export { theme, themeDefaultProps, themeDefaultStyles, themeOther }
