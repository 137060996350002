// const stepper = useStepper({
//   steps,
//   isOpen,
//   isComplete,
//   onClose,
//   onComplete,
//   error,
//   loading
// })

import React, { createContext, useState, useContext, useRef } from 'react'
import { useLatestValue } from '@/hooks'
import { delay } from '@/utils'

// stepper.onForceClose
// stepper.start()
// stepper.nextStep()
// stepper.jumpStep()
// stepper.repeatStep()
// stepper.complete()

// <StepperProvider {...stepper}>
//   <StepperModal>
//     <StakeModalStepOne />
//     <StakeModalStepTwo />
//     <StakeModalStepThree />
//     <StakeModalStepFour />
//   </StepperModal>
// </StepperProvider>

export interface UseStepperParamsOnClose {
  isOnGoing: boolean
  isComplete: boolean
}

interface UseStepperParams {
  steps: React.ReactNode[]
  // Turns the active step to have a red color and error icon
  error: boolean
  // Turns the active step to have a yellow border.
  // Intended for IBC transactions that take too long.
  // Often used in tandem with `isLoading`.
  warning: boolean
  isOpen: boolean
  // Displays a spinning icon on the active step.
  isLoading: boolean
  // Modifies the "last" icon to be a warning icon.
  // Initially intended for the last step of LSMLiquidStake which
  // can result to a failure, but can be retried.
  isPostError: boolean
  // @TODO: Not being used, consider removing
  isComplete: boolean
  onClose: (state: UseStepperParamsOnClose) => void
  onComplete?: () => void
}

interface UseStepperPayload {
  // @TODO: Might make sense to move all params under a single object
  // so it's simple to understand which props are being drilled down
  // and which props are actually initialized/provided by the Stepper.
  activeStep: number
  stepKey: number
  steps: React.ReactNode[]
  error: boolean
  warning: boolean
  isOpen: boolean
  // @TODO: Consider making a `status` property to replace most of these.
  isLoading: boolean
  isPostError: boolean
  isOnGoing: boolean
  isComplete: boolean
  reinitialize: () => void
  start: () => void
  nextStep: () => void
  previousStep: () => void
  repeatStep: () => void
  jumpStep: (step: number) => void
  complete: () => void
  // Use this for the final step when the whole flow completes.
  // This method does not fire if the flow is on-going or has not completed yet
  close: () => void
  // Use this for everything else; if you want the user to exit the modal
  // while it has not completed yet.
  forceClose: () => void
  // This lets stepper components run step-specific callback when modal gets closed during that step.
  // Use-case: StakeModalStepThree can append a pending transaction when the modal closes (aka user "minimizes" the modal).
  // We reset this whenever a user goes to another step so it doesn't fire if it's not overriden by the the following step.
  handleClose: (callback: () => void) => void
}

const StepperProviderContext = createContext<UseStepperPayload>({} as UseStepperPayload)

const useStepper = (params: UseStepperParams): UseStepperPayload => {
  const [activeStep, setActiveStep] = useState<number>(0)

  // Essentially save as activeStep but this allows us to remount a step,
  // purposely so we can retrigger effects.
  const [stepKey, setStepKey] = useState<number>(0)

  const [isOnGoing, setIsOnGoing] = useState(false)

  // @TODO: Would be nice to make consolidate both "on-going" and "is-complete"
  // into a single state with values "uninitialized", "pending", and "complete"
  const [isComplete, setIsComplete] = useState(false)

  // This lets stepper components run step-specific callback when modal gets closed during that step.
  // Use-case: StakeModalStepThree can append a pending transaction when the modal closes (aka user "minimizes" the modal).
  // We reset this whenever a user goes to another step so it doesn't fire if it's not overriden by the the following step.
  const closeCallbackRef = useRef<Function | null>(null)

  // Provide a fresh reference to the latest value of `params.isOpen`
  // so we can use it in our effects without having to worry about stale values.
  // We currently use this so modal doesn't unintentionally proceed while closed;
  // this may happen when a promise gets resolved and the modal is already closed.
  const isOpenRef = useLatestValue(params.isOpen)

  const lastStep = params.steps.length - 1

  // This is helpful for when you want to either restart the entire flow (used
  // for rate limiting broadcast failure in "Withdraw St Token" flow) or to reset
  // state when closing the modal.
  //
  // If you're using this for the latter, make sure to call this after the modal is closed.
  const reinitialize = () => {
    closeCallbackRef.current = null
    setActiveStep(0)
    setIsOnGoing(false)
    setIsComplete(false)
  }

  const reset = async () => {
    // We'll delay the state reset for a whole 200 seconds which is
    // roughly the same closing duration for the modal.
    // There's a small window while the closing animation is on-going
    // that would remount the first step, unintentionally triggering effects.
    // This also avoids the modal from flashing any changes.
    await delay(500)
    reinitialize()
  }

  const start = () => {
    if (!isOpenRef.current) return
    setIsOnGoing(true)
  }

  const nextStep = () => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep((activeStep) => Math.min(activeStep + 1, lastStep))
  }

  const previousStep = () => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep((activeStep) => Math.max(activeStep - 1, 0))
  }

  const jumpStep = (nextStep: number) => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep(nextStep)
  }

  // This lets a stepper component to remount itself
  // this means having to re-run mount effects and reinitialize state
  // We're using something different currently, so let's revisit this in the future.
  const repeatStep = () => {
    if (!isOpenRef.current) return
    setStepKey((stepKey) => stepKey + 1)
  }

  const complete = () => {
    if (!isOpenRef.current) return
    jumpStep(lastStep)
    setIsOnGoing(false)
    setIsComplete(true)
  }

  const close = () => {
    if (isOnGoing) return
    if (isComplete) params.onComplete?.()
    closeCallbackRef.current?.()
    params.onClose({ isOnGoing, isComplete })
    reset()
  }

  const forceClose = () => {
    closeCallbackRef.current?.()
    params.onClose({ isOnGoing, isComplete })
    reset()
  }

  const handleClose = (callback: () => void) => {
    closeCallbackRef.current = callback
  }

  return {
    activeStep,
    stepKey,
    steps: params.steps,
    error: params.error,
    warning: params.warning,
    isOpen: params.isOpen,
    isLoading: params.isLoading,
    isPostError: params.isPostError,
    isOnGoing,
    isComplete,
    reinitialize,
    start,
    nextStep,
    previousStep,
    jumpStep,
    repeatStep,
    complete,
    close,
    forceClose,
    handleClose
  }
}

const StepperProvider: React.FC<UseStepperPayload & { children: React.ReactNode }> = ({ children, ...params }) => {
  return <StepperProviderContext.Provider value={params}>{children}</StepperProviderContext.Provider>
}

const useStepperContext = () => {
  return useContext(StepperProviderContext)
}

export { useStepper, StepperProvider, useStepperContext }
