import React from 'react'
import { Loader, useMantineTheme } from '@mantine/core'

interface InlineLoaderProps {
  mode?: 'light' | 'dark'
}

const InlineLoader: React.FC<InlineLoaderProps> = ({ mode = 'dark' }) => {
  const theme = useMantineTheme()

  return <Loader variant="dots" color={mode === 'light' ? theme.white : 'dark'} size="xs" />
}

export { InlineLoader }
