// @source https://github.com/srph/chunk-pattern/blob/master/index.js
// Slices the array into chunks based on the pattern.
// Useful for intentionally splitting an array into rows and columns on a specific pattern.
// e.g., display 4 on top, 2 on bottom.
// @use chunkPattern([1, 2, 3, 4, 5], [2, 3]) => [[1, 2], [3, 4, 5]]
// @use chunkPattern([1, 2, 3, 4, 5], [4, 1]) => [[1, 2, 3, 4], [5]]
const chunkPattern = <T>(array: readonly T[], pattern: number[]): Array<T[]> => {
  const result: Array<T[]> = [[]]
  let patternIndex: number = 0
  let patternCount: number = pattern[0]

  array.forEach((item: any) => {
    if (patternCount === 0) {
      result.push([])
      patternIndex = (patternIndex + 1) % pattern.length
      patternCount = pattern[patternIndex]
    }

    const last = result[result.length - 1]
    last.push(item)
    patternCount--
  })

  return result
}

const includes = <T>(array: T[], callback: (value: T) => boolean) => {
  return Boolean(array.find(callback))
}

export { chunkPattern, includes }
