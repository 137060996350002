import * as React from 'react'
import { SVGProps } from 'react'
const SvgArrowHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.667 3.667H13L10.333 1m0 5.333H1L3.667 9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgArrowHorizontal
