import React from 'react'
import Link from 'next/link'
import { Anchor, Group, Text, Badge } from '@mantine/core'
import { Icon, IconName } from '@/components'
import { useRouter } from 'next/router'

interface SidebarLiteLinkProps {
  href: string
  icon: IconName
  label: string
  new?: boolean
}

const SidebarLiteLink: React.FC<SidebarLiteLinkProps> = (props) => {
  const { pathname } = useRouter()

  const active = pathname === props.href

  return (
    <Link href={props.href} passHref legacyBehavior>
      <Anchor
        variant="text"
        sx={(t) => ({
          display: 'block',
          width: '100%',
          background: active ? '#FAF8F9' : 'transparent',
          borderRadius: t.radius.md,
          '&:hover': { background: '#FAF8F9' }
        })}
        px="xs">
        <Group position="apart" sx={{ height: 40 }} spacing={0}>
          <Group spacing={12}>
            <Icon name={props.icon} sx={(t) => ({ color: t.colors.gray[7] })} size={22} />

            <Text inline size="sm" sx={(t) => ({ color: t.colors.gray[7] })} weight={500}>
              {props.label}
            </Text>
          </Group>

          {props.new && (
            <Badge size="xs" color="pink" sx={(t) => ({ background: t.colors.pink[1] })}>
              New!
            </Badge>
          )}
        </Group>
      </Anchor>
    </Link>
  );
}

export { SidebarLiteLink }
