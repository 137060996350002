import { rollbar } from '@/rollbar'

export class StrideFatalError extends Error {}

// Alias for throwing new error so intent is clear that
// code path is a type guard and it's unlikely to happen.
// But on the very, very rare chance it does, we'll throw.
//
// For asynchronous operations (only done with react-query), this will simply notify us through Rollbar.
// For everything else, we'll send a Rollbar notification + our error boundary will take care of it.
export const fatal = (message: string) => {
  const error = new StrideFatalError(message)
  rollbar.error(error)
  return error
}

// Log error on development. Send a Rollbar notification for awareness.
export const log = (e: string) => {
  rollbar.error(e)

  if (process.env.NODE_ENV === 'development') {
    console.error(e)
  }
}

// Alias for simply logging the error so intent is clear that
// we don't necessarily want to act on this error.
export const disregard = (e: unknown) => {
  console.error(e)
}

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
//
// @source https://github.com/alexreardon/tiny-invariant/blob/master/src/tiny-invariant.ts
export function assert(
  condition: any,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message: string
): asserts condition {
  if (condition) {
    return
  }

  throw fatal(message)
}
