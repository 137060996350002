import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { fatal } from '@/utils'
import { CosmWasmClient } from 'cosmwasm'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { SelectedCoinDenom } from '@/wallet-utils'
import { getDenomsFromPoolId } from './utils'

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: Consider fetching this through Shade API or contracts if possible
const DEX_POOL_MAP = {
  'stATOM/ATOM': {
    protocol: 'SCRT',
    // LP Contract
    pool_contract: 'secret1a65a9xgqrlsgdszqjtxhz069pgsh8h4a83hwt0',
    // LP Token
    token_contract: 'secret1kmjr03phgn4v4u0altvvuc53lfmy033wmvddy5',
    // Stake LP Token
    stake_contract: 'secret1hh9kgm00kfcjc78kefsf29g0fvxnd3f2tt9lrs'
  }
}

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: Consider fetching this through Shade API or contracts if possible
const LENDING_POOL_MAP = {
  stATOM: {
    contract: 'kujira19kl9ma0u7e9vdhw54mjahh052hcdwzpxmm840phffrff7v3perjsqxfajc',
    denom: 'ibc/A0E80E59956C754F1D9CB37234D13E0CF2949E7254896359F284512FA8428E18'
  },
  stOSMO: {
    contract: 'kujira1x5yjm80asepzk5w8gaswux6vul7pgpjl90q3j58xzjajls7s4kjq3vnzy9',
    denom: 'ibc/B0988C39E7418C644FDFD41682A59D22DCAD1BCC7A6429B2EAAA195FB726A2D7'
  }
}

// @TODO: This isn't working at all. It's likely we either [we forgot to put in the details]
// or [this was supposedly a "coming soon"]. Eitherway, we need to talk to Shade team and ask
// how this works.
const dex_adapter =
  () =>
  async ({ pools }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const poolShares = Object.fromEntries(
      await Promise.all(
        Object.entries(DEX_POOL_MAP)
          .filter(([poolKey]) => pools.find((pool) => `${pool.stakedDenom}/${pool.denom}` === poolKey))
          .map(async ([poolKey, poolData]) => {
            const { stakedDenom, denom } = getDenomsFromPoolId(poolKey)
            // @ts-ignore
            const protocol: Partial<SelectedCoinDenom> = poolData.protocol
            const protocolChain = CHAIN_INFO_LIST[protocol]

            const [_, stakedDenomUsdValue, denomUsdValue] = await Promise.all([
              CosmWasmClient.connect(protocolChain.rpc),
              redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + stakedDenom),
              redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + denom)
            ])

            if (!stakedDenomUsdValue || !denomUsdValue) {
              throw fatal(`Missing price for ${stakedDenom} or ${denom}`)
            }

            // @TODO: This isn't working at all. It's likely we either [we forgot to put in the details]
            // or [this was supposedly a "coming soon"]. Eitherway, we need to talk to Shade team and ask
            // how this works.
            return [
              poolKey,
              {
                bondedAmount: 0,
                unbondedAmount: 0
              }
            ]
          })
      )
    )

    return pools.map((pool) => {
      const { bondedAmount, unbondedAmount } = poolShares[pool.stakedDenom + '/' + pool.denom]

      return {
        poolId: pool.poolId,
        unbondedAmount: unbondedAmount.toNumber(),
        bondedAmount: bondedAmount.toNumber()
      }
    })
  }

// @TODO: This isn't working at all. It's likely we either [we forgot to put in the details]
// or [this was supposedly a "coming soon"]. Eitherway, we need to talk to Shade team and ask
// how this works.
const lending_adapter =
  () =>
  async ({ pools }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    return await Promise.all(
      pools.map(async (pool) => {
        // @TODO: This isn't working at all. It's likely we either [we forgot to put in the details]
        // or [this was supposedly a "coming soon"]. Eitherway, we need to talk to Shade team and ask
        // how this works.
        // @ts-ignore
        const poolConfig = LENDING_POOL_MAP[pool.denom]

        const [stakedDenomUsdValue, denomUsdValue] = await Promise.all([
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.stakedDenom),
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.denom)
        ])

        if (!stakedDenomUsdValue || !denomUsdValue) {
          throw fatal(`Missing price for ${pool.stakedDenom} or ${pool.denom}`)
        }

        return {
          poolId: pool.poolId,
          bondedAmount: 0,
          unbondedAmount: 0
        }
      })
    )
  }

export { dex_adapter, lending_adapter }
