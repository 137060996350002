import { DeliverTxResponse } from '@cosmjs/stargate'
import { isFuture } from 'date-fns'
import axios, { AxiosError } from 'axios'
import { poll, fatal } from '@/utils'
import { CHAIN_INFO_LIST, CHAIN_IS_COSMOS_SDK_v50 } from '@/config'
import { Account, TxQueryResponse } from '../types'
import { convertIbcTimeoutTimestampToDate, getSendPacketAttributesFromRawLogsOrEvents } from './utils'
import { IBCTransferStatus } from './types'
import { IBC_RETURN_LATER_MS, TIMEOUT_CHECKER_INTERVAL } from './constants'

export interface TraceIbcStatusParameters {
  account: Account
  tx: DeliverTxResponse
}

interface TraceIbcStatusOptions {
  // This option enables/disable the return-later so we can allow the UI to time out
  // if the ibc transfer takes too long (i.e., more than 30 seconds).
  // @TODO: We definitely should improve this option name into something like "screenTimeout"
  // or something else so it doesn't conflict with the ibc transaction being actually timed out
  // from the chain side
  timeout?: boolean
}

// Trace an IBC tranfer's status by:
// - Query transaction hash until resolved
// - Check for timeout timestamp
// - Optional: If it takes too long, we'll resolve *early* to let the user know to return later.
//
// Account should be the account that sent the IBC transfer.

const traceIbcStatus = async ({ account, tx }: TraceIbcStatusParameters, opts?: TraceIbcStatusOptions) => {
  const timeout = opts?.timeout ?? true

  const chainInfo = CHAIN_INFO_LIST[account.currency.coinDenom]

  const packets = getSendPacketAttributesFromRawLogsOrEvents(tx, account.currency.coinDenom)

  const instance = axios.create({ baseURL: chainInfo.rest })

  const acknowledgeUrlParameters = new URLSearchParams()
  // Cosmos v50 SDK deprecates the events parameters in favor of query
  const eventKey = CHAIN_IS_COSMOS_SDK_v50[account.currency.coinDenom] ? 'query' : 'events'
  acknowledgeUrlParameters.append(eventKey, `acknowledge_packet.packet_sequence='${packets.packet_sequence}'`)
  acknowledgeUrlParameters.append(eventKey, `acknowledge_packet.packet_dst_channel='${packets.packet_dst_channel}'`)
  acknowledgeUrlParameters.append('pagination.limit', '1')

  const isTransactionAcknowledged = async () => {
    try {
      const response = await instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${acknowledgeUrlParameters}`)

      return Boolean(response.data.tx_responses.length > 0)
    } catch (e) {
      // We don't want to bail out of the tracing promise even if it fails for a single time
      // (e.g., we got a network error for some reason). For now, we'll return false.
      // We'll add an extra check that we're not receiving a 404 (as that is likely a developer error)
      // @TODO: In the future, it may  make sense to add a max attempt mechanism here if chain goes down
      // longer than expected, or if the user is offline for a long time. Very rare edge case though.
      if (e instanceof AxiosError && e.response && e.response.status !== 404) {
        return false
      }

      throw fatal('Failed to query the ibc status due to an unknown error.')
    }
  }

  const acknowledgeAbortController = new AbortController()

  // We'll repetitively check the rpc if the transaction for the acknowledge packet already exists.
  // We're sure the poll won't run forever; we're depending on the timeout call below to resolve
  // which in turn will abort the poll if it doesn't resolve in time.
  const pollHttpPromise = new Promise<IBCTransferStatus>(async (resolve) => {
    await poll(
      () => isTransactionAcknowledged(),
      (status) => status === true,
      { ms: 5000, signal: acknowledgeAbortController.signal }
    )

    resolve('complete')
  })

  let timeoutInterval: number | null = null

  // If either of the above promises are unresolved within the timeout and none of the above resolved, it has likely failed
  // Maybe we don't need to do this and instead just listen for timeout_packet the same way we do for acknowledge_packet.
  // Before we do anything, keep in mind that pollHttpPromise fallback exit depends on this. So, it might be a good idea
  // to keep this (probably rename it) and create another promise.
  const timeoutPromise = new Promise<IBCTransferStatus>((resolve) => {
    if (packets == null) {
      throw fatal('Trying to invoke `this.query` without running `this.trace`')
    }

    const timeoutTimestamp = convertIbcTimeoutTimestampToDate(packets.packet_timeout_timestamp)

    timeoutInterval = window.setInterval(async () => {
      // Check if transaction has not yet timed out
      if (isFuture(timeoutTimestamp)) {
        return
      }

      // Manually clear anyway (even when trace handles this) to immediately
      // prevent this interval from running twice during ibc transfer timeout testing
      if (timeoutInterval) {
        clearInterval(timeoutInterval)
      }

      // It has likely timeout, but we'll query again in case our polling above simply hasn't completed yet.
      // If a network error occurs, we'll just resolve to timeout.
      const status = await isTransactionAcknowledged()

      // If we found it, it did not time out from the block chain.
      // If it's still not acknowledged, then it has likely timed out.
      resolve(status === true ? 'complete' : 'timeout')
    }, TIMEOUT_CHECKER_INTERVAL)
  })

  let returnLaterTimeout: number | null = null

  // If a transaction takes more than x seconds (30 seconds by default), we'll resolve to 'return-later'
  // to enable the StakeForm to use it to prompt the user to return later.
  const returnLaterPromise = new Promise<IBCTransferStatus>((resolve) => {
    if (!timeout) {
      return
    }

    setTimeout(() => {
      resolve('return-later')
    }, IBC_RETURN_LATER_MS)
  })

  const cleanup = () => {
    if (timeoutInterval) {
      clearInterval(timeoutInterval)
    }

    if (returnLaterTimeout) {
      clearTimeout(returnLaterTimeout)
    }

    acknowledgeAbortController.abort()
  }

  try {
    return await Promise.race<IBCTransferStatus>([pollHttpPromise, timeoutPromise, returnLaterPromise])
  } finally {
    cleanup()
  }
}

export { traceIbcStatus }
