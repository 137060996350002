import * as React from 'react'
import { SVGProps } from 'react'
const SvgProcessing = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"
      stroke="currentColor"
      strokeWidth={1.456}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.8v4.032L10.1 9.4"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgProcessing
