import React, { useState, useMemo } from 'react'
import { UnstyledButton, Box, Text, Paper, Loader, Group, Collapse, MantineColor, useMantineTheme } from '@mantine/core'
import { Icon, IconName, StaticActionIcon } from '@/components'
import { useMediaQuery } from '@mantine/hooks'

export type TransactionCardStatus =
  | 'checking' // Checking for status, usually used for IBC/LSM where status is a separate query.
  | 'checking-expanded' // Same as checking, but we want the transaction to be expanded
  | 'checking-failed' // Checking failed due to rpc/network errors
  | 'pending' // Transaction is still on-going
  | 'warning' // Transaction may have failed, mostly used for LSMLiquidStake
  | 'complete' // Transaction has been guaranteed to be done
  | 'error' // Transaction failed

export interface TransactionCardProps {
  status: TransactionCardStatus
  title?: string | null
  description?: React.ReactNode
  onDismiss?: () => void
  action?: React.ReactNode
  children?: React.ReactNode
}

// @TODO: We should consider making TransactionCardV2 that offers more flexibility
// given that we have really good primitives right now, and we have a good record of
// duplication in this codebase.
//
// Consider removing the `status` attribute entirely and replace it with `icon`
// customized by its parent component; we can always duplicate common cases like
// we've always done.
//
// Consider removing `description` entirely and just use `children`.
const TransactionCard: React.FC<TransactionCardProps> = ({
  status,
  title,
  onDismiss,
  action,
  children,
  description
}) => {
  // By default, non-dismissible transaction cards are "closed". `checking-expanded` is the only exception.
  const [isOpen, setIsOpen] = useState(status === 'checking-expanded')

  const t = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${t.breakpoints.md}px)`, true)

  const handleToggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const isDismissible = useMemo(() => {
    return !['pending', 'checking', 'checking-expanded', 'checking-failed'].includes(status)
  }, [status])

  const Wrapper = isDismissible ? Box : UnstyledButton

  const wrapperProps = isDismissible ? {} : { style: { width: '100%' }, onClick: handleToggleOpen }

  const icons: Record<Exclude<TransactionCardStatus, 'checking' | 'checking-expanded'>, IconName> = {
    'checking-failed': 'arrowHorizontal',
    pending: 'arrowHorizontal',
    warning: 'warningTriangle',
    complete: 'check',
    error: 'arrowHorizontal'
  }

  const colors: Record<Exclude<TransactionCardStatus, 'checking' | 'checking-expanded'>, MantineColor> = {
    'checking-failed': 'gray',
    pending: 'yellow',
    warning: 'yellow',
    complete: 'cyan',
    error: 'red'
  }

  return (
    <Paper p={0} withBorder>
      <Wrapper {...wrapperProps}>
        <Group p="md" align="center" position="apart" noWrap={!isMediumScreen}>
          <Group spacing="sm" noWrap={!isMediumScreen} align={isMediumScreen ? 'center' : 'flex-start'}>
            {status === 'checking' || status === 'checking-expanded' ? (
              <Loader size="xs" color="cyan" />
            ) : (
              <StaticActionIcon icon={icons[status]} color={colors[status]} />
            )}

            <Text weight={600} size="md" inline={isMediumScreen} sx={(t) => ({ color: t.colors.gray[9] })}>
              {title}
            </Text>
          </Group>

          <Box>
            {action ??
              (isDismissible ? (
                Boolean(onDismiss) && (
                  <UnstyledButton onClick={onDismiss}>
                    <Icon name="cross" size="xs" sx={(t) => ({ color: t.colors.gray[8] })} />
                  </UnstyledButton>
                )
              ) : (
                <Box sx={{ transform: isOpen ? '' : 'rotate(180deg)' }}>
                  <Icon name="caretDown" size="xs" sx={(t) => ({ color: t.colors.gray[8] })} />
                </Box>
              ))}
          </Box>
        </Group>
      </Wrapper>

      {Boolean(description || children) && (
        <Collapse in={isDismissible ? true : isOpen}>
          <Box p="lg" pt={0}>
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>{description}</Text>
            {children}
          </Box>
        </Collapse>
      )}
    </Paper>
  )
}

export { TransactionCard }
