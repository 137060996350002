import { env } from '@/utils/env'

const config = {
  app: {
    title: 'Stride',
    tagline: 'Multichain Liquid Staking'
  },

  network: {
    mainnetChainId: 'stride-1',
    launchDate: '2022-09-04',
    faucetApiUrl: env('STRIDE_FAUCET_API_URL', 'https://faucet.stride.zone')
  },

  staking: {
    // When a user links their evmos address, we send 1 aevmos to Stride.
    // This causes a bug on the LS side. Specifically, users are allowed to "apply" their stride balance,
    // and our calculations are off.
    // - Given an input of 0.001, it becomes 0.0009999999999999 something.
    // - Wallet doesn't actually quite get 0.001
    // - LS fails because we are trying to LS the input (0.001)
    // Ideally we should fix this by sending what we actually received from the IBC transfer in the the
    // actual Liquid Staking step, but this is good enough for now!
    // @TODO: Share this constant with the `min` validator for the `amount` input on the stake form.
    //
    // @TODO: This is a good idea, but revisit if we still have the problem above given that we are
    // currently doing work to fix our math library, how we handle numbers and arithmetic.
    strideBalanceThreshold: 0.001,
    // In the staking wallet component, we display a tooltip for the total LS amount.
    // The tooltip is only displayed if the total LS amount is gte than this threshold.
    walletTotalStTokenThreshold: 0.001
  },

  upstashRedis: {
    main: {
      url: env('UPSTASH_REDIS_MAIN_URL', 'https://usw1-hot-bat-33320.upstash.io/'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_MAIN_TOKEN',
        'AYIoASQgMGE0NDYyMjUtY2MxMy00ZjY0LTg0NmMtOTFlZmEyOTBiMjIzZWFmNDc3NjI0YWU1NDRlYThjY2UyMzU5MzNhNDRjZGI='
      )
    },

    airdrop: {
      url: env('UPSTASH_REDIS_AIRDROP_URL', 'https://us1-premium-caribou-39148.upstash.io'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_AIRDROP_TOKEN',
        'AZjsASQgMDc1ODU1ZTktMzMwZi00OTM1LWI2YTAtOGYwYWY3NjY1MGMwZTY5ZmIzMjRlMGVmNDhmZThlMWY0MmU1OWM5NjQwNzA='
      )
    },

    announcement: {
      url: env('UPSTASH_REDIS_ANNOUNCEMENT_URL', 'https://us1-immense-dassie-37146.upstash.io'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_ANNOUNCEMENT_TOKEN',
        'ApEaACQgZWM0NzUxZmYtNGFlYS00NWYyLWEzYjMtZmVkZWJhMTExNTA0E0vUq98wqi2KG59xHcukw2mQD7UuFHasIrIJfULO4TY='
      )
    }
  },

  rollbar: {
    token: env('ROLLBAR_TOKEN', 'aabbccddeeff112233'),
    env: env('ROLLBAR_ENV', process.env.NODE_ENV ?? 'local')
  },

  links: {
    website: 'https://stride.zone',
    github: 'https://github.com/Stride-Labs',
    docs: 'https://docs.stride.zone/docs',
    stats: 'https://stats.stride.zone',
    support: 'https://support.stride.zone/',
    testnet: 'https://github.com/Stride-Labs/testnet',
    twitter: 'https://twitter.com/stride_zone',
    twitterHandle: '@stride_zone',
    discord: 'https://stride.zone/discord',
    email: 'mailto:hello@stridelabs.co',
    faq: 'https://stride.zone/faq',
    injectiveBridge: 'https://hub.injective.network/bridge/?destination=stride&origin=injective&token=inj',
    dymensionBridge: 'https://portal.dymension.xyz/ibc?sourceId=dymension_1100-1&destinationId=stride-1',
    ibcxMedium: 'https://medium.com/@IBCindex',
    blog: 'https://www.stride.zone/blog',
    stake: 'https://wallet.keplr.app/?modal=staking&chain=stride-1&step_id=2',
    bridge: 'https://bridge.stride.zone'
  }
} as const

export { config }
