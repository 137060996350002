import { adapter as osmosis } from './osmosis'
import { adapter as umee } from './umee'
import { adapter as astroport } from './astroport'
import { adapter as mars } from './mars'
import { adapter as inter } from './inter'
import { adapter as kujira } from './kujira'
import { adapter as forge, getForgePoolPage } from './forge'
import { adapter as demex } from './demex'
import { dex_adapter as dex_shade, lending_adapter as lending_shade } from './shade'
import { adapter as levana } from './levana'

const adapters = {
  osmosis,
  umee,
  astroport,
  mars,
  inter,
  kujira,
  forge,
  demex,
  dex_shade,
  lending_shade,
  levana
}

export { adapters, getForgePoolPage }
