import type { ChainWalletBase } from '@cosmos-kit/core'
import type { Account, SafeModeAccount, SelectedCoinDenom } from '@/wallet-utils'

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useManager } from '@cosmos-kit/react-lite'

import { EthereumPublicKeyError, isAccountCurrency, isSelectedCoinDenom } from '@/wallet-utils'

import { CHAIN_CONFIG, CHAIN_INFO_LIST, STRIDE_CHAIN_INFO, getDexInfoFromDenom } from '@/config'
import { useWalletSelect } from './connect-wallet'
import { useRouter } from 'next/router'
import { assert, disregard } from '@/utils'

import { queryKeys } from '@/query-keys'
import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin'
import { useLatestValue } from '@/hooks'

const baseSelectedCoinDenom = 'TIA'

export interface WalletContext {
  selectedCoinDenom: SelectedCoinDenom
  setSelectedCoinDenom: (coin: SelectedCoinDenom) => void

  strideAccount: Account | SafeModeAccount | undefined
  selectedAccount: Account | SafeModeAccount | undefined

  getWallet: (denom: SelectedCoinDenom, safeMode?: boolean) => Promise<Account | SafeModeAccount>
  getAddress: (denom: SelectedCoinDenom) => Promise<{ denom: SelectedCoinDenom; address: string }>

  refreshStrideBalances: (
    account?: Account | SafeModeAccount,
    updateQuery?: boolean
  ) => Promise<{
    strideBalances: Coin[]
    strideBalance: string
    strideSelectedAccountBalance: string
    strideSelectedAccountStakedBalance: string
  }>
  refreshSelectedBalances: (
    account?: Account | SafeModeAccount,
    updateQuery?: boolean
  ) => Promise<{
    selectedAccountBalances: Coin[]
    selectedAccountBalance: string
    selectedAccountStakedBalance: string
  }>

  isFetchingStrideAccount: boolean
  isFetchingSelectedAccount: boolean

  selectedAccountBalance: string
  selectedAccountStakedBalance: string

  strideBalance: string
  strideSelectedAccountBalance: string
  strideSelectedAccountStakedBalance: string

  connect: () => Promise<void>
  disconnect: () => void

  connectArbitrary: (denom: SelectedCoinDenom) => Promise<void>
}

export const Wallet = createContext<WalletContext | null>(null)

export function WalletProvider({ children }: { children: React.ReactNode }) {
  const { walletRepos, on } = useManager()
  const { selectWallet } = useWalletSelect()
  const queryClient = useQueryClient()
  const router = useRouter()

  // const { data: icnsNameservice, state: icnsState } = useNameService('icns')

  const [strideAccount, setStrideAccount] = useState<Account | SafeModeAccount | undefined>(undefined)
  const strideAccountRef = useLatestValue(strideAccount)
  const [selectedAccount, setSelectedAccount] = useState<Account | SafeModeAccount | undefined>(undefined)

  const [isFetchingStrideAccount, setIsFetchingStrideAccount] = useState<boolean>(false)
  const [isFetchingSelectedAccount, setIsFetchingSelectedAccount] = useState<boolean>(false)

  const [selectedAccountBalance, setSelectedAccountBalance] = useState<string>('0')
  const [selectedAccountStakedBalance, setSelectedAccountStakedBalance] = useState<string>('0')

  const [strideBalance, setStrideBalance] = useState<string>('0')
  const [strideSelectedAccountBalance, setStrideSelectedAccountBalance] = useState<string>('0')
  const [strideSelectedAccountStakedBalance, setStrideSelectedAccountStakedBalance] = useState<string>('0')

  const selectedCoinDenom = useMemo(() => {
    if (!router.query.chain || !isSelectedCoinDenom(router.query.chain)) {
      return baseSelectedCoinDenom
    }
    return router.query.chain
  }, [router.query])

  const setSelectedCoinDenom = useCallback(
    (denom: SelectedCoinDenom) => {
      router.push('/?chain=' + denom, undefined, {
        shallow: true
      })
    },
    [router]
  )

  useEffect(() => {
    const currentWallet = localStorage.getItem('cosmos-kit@2:core//current-wallet')
    if (currentWallet) connect(currentWallet)
  }, [])

  // This is connected to the temporary stuff below.
  // useEffect(() => {
  //   if (strideAccount) connectSelected(strideAccount.wallet.walletName)
  // }, [selectedCoinDenom])

  // @TODO: Temporary hack
  useEffect(() => {
    if (!selectedAccount) return
    if (selectedAccount.currency.coinDenom !== selectedCoinDenom) {
      const walletName = selectedAccount.wallet.walletName
      setSelectedAccount(undefined)
      connectSelected(walletName)
    }
  }, [selectedAccount, selectedCoinDenom])

  useEffect(() => {
    on('refresh_connection', () => {
      if (strideAccountRef.current) {
        connect(strideAccountRef.current.wallet.walletName)
      }
    })
  }, [])

  const getWallet = useCallback(async (denom: SelectedCoinDenom, safeMode?: boolean) => {
    const chainInfo = CHAIN_INFO_LIST[denom]

    const walletRepo = walletRepos.find((wallet) => wallet.chainRecord.chain?.chain_id === chainInfo.chainId)

    assert(walletRepo?.current, `Wallet not connected for ${denom}`)

    const walletBase: ChainWalletBase | undefined = walletRepo.current

    assert(walletBase, `Wallet for ${denom} does not exist`)

    await walletBase.initClient()

    assert(walletBase.client, `Client does not exist`)

    // @TODO: Consider using walletBase.client.addChain so we can remove most of the
    // experimentalSuggestChain code, but we have no time at the moment to confirm
    // that nothing would break (nothing should).
    //
    // Make sure to update this list if we ever add more wallets.
    const experimentalSuggestChain = () => {
      const chainInfo = CHAIN_INFO_LIST[denom]

      if (walletBase.walletName === 'keplr-extension') {
        return window.keplr.experimentalSuggestChain(chainInfo)
      }

      if (walletBase.walletName === 'leap-extension') {
        return window.leap.experimentalSuggestChain(chainInfo)
      }

      if (walletBase.walletName === 'cosmostation-extension') {
        return window.cosmostation.providers.keplr.experimentalSuggestChain(chainInfo)
      }

      // It means we added a new wallet but forgot to update this code path.
      // For the most part it shouldn't matter because whatever comes next after the
      // suggest chain would fail if the chain is not in the wallet's registry.
      disregard(`Unsupported wallet for ${denom}`)
    }

    // @TODO: This is a last-minute workaround. For now, we intentionally removed
    // cosmos-kit's default behavior of calling `experimentalSuggestChain` as for some
    // reason it calls suggestChain for everything except ISLM for some reason.
    //
    // At the same time, we have no means of resuming the aforementioned suggestChain
    // when cosmos-kit calls it (or halting from proceeding until the initial suggestChain
    // was fully complete) so we're just going to try to manually call it here for now.
    if (['ISLM', 'CMDX', 'BAND', 'STRD', 'DYM', 'BLD', 'EVMOS'].includes(denom)) {
      await experimentalSuggestChain()
    }

    assert(walletBase.client.getAccount, `Client.getAccount does not exist`)

    const clientAccount = await walletBase.client.getAccount(chainInfo.chainId)

    assert(clientAccount, `Account does not exist`)

    const { address, isNanoLedger, pubkey } = clientAccount

    // Ledger only has Amino support, and DYM does not work with direct.
    // @TODO: Consider making a config if we have more in the future.
    await walletBase.initOfflineSigner(isNanoLedger ? 'amino' : 'direct')

    const signer = walletBase.offlineSigner

    assert(signer, `Signer does not exist`)

    // @TODO: nsName blocks us from proceeding despite not being important
    // I've asked Josef for more context, so we'll know more as we go!
    // @TODO: Consider removing this or using the useNameService hook from cosmos-kit.
    const nsName = undefined
    // const nsName = await fetchNsName(denom, address)

    function makeSafeCatchFn<T>(defaultValue: T) {
      return (e: Error) => {
        if (!safeMode) throw e
        console.error(e)
        return defaultValue
      }
    }

    const cosmwasm = await walletBase.getSigningCosmWasmClient().catch(makeSafeCatchFn(null))
    const client = await walletBase.getSigningStargateClient().catch(makeSafeCatchFn(null))
    const balances = client ? await client.getAllBalances(address).catch(makeSafeCatchFn([])) : []

    const currency = chainInfo.stakeCurrency

    assert(isAccountCurrency(currency), `Account coin denom (${currency.coinDenom}) is invalid`)

    const coin = balances.find((coin) => {
      return coin.denom === currency.coinMinimalDenom
    }) || { denom: currency.coinMinimalDenom, amount: '0' }

    if (safeMode) {
      const account: SafeModeAccount = {
        address,
        nsName,
        pubkey,
        isNanoLedger,
        coin,
        currency,
        coins: [...balances],
        client,
        cosmwasm,
        signer,
        wallet: walletBase,
        partial: !client || !balances.length
      }

      return account
    }

    assert(client, 'Unable to instantiate account without Stargate')
    assert(cosmwasm, 'Unable to instantiate account without StargateCosmwasm')

    const account: Account = {
      address,
      nsName,
      pubkey,
      isNanoLedger,
      coin,
      currency,
      coins: [...balances],
      client,
      cosmwasm,
      signer,
      wallet: walletBase
    }

    return account
  }, [])

  // @TODO: Should not be instantiating Stargate in anyway (getWallet does that) and
  // instead should focus on simply enabling wallet + getting the relevant denom's address
  const getAddress = async (denom: SelectedCoinDenom) => {
    const account = await connectArbitrary(denom).then(async () => await getWallet(denom, true))
    return { denom, address: account.address }
  }

  const getSelectedWallet = useCallback(
    async (safeMode?: boolean) => {
      return getWallet(selectedCoinDenom, safeMode)
    },
    [selectedCoinDenom]
  )

  const getStrideWallet = useCallback((safeMode?: boolean) => {
    return getWallet('STRD', safeMode)
  }, [])

  const connectSelected = useCallback(
    async (walletName: string) => {
      const walletRepo = walletRepos.find(
        (wallet) => wallet.chainRecord.chain?.chain_id === CHAIN_INFO_LIST[selectedCoinDenom].chainId
      )
      assert(walletRepo, `Selected wallet repo not found`)
      await walletRepo.connect(walletName)
      selectedWalletCallback()
    },
    [selectedCoinDenom, walletRepos]
  )

  const connect = useCallback(
    async (type?: string) => {
      const wallet = walletRepos.find((wallet) => {
        return wallet.chainRecord.chain?.chain_id === STRIDE_CHAIN_INFO.chainId
      })

      assert(wallet, `Stride wallet repo not found`)

      if (wallet.current) {
        connectSelected(wallet.current.walletName)
        walletCallback()
      } else {
        const walletName = type || (await selectWallet(wallet))
        if (!walletName) return

        await wallet.connect(walletName)
        if (walletName !== 'cosmos-extension-metamask') walletRepos.forEach((repo) => repo.connect(walletName))

        connectSelected(walletName)
        walletCallback()
      }
    },
    [walletRepos]
  )

  const connectArbitrary = useCallback(
    async (denom: SelectedCoinDenom) => {
      const wallet = walletRepos.find((wallet) => wallet.chainRecord.chain?.chain_id === CHAIN_INFO_LIST[denom].chainId)

      assert(wallet, `${denom} wallet repo not found`)

      assert(strideAccount, `Could not connect arbitrarily to ${denom}. Stride account not connected`)

      await wallet.connect(strideAccount.wallet.walletName)
    },
    [walletRepos, strideAccount]
  )

  // Detect a change in the selected wallet & trigger connection callback.
  // @TODO: Figure out if we really need this. On one hand, what does a cached wallet mean?
  // We're doing something similar by auto-connecting the wallet that's stored on local storage.
  // On the other hand, this causes bugs as soon as we remove the lockfile, which likely means
  // a part of our code misunderstands cosmos-kit.
  // https://github.com/Stride-Labs/interface/issues/559
  useEffect(() => {
    selectedWalletCallback()
  }, [
    walletRepos.find((wallet) => wallet.chainRecord.chain?.chain_id === CHAIN_INFO_LIST[selectedCoinDenom].chainId)
      ?.current
  ])

  // On page load, check if there is a cached wallet. If yes, load it.
  // @TODO: Figure out if we really need this. On one hand, what does a cached wallet mean?
  // We're doing something similar by auto-connecting the wallet that's stored on local storage.
  // On the other hand, this causes bugs as soon as we remove the lockfile, which likely means
  // a part of our code misunderstands cosmos-kit.
  // https://github.com/Stride-Labs/interface/issues/559
  useEffect(() => {
    // This is not ideal. Looking into another solution.
    setTimeout(() => {
      const wallet = walletRepos.find((wallet) => wallet.chainName === 'stride')
      if (!wallet?.current) return
      else walletCallback()
    }, 1000)
  }, [])

  // Whenever the status of the wallet repo is set to connected, we need to update the stride account and its balances.
  // This is a workaround for the fact that `await walletRepo?.connect()` does not await as expected.
  const walletCallback = useCallback(async () => {
    const wallet = walletRepos.find((wallet) => {
      return wallet.chainRecord.chain?.chain_id === STRIDE_CHAIN_INFO.chainId
    })

    if (!wallet?.current) {
      return
    }

    setIsFetchingStrideAccount(true)
    wallet?.closeView()

    const account = await getStrideWallet()

    setStrideAccount(account)
    refreshStrideBalances(account)
    refreshSelectedBalances()

    setIsFetchingStrideAccount(false)
  }, [walletRepos])

  // Same as above but for selectedChain wallet
  const selectedWalletCallback = useCallback(async () => {
    const wallet = walletRepos.find(
      (wallet) => wallet.chainRecord.chain?.chain_id === CHAIN_INFO_LIST[selectedCoinDenom].chainId
    )
    if (!wallet?.current) return

    setIsFetchingSelectedAccount(true)

    const account = await getSelectedWallet()

    setSelectedAccount(account)
    refreshSelectedBalances(account)
    refreshStrideBalances()

    setIsFetchingSelectedAccount(false)
  }, [walletRepos, selectedCoinDenom])

  // Refresh balances for Stride account
  const refreshStrideBalances = useCallback(
    async (account?: Account | SafeModeAccount, updateQuery?: boolean) => {
      // @TODO: From my understanding, one - we have to use the "account" parameter if it exists
      // so that during the "connect wallet on page load," we have an updated reference
      // to the stride account. We can likely properly solve that in a less clever way.
      // two - For the actual balance update, we should not have to call the entire getWallet
      // function, and instead only call a (non-existent) fetchBalance function.
      const currentAccount = account || (await getWallet('STRD', true))

      assert(currentAccount, `Stride account not connected`)

      // INJ on Stride (via Classic LS)
      const selectedAccountBalance =
        currentAccount.coins.find((coin) => {
          return coin.denom === CHAIN_CONFIG[selectedCoinDenom].ibcDenom
        })?.amount || '0'

      // stINJ on Stride
      const selectedAccountStakedBalance =
        currentAccount.coins.find((coin) => {
          return coin.denom === `st${CHAIN_INFO_LIST[selectedCoinDenom].stakeCurrency.coinMinimalDenom}`
        })?.amount || '0'

      // @TODO: It looks like we have a proper query for the balances. We shouldn't try
      // to maintain a separate state anymore.
      setStrideBalance(currentAccount.coin.amount)
      setStrideSelectedAccountBalance(selectedAccountBalance)
      setStrideSelectedAccountStakedBalance(selectedAccountStakedBalance)

      // We'll hide behind a flag because when we call this function, the query
      // we're invalidating below also runs the same function - will cause an infinite loop.
      // @TODO: Together with the TODO above, we should make two separate functions:
      // - "refreshBalances(account)" which invalidates the query and
      // - "fetchBalances(account)" which is purely for fetching the account's balances
      if (updateQuery) {
        // @TODO: Remove this as we move over to v2
        await queryClient.invalidateQueries({ queryKey: queryKeys.strideBalancesBase })

        await queryClient.invalidateQueries({ queryKey: queryKeys.strideBalancesV2Base })
      }

      return {
        strideBalances: currentAccount.coins,
        strideBalance: currentAccount.coin.amount,
        strideSelectedAccountBalance: selectedAccountBalance,
        strideSelectedAccountStakedBalance: selectedAccountStakedBalance
      }
    },
    [strideAccount, selectedCoinDenom]
  )

  // Refresh balances for selectedChain account
  const refreshSelectedBalances = useCallback(
    async (account?: Account | SafeModeAccount, updateQuery?: boolean) => {
      // @TODO: From my understanding, one - we have to use the "account" parameter if it exists
      // so that during the "connect wallet on page load," we have an updated reference
      // to the stride account. We can likely properly solve that in a less clever way.
      // two - For the actual balance update, we should not have to call the entire getWallet
      // function, and instead only call a (non-existent) fetchBalance function.
      const currentAccount = account || (await getWallet(selectedCoinDenom, true))

      assert(currentAccount, `Selected account not connected`)

      const accountStakedBalance =
        currentAccount.coins.find((coin) => {
          return coin.denom === CHAIN_CONFIG[currentAccount.currency.coinDenom].stakedIbcDenom
        })?.amount || '0'

      // @TODO: It looks like we have a proper query for the balances. We shouldn't try
      // to maintain a separate state anymore.
      setSelectedAccountBalance(currentAccount.coin.amount)
      setSelectedAccountStakedBalance(accountStakedBalance)

      // We'll hide behind a flag because when we call this function, the query
      // we're invalidating below also runs the same function - will cause an infinite loop.
      // @TODO: Together with the TODO above, we should make two separate functions:
      // - "refreshBalances(account)" which invalidates the query and
      // - "fetchBalances(account)" which is purely for fetching the account's balances
      if (updateQuery) {
        // @TODO: Remove this as we move over to v2
        await queryClient.invalidateQueries({ queryKey: queryKeys.selectedBalancesBase })

        await queryClient.invalidateQueries({ queryKey: queryKeys.selectedBalancesV2Base })
      }

      return {
        selectedAccountBalances: currentAccount.coins,
        selectedAccountBalance: currentAccount.coin.amount,
        selectedAccountStakedBalance: accountStakedBalance
      }
    },
    [selectedAccount, selectedCoinDenom]
  )

  const disconnect = useCallback(() => {
    walletRepos.forEach((repo) => repo.disconnect())
    setStrideAccount(undefined)
    setSelectedAccount(undefined)
    setStrideBalance('0')
    setStrideSelectedAccountBalance('0')
    setStrideSelectedAccountStakedBalance('0')
    setSelectedAccountBalance('0')
    setSelectedAccountStakedBalance('0')
  }, [walletRepos])

  return (
    <Wallet.Provider
      value={{
        selectedCoinDenom,
        setSelectedCoinDenom,
        strideAccount,
        selectedAccount,
        getWallet,
        getAddress,
        refreshStrideBalances,
        refreshSelectedBalances,
        isFetchingStrideAccount,
        isFetchingSelectedAccount,
        selectedAccountBalance,
        selectedAccountStakedBalance,
        strideBalance,
        strideSelectedAccountBalance,
        strideSelectedAccountStakedBalance,
        connect,
        disconnect,
        connectArbitrary
      }}>
      {children}
    </Wallet.Provider>
  )
}

export const useWallet = (): WalletContext => {
  const context = useContext(Wallet)
  assert(context, 'You forgot to mount WalletProvider')
  return context
}

export const useRefreshBalances = () => {
  const { refreshStrideBalances, refreshSelectedBalances } = useWallet()

  return async () => {
    const [selectedBalances, strideBalances] = await Promise.all([
      refreshSelectedBalances(undefined, true),
      refreshStrideBalances(undefined, true)
    ])

    return { selectedBalances, strideBalances }
  }
}

export const useSelectedCoin = () => {
  const { selectedCoinDenom } = useWallet()
  return selectedCoinDenom
}

export const useRefreshStrideBalances = () => {
  const { refreshStrideBalances } = useWallet()

  return async () => {
    return await refreshStrideBalances(undefined, true)
  }
}

export const useRefreshSelectedBalances = () => {
  const { refreshSelectedBalances } = useWallet()

  return async () => {
    return await refreshSelectedBalances(undefined, true)
  }
}

export const useChainWallet = (denom: SelectedCoinDenom, safeMode?: boolean) => {
  const { strideAccount, getWallet, connectArbitrary } = useWallet()
  return useQuery({
    queryKey: queryKeys.chainAccount({
      denom,
      strideAddress: strideAccount?.address || '',
      safeMode: safeMode || false
    }),

    queryFn: async (): Promise<Account | SafeModeAccount> => {
      try {
        await connectArbitrary(denom)
        return await getWallet(denom, safeMode)
      } catch (error: unknown) {
        // @TODO: Catch this error for Stride and selected accounts
        if (
          error instanceof Error &&
          error.message ===
            'No Ethereum public key. Initialize Ethereum app on Ledger by selecting the chain in the extension'
        ) {
          throw new EthereumPublicKeyError(error.message)
        }
        throw error
      }
    },

    enabled: Boolean(strideAccount)
  })
}

export const useStrideWallet = () => {
  const { strideAccount } = useWallet()
  return strideAccount
}

export const useSelectedWallet = () => {
  const { selectedAccount } = useWallet()
  return selectedAccount
}

export const useDexWallet = () => {
  const { strideAccount, connectArbitrary, getWallet, selectedCoinDenom } = useWallet()
  return useQuery({
    queryKey: queryKeys.dexWallet({ denom: selectedCoinDenom, strideAddress: strideAccount?.address || '' }),

    queryFn: async (): Promise<Account | SafeModeAccount | null> => {
      const dexInfo = getDexInfoFromDenom(selectedCoinDenom)

      if (dexInfo == null) {
        return null
      }

      return connectArbitrary(dexInfo.coinDenom).then(() => {
        return getWallet(dexInfo.coinDenom, false)
      })
    },

    enabled: Boolean(strideAccount)
  }).data
}

interface SelectedBalancesV2Payload {
  selectedAccountBalances: Coin[]
  // ATOM on Cosmos Hub
  selectedAccountBalance: string
  // stATOM on Cosmos Hub (IBC denom)
  selectedAccountStakedBalance: string
}

// @TODO: We should continue using this moving forward (slowly migrate ones using useSelectedBalances)
// as it exposes the full query instead of just the data. This way, we can properly check for the
// "isLoading" property. For context, StakeWallet used to have values that would flicker
// (loading -> 0 -> actual data loaded) this was the proper fix for it.
//
// @CONTEXT: It's a lot of work to move everything now
//
// To add, we should also use this instead of any balance state from useWallet moving forward.
export const useSelectedBalancesV2 = () => {
  const { selectedCoinDenom, strideAccount, refreshSelectedBalances } = useWallet()

  const queryFn = async () => {
    return await refreshSelectedBalances(undefined, false)
  }

  return useQuery<SelectedBalancesV2Payload>({
    queryKey: queryKeys.selectedBalancesV2({ denom: selectedCoinDenom, strideAddress: strideAccount?.address || '' }),
    queryFn,
    // We'll only check for strideAccount given that refreshSelectedBalances as is fetches the entire account.
    enabled: Boolean(strideAccount)
  })
}

interface StrideBalancesV2Payload {
  strideBalances: Coin[]
  // STRD on Stride
  strideBalance: string
  // ATOM on Stride (IBC denom)
  strideSelectedAccountBalance: string
  // stATOM on Stride
  strideSelectedAccountStakedBalance: string
}

// @TODO: We should continue using this moving forward (slowly migrate ones using useStrideBalances)
// as it exposes the full query instead of just the data. This way, we can properly check for the
// "isLoading" property. For context, StakeWallet used to have values that would flicker
// (loading -> 0 -> actual data loaded) this was the proper fix for it.
//
// @CONTEXT: It's a lot of work to move everything now
//
// To add, we should also use this instead of any balance state from useWallet moving forward.
export const useStrideBalancesV2 = () => {
  const { selectedCoinDenom, strideAccount, refreshStrideBalances } = useWallet()

  const queryFn = async () => {
    return await refreshStrideBalances(undefined, false)
  }

  return useQuery<StrideBalancesV2Payload>({
    queryKey: queryKeys.strideBalancesV2({ denom: selectedCoinDenom, strideAddress: strideAccount?.address || '' }),
    queryFn,
    enabled: Boolean(strideAccount)
  })
}

export const useStrideBalances = () => {
  const { selectedCoinDenom, strideAccount, selectedAccount, refreshStrideBalances } = useWallet()
  return useQuery({
    queryKey: queryKeys.strideBalances({ denom: selectedCoinDenom, strideAddress: strideAccount?.address || '' }),

    queryFn: async () => {
      const balances = await refreshStrideBalances(undefined, false)
      return balances
    },

    enabled: Boolean(strideAccount) && Boolean(selectedAccount),
    initialData: {
      strideBalances: [],
      strideBalance: '0',
      strideSelectedAccountBalance: '0',
      strideSelectedAccountStakedBalance: '0'
    }
  }).data as {
    strideBalances: Coin[]
    strideBalance: string
    strideSelectedAccountBalance: string
    strideSelectedAccountStakedBalance: string
  }
}

export const useSelectedBalances = () => {
  const { selectedCoinDenom, strideAccount, selectedAccount, refreshSelectedBalances } = useWallet()
  return useQuery({
    queryKey: queryKeys.selectedBalances({ denom: selectedCoinDenom, strideAddress: strideAccount?.address || '' }),

    queryFn: async () => {
      const balances = await refreshSelectedBalances()
      return balances
    },

    enabled: Boolean(strideAccount) && Boolean(selectedAccount),
    initialData: {
      selectedAccountBalances: [],
      selectedAccountBalance: '0',
      selectedAccountStakedBalance: '0'
    }
  }).data as {
    selectedAccountBalances: Coin[]
    selectedAccountBalance: string
    selectedAccountStakedBalance: string
  }
}

export const useWalletAddresses = (denoms: SelectedCoinDenom[]) => {
  const { strideAccount, getAddress } = useWallet()
  return useQuery({
    queryKey: queryKeys.walletAddresses({ chains: denoms, strideAddress: strideAccount?.address || '' }),

    queryFn: async () => {
      return await Promise.all(denoms.map((denom) => getAddress(denom)))
    },

    enabled: Boolean(strideAccount)
  })
}
