import { DeliverTxResponse } from '@cosmjs/stargate'
import { fatal } from '@/utils'
import { TxEvent } from '../types'

interface TxRawLog {
  events: TxEvent[]
}

// @TODO: Cosmos SDK v50 added a few more attrributes onto the send_packet event
export type SendPacketEventAttributesKeys =
  | 'packet_timeout_height'
  | 'packet_timeout_timestamp'
  | 'packet_sequence'
  | 'packet_src_port'
  | 'packet_src_channel'
  | 'packet_dst_port'
  | 'packet_dst_channel'
  | 'packet_channel_ordering'
  | 'packet_connection'

export interface SendPacketEventAttributes {
  key: SendPacketEventAttributesKeys
  value: string
}

export interface SendPacketEvent {
  type: 'send_packet'
  attributes: SendPacketEventAttributes[]
}

export type SendPacketEventObject = Record<SendPacketEventAttributesKeys, string>

// @TODO: Consider using zod instead. There's not much value in refactoring this at the moment.
const isRawLogs = (json: any): json is TxRawLog[] => {
  return Array.isArray(json) && Array.isArray(json[0].events) && Array.isArray(json[0].events[0].attributes)
}

// @TODO: Consider using zod instead. There's not much value in refactoring this at the moment.
const isSendPacketEvent = (event: any): event is SendPacketEvent => {
  return event.type === 'send_packet'
}

const isSendPacketAttributeKey = (key: string): key is SendPacketEventAttributesKeys => {
  return [
    'packet_timeout_height',
    'packet_timeout_timestamp',
    'packet_sequence',
    'packet_src_port',
    'packet_src_channel',
    'packet_dst_port',
    'packet_dst_channel',
    'packet_channel_ordering',
    'packet_connection'
  ].includes(key)
}

const parseTxRawLogs = (tx: DeliverTxResponse): Array<TxRawLog> => {
  if (!tx.rawLog) {
    throw fatal('Raw log is empty.')
  }

  let logs
  try {
    logs = JSON.parse(tx.rawLog)
  } catch (e) {
    // We don't expect this to throw since tx is asserted before this function is called
    throw fatal('Unable to parse tx.rawLog')
  }

  if (!isRawLogs(logs)) {
    throw fatal('Parsed json is not a raw log.')
  }

  return logs
}

export { parseTxRawLogs, isSendPacketEvent, isSendPacketAttributeKey }
