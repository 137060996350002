import { CosmWasmClient } from 'cosmwasm'
import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { OSMO_CHAIN_INFO } from '@/config'
import { z } from 'zod'
import { fatal } from '@/utils'
import { redis } from '@/redis'
import BigNumber from 'bignumber.js'

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: We should query the market address. Unfortunately I have
// no idea what the "factory address" is for Levana.
// https://docs.levana.finance/api-tutorial-ts/query-market
const marketAddresses: Record<string, string> = {
  'USD/stATOM': 'osmo1ufpu3nudumzh53sek246zrwvv2cc7leplaqruuggeny7wlcvfrzq4cqmwd',
  'USD/stOSMO': 'osmo1uhyscxda8ljzg95a3u2u7rvzfdy6u4dhpj65u8xdja5kext228eq5u8vyp'
}

const adapter = async ({
  pools,
  address
}: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
  // @TODO: Figure out if there's a way to get the instantiated client from `wallet.tsx`
  // Our problem here is we can execute hooks, so we likely have to pass cosmwasm to the adapter itself
  const client = await CosmWasmClient.connect(OSMO_CHAIN_INFO.rpc)

  return await Promise.all(
    pools.map(async (pool) => {
      const marketAddress = marketAddresses[`${pool.denom}/${pool.stakedDenom}`]

      if (marketAddress == null) {
        throw fatal(`Missing market address for Levana pool ${pool.poolId}`)
      }

      const liquidityProvider = await getLiquidityProvider(client, {
        walletAddress: address,
        marketAddress
      })

      // e.g., sheet_COINGECKO_PRICE_DOLLAR_stOSMO
      const rate = z.number().parse(await redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${pool.stakedDenom}`))

      return {
        poolId: pool.poolId,
        unbondedAmount: new BigNumber(liquidityProvider.lp_amount).times(rate).toNumber(),
        bondedAmount: new BigNumber(liquidityProvider.xlp_amount).times(rate).toNumber()
      }
    })
  )
}

const liquidityProviderSchema = z.object({
  lp_amount: z.string(),
  lp_collateral: z.string(),
  xlp_amount: z.string(),
  xlp_collateral: z.string(),
  available_yield: z.string(),
  available_yield_lp: z.string(),
  available_yield_xlp: z.string(),
  available_crank_rewards: z.string(),
  history: z.object({
    deposit: z.string(),
    deposit_usd: z.string(),
    yield: z.string(),
    yield_usd: z.string()
  })
})

interface LiquidityProviderParameters {
  walletAddress: string
  marketAddress: string
}

const getLiquidityProvider = async (
  client: CosmWasmClient,
  { marketAddress, walletAddress }: LiquidityProviderParameters
) => {
  const payload = await client.queryContractSmart(marketAddress, {
    lp_info: { liquidity_provider: walletAddress }
  })

  return liquidityProviderSchema.parse(payload)
}

export { adapter }
