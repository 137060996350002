import * as React from 'react'
import { SVGProps } from 'react'
const SvgGovernance = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 98 92" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m27.502 76.684-18.01-20.33a7.26 7.26 0 0 1 .618-10.264L58.993 2.777a7.277 7.277 0 0 1 10.264.618l25.658 28.966a7.277 7.277 0 0 1-.619 10.264l-38.32 33.952M.949 91.074h81.803"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgGovernance
