import * as React from 'react'
import { SVGProps } from 'react'
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#lock_svg__clip0_2337_3009)" strokeWidth={1.044} strokeMiterlimit={10}>
      <path
        d="M1.683 5.134h4.633c.642 0 1.162.52 1.162 1.162v3.553c0 .641-.52 1.161-1.162 1.161H1.683c-.641 0-1.161-.52-1.161-1.161V6.296c0-.642.52-1.162 1.161-1.162Z"
        stroke="#495057"
      />
      <path d="M5.96 5.101v-2.15a1.96 1.96 0 0 0-3.92 0v2.025" stroke="currentColor" />
      <path d="M4 8.418v-.694" stroke="currentColor" strokeLinecap="round" />
    </g>
  </svg>
)
export default SvgLock
