import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { fatal } from '@/utils'
import { CosmWasmClient } from 'cosmwasm'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { isSelectedCoinDenom } from '@/wallet-utils'

const contracts = {
  marsRedBank: 'osmo1c3ljch9dfw5kf52nfwpxd2zmj2ese7agnx0p9tenkrryasrle5sqf3ftpg'
}

const POOL_MAP = {
  ATOM: {
    denom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    // denom of the staking token as ibc denom
    stakedDenom: 'ibc/C140AFD542AE77BD7DCC83F13FDD8C5E5BB8C4929785E6EC2F4C636F98F17901'
  }
}

const adapter =
  () =>
  async ({ pools, address }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const chain = CHAIN_INFO_LIST.OSMO
    const client = await CosmWasmClient.connect(chain.rpc)

    const collaterals = await client.queryContractSmart(contracts.marsRedBank, {
      user_collaterals: { user: address }
    })
    return await Promise.all(
      pools.map(async (pool) => {
        // @ts-ignore
        const poolConfig = POOL_MAP[pool.denom]
        const collateral = collaterals.find((collateral: any) => collateral.denom === poolConfig.denom)
        const collateralStaked = collaterals.find((collateral: any) => collateral.denom === poolConfig.stakedDenom)

        const [stakedDenomUsdValue, denomUsdValue] = await Promise.all([
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.stakedDenom),
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.denom)
        ])

        if (!stakedDenomUsdValue || !denomUsdValue) {
          throw fatal(`Missing price for ${pool.stakedDenom} or ${pool.denom}`)
        }

        if (!isSelectedCoinDenom(pool.denom)) {
          throw fatal(`Denom ${pool.denom} is not a valid denom`)
        }

        return {
          poolId: pool.poolId,
          bondedAmount: new BigNumber(collateralStaked?.amount || 0)
            .dividedBy(10 ** CHAIN_INFO_LIST[pool.denom].stakeCurrency.coinDecimals)
            .times(denomUsdValue)
            .toNumber(),
          unbondedAmount: new BigNumber(collateral?.amount || 0)
            .dividedBy(10 ** CHAIN_INFO_LIST[pool.denom].stakeCurrency.coinDecimals)
            .times(stakedDenomUsdValue)
            .toNumber()
        }
      })
    )
  }

export { adapter }
