import { assert } from '@/utils'

import {
  SelectedCoinDenom,
  SelectedStakedCoinDenom,
  isSelectedCoinDenom,
  isStakedSelectedCoinDenom
} from '@/wallet-utils'

const getDenomsFromPoolId = (poolId: string): { stakedDenom: SelectedStakedCoinDenom; denom: SelectedCoinDenom } => {
  const [stakedDenom, denom] = poolId.split('/')
  assert(isSelectedCoinDenom(denom), `Invalid pool denom ${denom} for ${poolId}`)
  assert(isStakedSelectedCoinDenom(stakedDenom), `Invalid pool staked denom ${stakedDenom} for ${poolId}`)
  return { stakedDenom, denom }
}

export { getDenomsFromPoolId }
