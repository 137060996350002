const getFeatureFlags = () => {
  if (process.env.NEXT_PUBLIC_FEATURE_FLAGS) {
    try {
      return JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAGS)
    } catch (e) {
      return {}
    }
  }

  return {}
}

const FEATURE_FLAGS: Record<string, boolean> = getFeatureFlags()

export { FEATURE_FLAGS }
