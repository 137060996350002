import React from 'react'
import { Anchor, Group, Text } from '@mantine/core'
import { Icon, IconName } from '@/components'

interface SidebarLiteExternalLinkProps {
  href: string
  icon: IconName
  label: string
}

const SidebarLiteExternalLink: React.FC<SidebarLiteExternalLinkProps> = (props) => {
  return (
    <Anchor
      href={props.href}
      target="_blank"
      variant="text"
      sx={(t) => ({
        display: 'block',
        width: '100%',
        background: 'transparent',
        borderRadius: t.radius.md,
        '&:hover': { background: '#FAF8F9' }
      })}
      px="xs">
      <Group spacing={12} sx={{ height: 40 }}>
        <Icon name={props.icon} sx={(t) => ({ color: t.colors.gray[7] })} size={22} />

        <Text inline size="sm" sx={(t) => ({ color: t.colors.gray[7] })} weight={500}>
          {props.label}
        </Text>

        
        <Icon name="externalLink" fill="#BCBCBC" size={12} />
      </Group>
    </Anchor>
  )
}

export { SidebarLiteExternalLink }
