import * as React from 'react'
import { SVGProps } from 'react'
const SvgQuestionCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.772 14.685v.056M8.92 8.733c0-2.47 4.098-2.751 4.098 0 0 1.74-2.246 1.347-2.246 4.098"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx={11}
      cy={10.718}
      rx={10}
      ry={9.718}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgQuestionCircle
