import { Skeleton as OriginalSkeleton, SkeletonProps as OriginalSkeletonProps } from '@mantine/core'

interface SkeletonProps extends OriginalSkeletonProps {
  mode?: string
}

const Skeleton = ({ mode = 'light', ...props }: SkeletonProps) => {
  return (
    <OriginalSkeleton {...props} sx={mode === 'dark' ? (t) => ({ backgroundColor: t.colors.gray[9] }) : undefined} />
  )
}

export { Skeleton }
