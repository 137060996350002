import { ColorSchemeProvider as OriginalColorSchemeProvider } from '@mantine/core'
import { createContext, useCallback, useContext, useState } from 'react'

interface ColorSchemeContext {
  colorScheme: 'light' | 'dark'
  toggleColorScheme: () => void
}

const ColorScheme = createContext<ColorSchemeContext>({
  colorScheme: 'light',
  toggleColorScheme: () => {}
})

const ColorSchemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>('light')

  const toggleColorScheme = useCallback(() => {
    setColorScheme(colorScheme === 'light' ? 'dark' : 'light')
  }, [colorScheme, setColorScheme])

  return (
    <OriginalColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      {children}
    </OriginalColorSchemeProvider>
  )
}

const useColorScheme = () => useContext(ColorScheme)

export { ColorSchemeProvider, useColorScheme }
