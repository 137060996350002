import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const useSmallScreen = () => {
  const theme = useMantineTheme()
  const isSmallScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`, true)
  return { isSmallScreen }
}

export { useSmallScreen }
