import * as React from 'react'
import { SVGProps } from 'react'
const SvgNight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.63A7.015 7.015 0 1 1 6.37 0 5.456 5.456 0 0 0 14 7.63Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgNight
