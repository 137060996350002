import BigNumber from 'bignumber.js'
import { fatal } from '@/utils'
import { redis } from '@/redis'
import { DexAdapter } from './types'

const crescent: DexAdapter = async ({ selectedCoinDenom, priceInUsd }) => {
  const [tokenValue, stTokenValue] = await Promise.all([
    // sheet_CRESCENT_DEFI_SWAP_RATE_UMEE/stUMEE
    redis.get<number>(`sheet_CRESCENT_DEFI_SWAP_RATE_${selectedCoinDenom}/st${selectedCoinDenom}`),
    // sheet_CRESCENT_DEFI_SWAP_RATE_stUMEE/UMEE
    redis.get<number>(`sheet_CRESCENT_DEFI_SWAP_RATE_st${selectedCoinDenom}/${selectedCoinDenom}`)
  ])

  if (tokenValue == null || stTokenValue == null) {
    throw fatal(`Missing tokenValue and stTokenValue for ${selectedCoinDenom}`)
  }

  return {
    tokenValue: Number(tokenValue),
    stTokenValue: Number(stTokenValue),
    stTokenValueInUsd: new BigNumber(stTokenValue).multipliedBy(priceInUsd).toNumber()
  }
}

export { crescent }
