import { useState } from 'react'
import { useInterval } from './useInterval'

// @TODO: accept interval, maybe?
const useNow = () => {
  const [now, setNow] = useState(() => getNowUtc())

  useInterval(() => {
    setNow(getNowUtc())
  }, 60_000)

  return { now }
}

// Will make it simpler to make accurate date comparisons,
// especially when functions like `differenceInCalendayDays`
// from date-fns are ran
const getNowUtc = (): Date => {
  const date = new Date()

  return new Date(
    // There has been discrepancy in time because new Date(year, month, day, hours, minutes, seconds)
    // constructor creates a date in the local time zone, not in UTC. To create
    // a date in UTC, you should use the Date.UTC method.
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  )
}

export { useNow }
