const PLACEHOLDER = '...'

// Puts an ellipsis on the middle
// Removes 3 from the right to take into account for the ellipsis placeholder
const ellipsis = (str: string, limit: number) => {
  if (str.length <= limit || str.length <= PLACEHOLDER.length) return str
  const leftCursor = limit / 2
  const rightCursor = str.length - limit / 2 + PLACEHOLDER.length
  return str.substr(0, leftCursor) + PLACEHOLDER + str.substr(rightCursor)
}

const and = (data: readonly string[], separator = 'and') => {
  const input = data.slice(0, data.length - 1)

  const last = data[data.length - 1]

  if (!input.length) {
    return last
  }

  return `${input.join(', ')}${input.length >= 2 ? ',' : ''} ${separator} ${last}`.trim()
}

export { ellipsis, and }
