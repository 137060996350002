import axios from 'axios'
import { StdFee, encodeSecp256k1Pubkey } from '@cosmjs/amino'
import {
  SignerData,
  DeliverTxResponse,
  QueryClient,
  setupAuthExtension,
  setupBankExtension,
  setupTxExtension,
  setupStakingExtension
} from '@cosmjs/stargate'
import { EncodeObject, isOfflineDirectSigner } from '@cosmjs/proto-signing'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { generateEndpointBroadcast, generatePostBodyBroadcast } from '@evmos/provider'
import { createTxRaw } from '@evmos/proto'
import { createTxIBCMsgTransfer } from '@evmos/transactions'
import { EthSignType } from '@keplr-wallet/types'
import { Buffer } from 'buffer'
import { EVMOS_CHAIN_INFO } from '@/config'
import { fatal, poll } from '@/utils'
import { broadcastTx, convertDenomToMicroDenom } from '../utils'
import { Account, TxQueryResponse } from '../types'
import { EvmosIbcReturnType } from './signer-types'
import { Uint53 } from '@cosmjs/math'
import { Tendermint34Client } from '@cosmjs/tendermint-rpc'

const evmosSupportedWalletTypes = ['keplr-extension', 'leap-extension', 'cosmostation-extension']

// These are the recommended functions to sign and broadcast
// ibc transactions that could come from Evmos to Stride.

const simulateEvmosIbcTransaction = async (
  account: Account,
  address: string,
  messages: readonly EncodeObject[],
  memo: string | undefined
) => {
  const anyMsgs = messages.map((m) => account.client.registry.encodeAsAny(m))

  // We need this because it gives us a pubkey with the type we need
  const accountFromSigner = (await account.signer.getAccounts()).find((account) => {
    return account.address === address
  })

  if (!accountFromSigner) {
    throw new Error('Failed to retrieve account from signer')
  }

  const pubkey = encodeSecp256k1Pubkey(accountFromSigner.pubkey)

  const { sequence } = await account.client.getSequence(address)

  const tendermintClient = await Tendermint34Client.connect(EVMOS_CHAIN_INFO.rpc)

  const queryClient = QueryClient.withExtensions(
    tendermintClient,
    setupAuthExtension,
    setupBankExtension,
    setupStakingExtension,
    setupTxExtension
  )

  const { gasInfo } = await queryClient.tx.simulate(anyMsgs, memo, pubkey, sequence)

  if (!gasInfo) {
    throw new Error('Could not find gas info in simulation response')
  }

  return Uint53.fromString(gasInfo.gasUsed.toString()).toNumber()
}

// Almost 1:1 API parity with `SigningStargateClient.sign`
const signEvmosIbcTransaction = async (
  account: Account,
  _address: string,
  messages: EncodeObject[],
  fee: StdFee,
  memo: string,
  _explicitSignerData?: SignerData
): Promise<EvmosIbcReturnType> => {
  const { accountNumber, sequence } = await account.client.getSequence(account.address)

  const message = messages[0]?.value

  if (message == null) {
    throw fatal('Unable to sign an ibc transaction without any messages.')
  }

  const feeAmount = fee.amount[0]

  if (feeAmount == null) {
    throw fatal('Unable to sign an ibc transaction without a fee amount.')
  }

  // For some reason Ledger transactions expects gas fees (?)
  // @TODO: Let's check this out once we start working on Evmos + Ledger again.
  // We just made some changes with `convertDenomToMicroDenom` and
  // in short, we're already calculating micro denom based on the denom being sent
  // directly on the mutation (useSignSendToken)
  const payloadFee = {
    amount: isOfflineDirectSigner(account.signer)
      ? feeAmount.amount
      : String(convertDenomToMicroDenom(0.05, feeAmount.denom)),
    denom: feeAmount.denom,
    gas: fee.gas
  }

  const chain = {
    chainId: 9001,
    cosmosChainId: EVMOS_CHAIN_INFO.chainId
  }

  const sender = {
    accountAddress: account.address,
    sequence,
    accountNumber,
    pubkey: Buffer.from(account.pubkey).toString('base64')
  }

  const { signDirect, eipToSign } = createTxIBCMsgTransfer(
    {
      chain,
      fee: payloadFee,
      sender,
      memo
    },
    {
      sourcePort: message.sourcePort,
      sourceChannel: message.sourceChannel,
      amount: message.token.amount,
      denom: message.token.denom,
      receiver: message.receiver,
      timeoutTimestamp: String(message.timeoutTimestamp),
      // https://ibc.cosmos.network/main/ibc/overview.html#ibc-client-heights
      // Evmos suggested that their revision number is their chain id's revision - 9001
      revisionNumber: 9001,
      // Evmos suggested to use uint64_max
      revisionHeight: Number.MAX_SAFE_INTEGER
    }
  )

  // We have a strong assumption that the default signing type is SIGN_DIRECT for non-Ledger transactions.
  if (isOfflineDirectSigner(account.signer)) {
    const { signature, signed } = await account.signer.signDirect(account.address, {
      bodyBytes: signDirect.body.toBinary(),
      authInfoBytes: signDirect.authInfo.toBinary(),
      accountNumber: BigInt(accountNumber),
      chainId: EVMOS_CHAIN_INFO.chainId
    })

    return {
      type: 'evmos',
      value: createTxRaw(signed.bodyBytes, signed.authInfoBytes, [
        new Uint8Array(Buffer.from(signature.signature, 'base64'))
      ])
    }
  }

  if (!evmosSupportedWalletTypes.includes(account.wallet.walletName))
    throw fatal(`Wallet type ${account.wallet.walletName} does not support Evmos signing.`)

  let walletInstance

  switch (account.wallet.walletName) {
    case 'keplr-extension':
      walletInstance = window.keplr
      break
    case 'leap-extension':
      walletInstance = window.leap
      break
    case 'cosmostation-extension':
      walletInstance = window.cosmostation.providers.keplr
      break
  }

  if (!walletInstance) throw fatal(`Could not find wallet with Evmos signing support.`)

  const signature = await walletInstance.signEthereum(
    EVMOS_CHAIN_INFO.chainId,
    account.address,
    JSON.stringify(eipToSign),
    EthSignType.EIP712
  )

  return {
    type: 'evmos-ledger',
    value: createTxRaw(signDirect.body.toBinary(), signDirect.authInfo.toBinary(), [signature])
  }
}

interface BroadcastResponse {
  tx_response: {
    height: string
    code: number
    txhash: string
    raw_log: string
    data: string
    gas_used: string
    gas_wanted: string
    log: string
    codespace: string
  }
}

const QUERY_TRANSACTION_INTERVAL = 3000

const QUERY_TRANSACTION_ATTEMPTS = 20

const QUERY_TRANSACTION_ATTEMPT_MS = QUERY_TRANSACTION_INTERVAL * QUERY_TRANSACTION_ATTEMPTS

// Almost 1:1 API parity with StargateClient.broadcastTx
// Here, we also re-implemented Stargate's broadcasting function fitted for Evmos' broadcasting method.
// First, we'll try to broadcast the transaction. Second, we'll do a series of checks, then we'll poll
// the transaction until it's there. Otherwise, we timeout after 60 seconds.
// @TODO: Implement standard tx error (via `assertTxSuccess`)
const broadcastEvmosIbcTransaction = async (
  account: Account,
  payload: EvmosIbcReturnType
): Promise<DeliverTxResponse> => {
  if (payload.type === 'evmos') {
    const bytes = TxRaw.encode(payload.value.message).finish()

    if (!account.client) {
      throw fatal('Stargate client does not exist')
    }

    return broadcastTx(account.client, bytes)
  }

  const instance = axios.create({ baseURL: EVMOS_CHAIN_INFO.rest })

  const broadcastResponse = await instance.post<BroadcastResponse>(
    generateEndpointBroadcast(),
    JSON.parse(generatePostBodyBroadcast(payload.value, 'BROADCAST_MODE_SYNC'))
  )

  const { code, raw_log, codespace, txhash } = broadcastResponse.data.tx_response

  // First, we'll exit early if the transaction code is not 0.
  if (broadcastResponse.data.tx_response.code) {
    throw new Error(`Broadcasting transaction failed with code ${code} (codespace: ${codespace}). Log: ${raw_log}`)
  }

  const transactionParameters = new URLSearchParams()
  transactionParameters.append('events', `tx.hash='${txhash}'`)

  const pollFn = async () => {
    try {
      const transactionResponse = await instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${transactionParameters}`)
      if (transactionResponse.data.tx_responses.length === 0) return null
      return transactionResponse.data.tx_responses[0]
    } catch (e) {
      // @TODO: Check for BroadcastTxError like we're doing with `broadcastTx`
      // // https://github.com/Stride-Labs/interface/issues/535
      if (process.env.NODE_ENV === 'development') {
        console.error(e)
      }

      return null
    }
  }

  // broadcastResponse is unlikely to contain the transaction data.
  // We'll poll for the transaction data for one minute.
  const transaction = await poll(pollFn, Boolean, {
    ms: QUERY_TRANSACTION_INTERVAL,
    max: QUERY_TRANSACTION_ATTEMPTS
  })

  // If transaction is still null, it's likely it's timed out.
  if (transaction == null) {
    throw new Error(
      `Transaction with hash ${txhash} was submitted but was not yet found on the chain. You might want to check later. There was a wait of ${
        QUERY_TRANSACTION_ATTEMPT_MS / 1000
      } seconds.`
    )
  }

  return {
    height: Number(transaction.height),
    code: Number(transaction.code),
    transactionHash: transaction.txhash,
    gasUsed: BigInt(transaction.gas_used),
    gasWanted: BigInt(transaction.gas_wanted),
    rawLog: transaction.raw_log,
    events: transaction.events,
    // @TODO: Look into adding this to the response so we're consistent
    txIndex: 0,
    // @TODO: Look into adding this to the response so we're consistent
    msgResponses: []
  }
}

export { simulateEvmosIbcTransaction, signEvmosIbcTransaction, broadcastEvmosIbcTransaction }
