import { FEATURE_FLAGS } from '@/feature-flags'
import { SelectedCoinDenom } from '@/wallet-utils/types'

const AIRDROP_CONFIG = {
  guideUrl: 'https://stride.zone/blog/stride-airdrop-details',
  weights: {
    claim: 0.2,
    stake: 0.2,
    liquidStake: 0.6
  }
} as const

// Why is this not a `CHAIN_SUPPORTS_AIRDROP` variable inside `chain-config.ts`?
// Airdrop does not have 1:1 mapping with chains we have liquid stake support for.
// Often, Airdrop is released much, much later after liquid staking support.
//
// stride = airdrop for the testnet rewards
const AIRDROP_IDENTIFIERS = [
  'stride',
  'gaia',
  'osmosis',
  'juno',
  'stars',
  'luna',
  'evmos',
  'injective',
  'umee',
  'comdex',
  'sommelier'
] as const

type AirdropIdentifier = (typeof AIRDROP_IDENTIFIERS)[number]

// 1 to 1 mapping from airdrop identifier to denom / chain info
const AIRDROP_IDENTIFIER_DENOMS: Record<AirdropIdentifier, SelectedCoinDenom> = {
  stride: 'STRD',
  gaia: 'ATOM',
  osmosis: 'OSMO',
  juno: 'JUNO',
  stars: 'STARS',
  luna: 'LUNA',
  evmos: 'EVMOS',
  injective: 'INJ',
  umee: 'UMEE',
  comdex: 'CMDX',
  sommelier: 'SOMM'
}

// @TODO: Probably should based off `AIRDROP_IDENTIFIERS` instead of another variable.
const AIRDROP_COIN_DENOMS = ['ATOM', 'OSMO', 'STARS', 'JUNO', 'LUNA', 'EVMOS', 'INJ', 'UMEE', 'CMDX', 'SOMM'] as const

// For most chains, Stride uses their Stride address to get their address from another chain.
// This is called mechanical mapping. However, this does not work for some chains because
// they have a different coin type, etc.
const AIRDROP_REQUIRES_LINKING: Partial<Record<AirdropIdentifier, boolean>> = {
  evmos: true,
  injective: true
} as const

// Currently, TIA is the default selected chain for liquid staking.
// However, TIA does not have Airdrop support yet, so it doesn't make
// sense that we link users from the Airdrop page to the TIA liquid staking page.
const AIRDROP_LIQUID_STAKE_URL_DEFAULT_DENOM = 'ATOM'

// @TOODO: Rename to TIA_AIDROP_LIMIT_OVERALL since this is not a daily number
const TIA_DAILY_AIRDROP_LIMIT_OVERALL = 5_000_000

const TIA_DAILY_AIRDROP_LIMIT_ROUND_1 = 50_000

const TIA_DAILY_AIRDROP_LIMIT_ROUND_2 = 22_222

const TIA_DAILY_AIRDROP_START_ROUND_1 = '2024-02-01 00:00:00'

const TIA_DAILY_AIRDROP_START_ROUND_2 = '2024-04-01 00:00:00'

const TIA_DAILY_AIRDROP_END = '2024-06-29 23:59:59' // 29 is the last day of rewards; 30 onwards, no rewards

const TIA_DAILY_AIRDROP_DAYS_SINCE_MAX_ROUND_1 = 60

const TIA_DAILY_AIRDROP_DAYS_SINCE_MAX_ROUND_2 = 90

const TIA_MODE: 'earn' | 'transitional' | 'claim' = FEATURE_FLAGS.TIA_AIRDROP_CLAIM ? 'claim' : 'transitional'

const TIA_AIRDROP_TRANSITION_END_DATE = '2024-07-30 19:59:59'

// The claimable amount that the user gets are the X amount of rewards they earned from 180 days ago.
// We use this to inform why the user does not have claimable rewards at the moment.
const TIA_CLAIMABLE_EARNED_DAYS_AGO = 180

const DYM_OVERALL_AIDROP_LIMIT = 250_000

const DYM_DAILY_AIRDROP_LIMIT = 2_083

const DYM_AIRDROP_START = '2024-03-14'

const DYM_AIRDROP_MAX_ELAPSED_DAYS = 120

const DYM_DAILY_AIRDROP_END = '2024-07-12 23:59:59'

const DYM_AIRDROP_TRANSITION_END_DATE = '2024-08-12 19:59:59'

const DYM_MODE: 'earn' | 'transitional' | 'claim' = FEATURE_FLAGS.DYM_AIRDROP_CLAIM ? 'claim' : 'transitional'

const DYM_CLAIMABLE_EARNED_DAYS_AGO = 180

const DYDX_AIRDROP_SUPPORT_ENDS = new Date('Thu Jun 27 2024 21:00:00 GMT+0800')

const DYDX_MODE: 'support' | 'claim' = 'claim'

const SAGA_AIRDROP_SUPPORT_ENDS = new Date('Mon Sep 01 2024 21:00:00 GMT+0800')

export {
  AIRDROP_CONFIG,
  AIRDROP_IDENTIFIERS,
  AIRDROP_IDENTIFIER_DENOMS,
  AIRDROP_COIN_DENOMS,
  AIRDROP_REQUIRES_LINKING,
  AIRDROP_LIQUID_STAKE_URL_DEFAULT_DENOM,
  TIA_DAILY_AIRDROP_LIMIT_OVERALL,
  TIA_DAILY_AIRDROP_LIMIT_ROUND_1,
  TIA_DAILY_AIRDROP_LIMIT_ROUND_2,
  TIA_DAILY_AIRDROP_START_ROUND_1,
  TIA_DAILY_AIRDROP_START_ROUND_2,
  TIA_DAILY_AIRDROP_END,
  TIA_DAILY_AIRDROP_DAYS_SINCE_MAX_ROUND_1,
  TIA_DAILY_AIRDROP_DAYS_SINCE_MAX_ROUND_2,
  TIA_AIRDROP_TRANSITION_END_DATE,
  TIA_CLAIMABLE_EARNED_DAYS_AGO,
  TIA_MODE,
  DYM_OVERALL_AIDROP_LIMIT,
  DYM_DAILY_AIRDROP_LIMIT,
  DYM_AIRDROP_START,
  DYM_AIRDROP_MAX_ELAPSED_DAYS,
  DYM_DAILY_AIRDROP_END,
  DYM_MODE,
  DYM_AIRDROP_TRANSITION_END_DATE,
  DYM_CLAIMABLE_EARNED_DAYS_AGO,
  DYDX_AIRDROP_SUPPORT_ENDS,
  DYDX_MODE,
  SAGA_AIRDROP_SUPPORT_ENDS
}
