import { DefiIntegration } from '@/page-components/DefiIntegrations/types'
import { adapters, getForgePoolPage } from '@/page-components/DefiIntegrations/adapters'

const OSMOSIS_INTEGRATION: DefiIntegration = {
  id: 'OSMOSIS',
  name: 'Osmosis',
  tagline: 'Provide Liquidity',
  description: 'Swap, provide liquidity and earn incentives on the Osmosis DEX.',
  denom: 'OSMO',
  icon: 'OSMO',
  style: 'pair',
  url: 'https://app.osmosis.zone/pools',
  poolPage: (pool) => `https://app.osmosis.zone/pool/${pool.poolId}`,
  adapter: adapters.osmosis,
  type: 'pool'
}

const UMEE_INTEGRATION: DefiIntegration = {
  id: 'UMEE',
  name: 'UX',
  tagline: 'Borrow & Lend',
  description: 'Lend and borrow Cosmos tokens on the Umee money market.',
  denom: 'UMEE',
  icon: 'UMEE',
  style: 'pair',
  url: 'https://app.umee.cc/#/dashboard',
  poolPage: () => `https://app.umee.cc/#/dashboard`,
  adapter: adapters.umee,
  type: 'lending'
}

// waiting on contract info from Kent
const ASTROPORT_INTEGRATION: DefiIntegration = {
  id: 'ASTROPORT',
  name: 'Astroport',
  tagline: 'Provide Liquidity',
  description: 'Swap, provide liquidity and earn incentives on the Astroport DEX.',
  denom: 'INJ',
  icon: 'ASTROPORT',
  style: 'pair',
  url: 'https://app.astroport.fi/pools',
  poolPage: () => `https://app.astroport.fi/pools`,
  adapter: adapters.astroport(),
  type: 'pool'
}

const INTER_INTEGRATION: DefiIntegration = {
  id: 'INTER',
  name: 'Inter Protocol',
  tagline: 'Mint Stablecoin',
  description: 'Provide collateral and mint the IST stablecoin on Inter Protocol.',
  denom: 'BLD',
  icon: 'IST',
  style: 'pair',
  url: 'https://inter.trade/',
  poolPage: () => `https://inter.trade/`,
  adapter: adapters.inter(),
  type: 'lending'
}

const FORGE_INTEGRATION: DefiIntegration = {
  id: 'FORGE',
  name: 'Forge',
  tagline: 'Provide Liquidity',
  description: 'Swap, provide liquidity and earn incentives on the Forge DEX.',
  denom: 'EVMOS',
  icon: 'FORGE',
  style: 'pair',
  url: 'https://forge.trade/#/pools',
  poolPage: getForgePoolPage,
  adapter: adapters.forge(),
  type: 'pool'
}

const SHADE_DEX_INTEGRATION: DefiIntegration = {
  id: 'SHADE',
  name: 'Shade DEX',
  tagline: 'Provide Liquidity',
  description: 'Swap, provide liquidity and earn incentives on the Shade DEX.',
  denom: 'SCRT',
  icon: 'SHADE',
  style: 'pair',
  url: 'https://app.shadeprotocol.io/swap/pools',
  poolPage: () => `https://app.shadeprotocol.io/swap/pools`,
  adapter: adapters.dex_shade(),
  type: 'pool'
}

const SHADE_LEND_INTEGRATION: DefiIntegration = {
  id: 'SHADE_LEND',
  name: 'Shade Lending',
  tagline: 'Mint Stablecoin',
  description: 'Provide collateral and mint the SILK stablecoin on Shade.',
  denom: 'SCRT',
  icon: 'SHADE',
  style: 'pair',
  url: 'https://app.shadeprotocol.io/borrow',
  poolPage: () => `https://app.shadeprotocol.io/borrow`,
  adapter: adapters.lending_shade(),
  type: 'lending'
}

const MARS_INTEGRATION: DefiIntegration = {
  id: 'MARS',
  name: 'Mars',
  tagline: 'Borrow & Lend',
  description: 'Lend and borrow Cosmos tokens on the Mars money market.',
  denom: 'OSMO',
  icon: 'MARS',
  style: 'pair',
  url: 'https://osmosis.marsprotocol.io/redbank',
  poolPage: (pool) => `https://osmosis.marsprotocol.io/redbank/deposit/${pool.stakedDenom}`,
  adapter: adapters.mars(),
  type: 'lending'
}

const KUJIRA_INTEGRATION: DefiIntegration = {
  id: 'KUJIRA',
  name: 'Kujira USK',
  tagline: 'Mint Stablecoin',
  description: 'Provide collateral and mint the USK stablecoin on Kujira.',
  denom: 'KUJI',
  icon: 'KUJI',
  style: 'pair',
  url: 'https://blue.kujira.app/mint',
  poolPage: () => `https://blue.kujira.app/mint`,
  adapter: adapters.kujira(),
  type: 'lending'
}

const DEMEX_INTEGRATION: DefiIntegration = {
  id: 'DEMEXNITRON',
  name: 'Demex',
  tagline: 'Mint Stablecoin',
  description: 'Provide collateral and mint the USC stablecoin on Demex Nitron.',
  denom: 'SWTH',
  icon: 'DEMEX',
  style: 'pair',
  url: 'https://app.dem.exchange/nitron',
  poolPage: () => `https://app.dem.exchange/nitron`,
  adapter: adapters.demex(),
  type: 'lending'
}

const LEVANA_INTEGRATION: DefiIntegration = {
  id: 'LEVANA',
  name: 'Levana',
  tagline: 'Provide Liquidity',
  description: 'Swap, provide liquidity and earn incentives on Levana.',
  denom: 'OSMO',
  icon: 'LVN',
  style: 'single',
  url: 'https://trade.levana.finance/osmosis/earn',
  poolPage: (pool) => `https://trade.levana.finance/osmosis/earn/${pool.stakedDenom}_${pool.denom}`,
  adapter: adapters.levana,
  type: 'pool'
}

const DEFI_INTEGRATIONS = [
  OSMOSIS_INTEGRATION,
  UMEE_INTEGRATION,
  ASTROPORT_INTEGRATION,
  MARS_INTEGRATION,
  INTER_INTEGRATION,
  FORGE_INTEGRATION,
  SHADE_DEX_INTEGRATION,
  SHADE_LEND_INTEGRATION,
  KUJIRA_INTEGRATION,
  DEMEX_INTEGRATION,
  LEVANA_INTEGRATION
]

// I am not sure how to properly categorize stablecoin for now. To me,
// it looks like a subset of lending protocol. It's a lot of change to
// introduce a new type, so might as well add a flag for now.
//
// @TODO: In the future, probably have separate integration types for
// lending and stable coin
const DEFI_INTEGRATION_IS_STABLECOIN_PROTOCOL: Record<string, boolean> = {
  DEMEXNITRON: true,
  SHADE_LEND: true,
  KUJIRA: true,
  INTER: true
}

export { DEFI_INTEGRATIONS, DEFI_INTEGRATION_IS_STABLECOIN_PROTOCOL }
