import * as React from 'react'
import { SVGProps } from 'react'
const SvgStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 11.5h13.5M4 11.5a3 3 0 0 1-3-3m3 3a3 3 0 0 0 0 6h13.5a3 3 0 0 0 0-6m0 0a3 3 0 0 0 3-3M1 8.5a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3M1 8.5a4.5 4.5 0 0 1 .9-2.7l2.587-3.45A3.375 3.375 0 0 1 7.187 1h7.126c1.062 0 2.062.5 2.7 1.35L19.6 5.8a4.5 4.5 0 0 1 .9 2.7m-3 6h.008v.008H17.5V14.5Zm0-6h.008v.008H17.5V8.5Zm-3 6h.008v.008H14.5V14.5Zm0-6h.008v.008H14.5V8.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgStatus
