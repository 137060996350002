import { DeliverTxResponse, SigningStargateClient } from '@cosmjs/stargate'
import { StdFee } from '@cosmjs/amino'
import { EncodeObject } from '@cosmjs/proto-signing'
import { assertTxSuccess } from './errors'

// @TODO: Move to tx.ts

// Wrap Stargate's broadcastTx function so our standard errors pass through.
// Until recently, I believed that `broadcastTx` is unlikely to fail as you
// may have to ran its result to `assertIsDeliverTxSuccess`.
//
// Turns out, broadcastTx itself can fail with a different error shape; instead of DeliverTxResponse,
// you'll get `BroadcastTxError`. Based on my conversation from Sam, there are
// two stages: `CheckTx` and `DeliverTx` - my understanding is that one happens before transaction
// is committed to a block, meanwhile the latter happens when a transaction is already committed
// to a block. TL;DR: Basic validation error vs deeper/stateful error
const broadcastTx = async (client: SigningStargateClient, bytes: Uint8Array): Promise<DeliverTxResponse> => {
  try {
    return await client.broadcastTx(bytes)
  } catch (e) {
    assertTxSuccess(e)
    throw e
  }
}

// Wrap Stargate's signAndBroadcast function so our standard errors pass through.
// Until recently, I believed that `signAndBroadcast` is unlikely to fail as you
// may have to ran its result to `assertIsDeliverTxSuccess`.
//
// Turns out, `signAndBroadcast` itself can fail with a different error shape; instead of `DeliverTxResponse`,
// you'll get `BroadcastTxError`. Based on my conversation from Sam, there are
// two stages: `CheckTx` and `DeliverTx` - my understanding is that one happens before transaction
// is committed to a block, meanwhile the latter happens when a transaction is already committed
// to a block. TL;DR: Basic validation error vs deeper/stateful error
const signAndBroadcast = async (
  client: SigningStargateClient,
  signerAddress: string,
  messages: readonly EncodeObject[],
  fee: StdFee | 'auto' | number,
  memo?: string
): Promise<DeliverTxResponse> => {
  try {
    return await client.signAndBroadcast(signerAddress, messages, fee, memo)
  } catch (e) {
    assertTxSuccess(e)
    throw e
  }
}

export { broadcastTx, signAndBroadcast }
