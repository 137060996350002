import { CHAIN_CONFIG } from '@/config/chain-config'
import {
  EVMOS_CHAIN_INFO,
  INJ_CHAIN_INFO,
  DYDX_CHAIN_INFO,
  STRIDE_CHAIN_INFO,
  DYM_CHAIN_INFO,
  ISLM_CHAIN_INFO
} from '@/config/chain-info'
import BigNumber from 'bignumber.js'
import { parseUnits, formatUnits } from 'ethers'

// This is just an easy workaround for now, refer to the long comment below.
const denomsWithUnconventionalCoinDecimals = [
  EVMOS_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
  `st${EVMOS_CHAIN_INFO.stakeCurrency.coinMinimalDenom}`,
  CHAIN_CONFIG.EVMOS.ibcDenom,
  CHAIN_CONFIG.EVMOS.stakedIbcDenom,

  INJ_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
  `st${INJ_CHAIN_INFO.stakeCurrency.coinMinimalDenom}`,
  CHAIN_CONFIG.INJ.ibcDenom,
  CHAIN_CONFIG.INJ.stakedIbcDenom,

  DYDX_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
  `st${DYDX_CHAIN_INFO.stakeCurrency.coinMinimalDenom}`,
  CHAIN_CONFIG.DYDX.ibcDenom,
  CHAIN_CONFIG.DYDX.stakedIbcDenom,

  DYM_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
  `st${DYM_CHAIN_INFO.stakeCurrency.coinMinimalDenom}`,
  CHAIN_CONFIG.DYM.ibcDenom,
  CHAIN_CONFIG.DYM.stakedIbcDenom,

  ISLM_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
  `st${ISLM_CHAIN_INFO.stakeCurrency.coinMinimalDenom}`,
  CHAIN_CONFIG.ISLM.ibcDenom,
  CHAIN_CONFIG.ISLM.stakedIbcDenom
]

// This function allows you to correctly the muliplier to allow the conversion of
// 1 atom to 1_000_000 atom and vice-versa. In this case, the multiplier is 1e6.
// However, for chains like Evmos and Injective, the multiplier is 1e18.
// In case you're not familiar with the scientific notation, 1e6 just means 1_000_000 (6 zeroes).
// 1e18, as you guessed it, just means 1 plus 18 zeroes after it.
//
// Multiplier (also known as coinDecimals) should set to the following:
//
// Whether you're sending a transaction, displaying a token value, you should always
// the the decimal count (coinDecimals) of the host / base token. For example, if you
// are sending aevmos or staevmos, you should use the coinDecimals of aevmos (18 in this case).
// To get the coinDecimals, you can either do account.currency.coinDecimals
// or SOME_CHAIN_INFO.stakeCurrency.coinDecimals
//
// If you're using this function, don't forget to test this on Evmos and a non-Evmos chain.
// Right now, there is an idea to require the minimal denom so that everything is
// thought through. However, it may not make any sense given that most of our supported
// chains are Cosmos-based (6-coin decimal standard)) Let's revisit this once we
// start supporting more chains.

// Currently, this makes a simple check on aevmos and inj.
// It does not take into account other chains or tokens that may have different coin decimals (like Evmos)
// @TODO: Once we start having more chains with custom decimals, let's either
// iterate on all the chains or make a look-up map with type Record<SelectedIBCDenom, SelectedCoinDenom>
// Reminder that this function is likely called without being memoized in the render function
// so using an array may not be efficient.
//
// Would be also great to have this typed rather than accepting a string.
// i.e., SelectedCoinMinimalDenom | SelectedCoinIbcDenom
//
// @WARN: This function does not support LSM. While only Cosmos Hub supports LSM, if in the future a chain
// like Evmos decides to support LSM, we'll have to make sure we're checking for it.
const getCoinDecimalsByMinimalDenom = (minimalDenom: string = ''): number => {
  return minimalDenom && denomsWithUnconventionalCoinDecimals.includes(minimalDenom)
    ? EVMOS_CHAIN_INFO.stakeCurrency.coinDecimals
    : STRIDE_CHAIN_INFO.stakeCurrency.coinDecimals
}

// string micro denom -> source of truths (via query)
// bigint micro denom -> used with other micro denosm for calculations within the app
// string denom -> user inputs, display values
// number denom -> used with other denoms for calculations for display values

// '1000000' (1_234_000 uatom) -> '1.234' (1.234 ATOM)
// By using this, often the intent is to display the amount in a more readable format.
//
// @TODO: We sometimes receive numbers that are supposedly microdenoms but have decimals in them
// e.g., 10800000000.000000000000000000 - make sure to prune this by yourself.
export const convertMicroDenomToDenom = (amountInMicroDenom: string | bigint, minimalDenom: string = ''): string => {
  const value = typeof amountInMicroDenom === 'string' ? amountInMicroDenom : amountInMicroDenom.toString()
  return formatUnits(value, getCoinDecimalsByMinimalDenom(minimalDenom))
}

// '1.234' (1.234 ATOM) -> '1234000' (1_234_000 uatom)
// By using this, often the intent is to convert an input into a useful type.
//
// @TODO: We sometimes receive numbers that have more than 6 decimals in them. This causes
// pareUnits to throw due to an underflow (precision loss). We should consider pruning decimals
// beyond 6 digits (based on cosmos microdenoms) or looking into Ether's FixedNumber class.
// https://github.com/ethers-io/ethers.js/issues/1322
export const convertDenomToMicroDenom = (amountInDenom: string | number, minimalDenom: string = ''): bigint => {
  const value = typeof amountInDenom === 'string' ? amountInDenom : amountInDenom.toString()
  return parseUnits(value, getCoinDecimalsByMinimalDenom(minimalDenom))
}

// @TODO: Introduce formatMicroDenom(...) where anything lower than X decimals are displayed as "< 0.001"
// Easy-access to BigNumber and conversions
export const formatMicroDenom = (amountInMicroDenom: string | bigint, minimalDenom: string = '', decimals = 2) => {
  return new BigNumber(convertMicroDenomToDenom(amountInMicroDenom, minimalDenom))
    .decimalPlaces(decimals, BigNumber.ROUND_DOWN)
    .toFormat()
}

// A version of formatMicroDenom for when we already have denoms and it's just extra work converting them back.
// Initially used with token to token conversions (ATOM to stATOM and vice-versa)
export const formatDenom = (amoutInDenom: string | number, decimals = 2) => {
  return new BigNumber(amoutInDenom).decimalPlaces(decimals, BigNumber.ROUND_DOWN).toFormat()
}

interface FormatCurrencyOptions {
  decimals?: number
}

export const formatCurrency = (amount: number | string | BigNumber, options?: FormatCurrencyOptions): string => {
  // BigNumber.toLocaleString seems to work differently. It does not include the
  // dollar sign and does not seem to add proper decimal places.
  const value = BigNumber.isBigNumber(amount) ? amount.toNumber() : Number(amount)

  // We can't use optional operator here because options?.decimals would be evaluated as false if its value is zero.
  // Also, I don't know how Number.toLocaleString works so might as well just provide it when needed and allow for
  // defaults to work for us
  const decimalProps =
    options && 'decimals' in options
      ? { minimumFractionDigits: options.decimals, maximumFractionDigits: options.decimals }
      : {}

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'usd',
    ...decimalProps
  })
}
