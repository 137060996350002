import * as React from 'react'
import { SVGProps } from 'react'
const SvgWarningTriangleCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={8} fill="currentColor" />
    <path d="m8 4.19 3.81 6.477H4.19L8 4.19Z" stroke="#fff" strokeLinejoin="round" />
    <path d="M8 6.857v1.524" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx={8} cy={9.524} r={0.381} fill="currentColor" />
  </svg>
)
export default SvgWarningTriangleCircle
