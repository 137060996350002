import * as React from 'react'
import { SVGProps } from 'react'
const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.48 8.477V11H1.193V1h2.45M6.335 1h5.145v5M11.48 1 4.947 7.35"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgExternalLink
