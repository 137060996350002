import * as React from 'react'
import { SVGProps } from 'react'
const SvgCrossCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={9.5} cy={10} r={9.5} fill="currentColor" />
    <path
      d="m12.214 7.286-5.428 5.428m0-5.428 5.428 5.428"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgCrossCircle
