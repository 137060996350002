import React from 'react'
import OriginalPlausibleProvider from 'next-plausible'

import { env } from '@/utils'

const PlausibleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <OriginalPlausibleProvider
      // The 'domain' prop specifies the domain name for which Plausible analytics will be tracked.
      domain={env('PLAUSIBLE_DOMAIN')}
      // The 'enabled' prop controls whether Plausible analytics is enabled in development mode.
      // By default, the tracking only works in production. Adding this prop allows us to track
      // events in development mode.
      enabled={env('PLAUSIBLE_DEVELOP_ENABLED') === 'true'}>
      {children}
    </OriginalPlausibleProvider>
  )
}

export { PlausibleProvider }
