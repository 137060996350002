import * as React from 'react'
import { SVGProps } from 'react'
const SvgAtom = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.714 1.286c-.248-.248-.615-.37-1.08-.363-.466.008-1.021.148-1.634.41-.613.263-1.272.644-1.938 1.121a15.744 15.744 0 0 0-1.948 1.66c-.62.62-1.183 1.282-1.66 1.948a10.524 10.524 0 0 0-1.121 1.939C1.07 8.614.93 9.169.923 9.634c-.008.466.115.833.363 1.08.5.5 1.477.483 2.714-.047 1.238-.53 2.636-1.531 3.886-2.782.62-.619 1.183-1.28 1.66-1.947.478-.667.859-1.326 1.121-1.939.263-.613.402-1.168.41-1.633.008-.466-.115-.833-.363-1.08M6 6v.007V6Z"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.286 1.286c-.247.248-.37.615-.363 1.08.009.466.148 1.021.41 1.634.263.613.644 1.272 1.121 1.938a15.742 15.742 0 0 0 1.66 1.948c.62.619 1.282 1.183 1.949 1.66.666.478 1.325.859 1.938 1.121.613.263 1.168.402 1.634.41.465.008.832-.115 1.08-.363.5-.5.482-1.477-.048-2.715-.53-1.237-1.531-2.635-2.781-3.885a15.743 15.743 0 0 0-1.948-1.66 10.524 10.524 0 0 0-1.939-1.121C3.387 1.07 2.831.93 2.366.923c-.466-.008-.833.115-1.08.363"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgAtom
