import { useState, useMemo } from 'react'
import { intervalToDuration, isAfter, eachDayOfInterval, addMonths } from 'date-fns'
import { useInterval } from '@/hooks'

interface UseCountdownParameters {
  target: Date
  padded?: boolean
}

interface UseCountdownDuration {
  days: string
  hours: string
  minutes: string
  seconds: string
}

const useCountdown = ({ target, padded = true }: UseCountdownParameters): UseCountdownDuration => {
  const [now, setNow] = useState(() => new Date())

  useInterval(() => {
    setNow(new Date())
  }, 1000)

  const duration: UseCountdownDuration = useMemo(() => {
    const pad = (n: number | undefined) => {
      if (padded) return String(n).padStart(2, '0')

      return String(n)
    }

    if (isAfter(now, target)) {
      return { days: pad(0), hours: pad(0), minutes: pad(0), seconds: pad(0) }
    }

    const remaining = intervalToDuration({
      start: now,
      end: target
    })

    // We'll convert the [remaining months] to [days] then get its sum with the [remaining days]
    const getNumberOfDays = (): number => {
      const remainingDays = Number(remaining.days)

      if (remaining.months) {
        return (
          eachDayOfInterval({
            start: now,
            end: addMonths(now, remaining.months)
          }).length + remainingDays
        )
      }

      return remainingDays
    }

    return {
      days: pad(getNumberOfDays()),
      hours: pad(remaining.hours),
      minutes: pad(remaining.minutes),
      seconds: pad(remaining.seconds)
    }
  }, [target, now])

  return duration
}

export { useCountdown }
