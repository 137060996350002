import * as React from 'react'
import { SVGProps } from 'react'
const SvgSecureTheNetwork = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 79 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 5.087c0-1.084.427-2.124 1.187-2.89A4.04 4.04 0 0 1 5.053 1h68.894c1.075 0 2.106.43 2.866 1.197A4.105 4.105 0 0 1 78 5.087v25.584a73.131 73.131 0 0 1-10.366 37.572A72.31 72.31 0 0 1 39.5 95a72.307 72.307 0 0 1-28.134-26.757A73.132 73.132 0 0 1 1 30.672V5.087Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgSecureTheNetwork
