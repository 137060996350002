import * as React from 'react'
import { SVGProps } from 'react'
const SvgClaimCompact = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.45 5.073h1.848c1.178 0 2.134.955 2.134 2.133v11.375a2.134 2.134 0 0 1-2.134 2.134H2.926A2.134 2.134 0 0 1 .792 18.58V7.206c0-1.178.956-2.133 2.134-2.133h1.973"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.612 1.727V14.33l-2.607-2.607M8.61 14.324l2.606-2.607"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgClaimCompact
