import React, { createContext, useContext, useState } from 'react'

interface ContextType {
  isOpen: boolean
  open: () => void
  close: () => void
}

const Context = createContext<ContextType | null>(null)

const NavigationMobileMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  return <Context.Provider value={{ isOpen, open, close }}>{children}</Context.Provider>
}

const useNavigationMobileMenu = () => {
  const context = useContext(Context)

  if (context == null) {
    throw new Error('useNavigationMobileMenu must be used within a NavigationMobileMenuProvider')
  }

  return context
}

export { NavigationMobileMenuProvider, useNavigationMobileMenu }
