import * as React from 'react'
import { SVGProps } from 'react'
const SvgWarningTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m8 1.55 7 11.9H1l7-11.9Z" stroke="currentColor" strokeWidth={1.2} strokeLinejoin="round" />
    <path d="M8 6.45v2.8" stroke="currentColor" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round" />
    <circle cx={8} cy={11.35} r={0.7} fill="currentColor" />
  </svg>
)
export default SvgWarningTriangle
