import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { fatal } from '@/utils'
import { redis } from '@/redis'
import { CHAIN_INFO_LIST } from '@/config'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { Coin } from '@cosmjs/amino'

const POOL_MAP = {
  stATOM: {
    denom: 'uist',
    ibcDenom: 'ibc/B1E6288B5A0224565D915D1F66716486F16D8A44BF33A9EC323DD6BA30764C35'
  }
}

const adapter =
  () =>
  async ({ pools, address }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const chain = CHAIN_INFO_LIST.IST
    const balancesResponse = await axios.get(`${chain.rest}/cosmos/bank/v1beta1/balances/${address}`)

    return await Promise.all(
      pools.map(async (pool) => {
        // @ts-ignore
        let poolConfig = POOL_MAP[pool.denom] || POOL_MAP[pool.stakedDenom]

        // @TODO: Use zod to validate response
        const balances: Coin[] = balancesResponse.data.balances

        const denomBalance = balances.find((balance) => balance.denom === poolConfig.denom)
        const stakedDenomBalance = balances.find((balance) => balance.denom === poolConfig.ibcDenom)

        const [stakedDenomUsdValue, denomUsdValue] = await Promise.all([
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.stakedDenom),
          redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.denom)
        ])

        if (!stakedDenomUsdValue || !denomUsdValue) {
          throw fatal(`Missing price for ${pool.stakedDenom} or ${pool.denom}`)
        }

        return {
          poolId: pool.poolId,
          bondedAmount: new BigNumber(denomBalance?.amount || 0).dividedBy(1e6).times(denomUsdValue).toNumber(),
          unbondedAmount: new BigNumber(stakedDenomBalance?.amount || 0)
            .dividedBy(1e6)
            .times(stakedDenomUsdValue)
            .toNumber()
        }
      })
    )
  }

export { adapter }
