import React from 'react'
import { Text, TextProps } from '@mantine/core'
import { InlineLoader } from '@/components'

interface TextLoaderOwnProps {
  loading: boolean
}

type TextLoaderProps<T> = TextProps<T> & TextLoaderOwnProps

function TextLoader<T = 'div'>({ loading, ...props }: TextLoaderProps<T>): React.ReactElement {
  // Mantine itself returns `as any` for its Text component because somehow it managed to really fuck up its types.
  // Whether it's because Polymorphic components are fucked, but whatever this is really annoying to fix.
  // @see https://github.com/mantinedev/mantine/blob/4.2.12/src/mantine-core/src/components/Text/Text.tsx#L112
  // @ts-expect-error
  return loading ? <InlineLoader /> : <Text {...props} />
}

export { TextLoader }
