import React from 'react'
import { Avatar, Group, Text, Box, Tooltip } from '@mantine/core'
import { ellipsis } from '@/utils'
import { useStrideWallet } from '@/contexts/wallet'

interface AccountDetailsProps {
  variant?: 'light' | 'dark'
}

// @TODO: Accessibility (so browser search works at least)
const AccountDetails: React.FC<AccountDetailsProps> = ({ variant = 'light' }) => {
  const strideAccount = useStrideWallet()

  const isDark = variant === 'dark'

  return (
    <Box
      py={2}
      pl={8}
      pr={2}
      sx={(t) => ({ backgroundColor: isDark ? t.colors.gray[8] : t.colors.gray[1], borderRadius: t.radius.xl })}>
      <Tooltip label={strideAccount?.address}>
        <Group spacing={8}>
          <Text size="sm" inline sx={(t) => ({ color: t.colors.gray[6] })}>
            {ellipsis(strideAccount?.address || '', 20)}
          </Text>
          <Avatar size={32} color="red" radius="lg">
            MK
          </Avatar>
        </Group>
      </Tooltip>
    </Box>
  )
}

export { AccountDetails }
