import BigNumber from 'bignumber.js'
import { DexAdapter } from './types'

const dymension: DexAdapter = async ({ priceInUsd }) => {
  return {
    tokenValue: 1,
    stTokenValue: 1,
    stTokenValueInUsd: new BigNumber(1).multipliedBy(priceInUsd).toNumber()
  }
}

export { dymension }
