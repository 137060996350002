import * as React from 'react'
import { SVGProps } from 'react'
const SvgLiquidStake = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.74 3a3.89 3.89 0 0 0-3.71 2.73c-.11.36-.36 1.42-1.18 1.92s-1.97.1-2.29 0a6.547 6.547 0 0 0-8.54 6.24c0 3.62 2.93 6.55 6.55 6.55 3.62 0 6.55-2.93 6.55-6.55 0-.42-.09-1.95.81-2.58.9-.63 1.53-.36 2.59-.62 1.75-.43 3.1-1.93 3.1-3.8 0-2.14-1.74-3.88-3.88-3.88V3Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgLiquidStake
