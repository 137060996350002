import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleBack = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.85 4.737h13.241c2.695 0 4.88 2.01 4.88 4.487 0 2.479-2.185 4.486-4.88 4.486H1.851"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m4.144 7.711-3.06-3.058 3.06-3.06"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgCircleBack
