import * as React from 'react'
import { SVGProps } from 'react'
const SvgEth = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity={0.6} d="M5.999.75v6.098l5.248 2.303L5.999.75Z" fill="currentColor" />
    <path d="M5.999.75.75 9.151l5.249-2.303V.75Z" fill="currentColor" />
    <path opacity={0.6} d="M5.999 13.103v4.143l5.251-7.135L6 13.103h-.001Z" fill="currentColor" />
    <path d="M5.999 17.246v-4.144L.75 10.111l5.249 7.135Z" fill="currentColor" />
    <path opacity={0.2} d="m5.999 12.144 5.248-2.993L5.999 6.85v5.294Z" fill="currentColor" />
    <path opacity={0.6} d="m.75 9.151 5.249 2.993V6.85L.75 9.15v.001Z" fill="currentColor" />
  </svg>
)
export default SvgEth
