import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { SelectedCoinDenom } from '@/wallet-utils'
import axios from 'axios'

interface CarbonAccountCollateralResponse {
  collaterals: Array<{ denom: string; cdp_denom: string; collateral_amount: string }>
}

interface CarbonAccountStablecoinResponse {
  principal_debt: string
  interest_debt: string
  initial_cumulative_interest_multiplier: string
}

const POOL_MAP = {
  stATOM: {
    denom: 'ibc/B7864B03E1B9FD4F049243E92ABD691586F682137037A9F3FCA5222815620B3C'
  },
  stOSMO: {
    denom: 'ibc/75249A18DEFBEFE55F83B1C70CAD234DF164F174C6BC51682EE92C2C81C18C93'
  },
  stLUNA: {
    denom: 'ibc/FBEE20115530F474F8BBE1460DA85437C3FBBFAF4A5DEBD71CA6B9C40559A161'
  },
  stEVMOS: {
    denom: 'ibc/0BA1FA7A554B4347A87BD37AFF331683298730F9F8FCECB4896D58BED4B31F00'
  }
}

const adapter =
  () =>
  async ({ pools, address }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const chain = CHAIN_INFO_LIST.SWTH
    const [collateralResponse, stablecoinResponse] = await Promise.all([
      axios.get<CarbonAccountCollateralResponse>(`${chain.rest}/carbon/cdp/v1/account_collateral/${address}`),
      axios.get<CarbonAccountStablecoinResponse>(`${chain.rest}/carbon/cdp/v1/account_stablecoin/${address}`)
    ])

    return await Promise.all(
      pools.map(async (pool) => {
        // @ts-ignore
        const poolConfig = POOL_MAP[pool.denom] || POOL_MAP[pool.stakedDenom]

        const collateral = collateralResponse.data.collaterals.find(
          (collateral: any) => collateral.denom === poolConfig.denom
        )

        const stakedDenomUsdValue = await redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + pool.stakedDenom)

        // if (!stakedDenomUsdValue) {
        //   throw fatal(`Missing price for ${pool.stakedDenom}`)
        // }

        return {
          poolId: pool.poolId,
          bondedAmount: new BigNumber(stablecoinResponse.data.principal_debt || 0)
            .dividedBy(10 ** CHAIN_INFO_LIST[pool.denom as SelectedCoinDenom].stakeCurrency.coinDecimals)
            .times(1)
            .toNumber(),
          unbondedAmount: new BigNumber(collateral?.collateral_amount || 0)
            .dividedBy(10 ** CHAIN_INFO_LIST[pool.denom as SelectedCoinDenom].stakeCurrency.coinDecimals)
            .times(stakedDenomUsdValue || 0)
            .toNumber()
        }
      })
    )
  }

export { adapter }
