import { useQuery } from '@tanstack/react-query'
import { Redis } from '@upstash/redis'
import { queryKeys } from '@/query-keys'
import { config } from '@/config'

const redis = new Redis({
  url: config.upstashRedis.announcement.url,
  token: config.upstashRedis.announcement.token
})

interface AnnouncementFlags {
  upgrade: boolean
  maintenance: boolean
  custom: boolean
  customMessage: string
}

const useAnnouncementFlags = () => {
  const handleRequest = async (): Promise<AnnouncementFlags> => {
    const [upgrade, maintenance, custom, customMessage] = await Promise.all([
      redis.get<boolean>('upgrade'),
      redis.get<boolean>('maintenance'),
      redis.get<boolean>('custom'),
      redis.get<string>('custom_message')
    ])

    return {
      upgrade: upgrade || false,
      maintenance: maintenance || false,
      custom: custom || false,
      customMessage: customMessage || ''
    }
  }

  return useQuery({
    queryKey: queryKeys.globalAnnouncementFlags,
    queryFn: handleRequest,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 60 * 1000
  })
}

export { useAnnouncementFlags }
