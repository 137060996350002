import * as React from 'react'
import { SVGProps } from 'react'
const SvgUpwardArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.5 6.5 6 3l2.083 2.143 4.837-3.691"
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10 .96 3.206.05v2.882"
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgUpwardArrow
