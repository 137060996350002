import * as React from 'react'
import { SVGProps } from 'react'
const SvgStakeStrd = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.087 12.672c0 5.39-4.497 9.758-10.043 9.758C5.497 22.43 1 18.061 1 12.672c0-5.39 4.497-9.759 10.043-9.759M19.174 1v7.652M23 4.826h-7.652"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.206 15.419c-.954 0-1.727-.732-1.727-1.634v-2.447h2.228V9.914h-2.228V7.561l-1.707.862v1.491H8.535c-2.257 0-2.85 2.573-1.114 3.531l1.765 1.123c.39.273.231.848-.318.848H6.466v1.437h2.402c2.27 0 2.85-2.573 1.113-3.53L8.231 12.2c-.39-.274-.246-.862.304-.862h3.237v2.466c0 1.861 1.36 3.05 3.414 3.05h.521V15.42h-.5Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgStakeStrd
