import * as adapters from '@/page-components/Stake/DexAdapters'
import { DexConfig, DexInfo, DexType } from '@/page-components/Stake/DexAdapters/types'
import { SelectedCoinDenom } from '@/wallet-utils'
import { IBCX_CURRENCY, STAKED_IBCX_CURRENCY } from './ibcx'

const DEX_INFO_LIST: Record<DexType, DexInfo> = {
  osmosis: {
    name: 'Osmosis',
    coinDenom: 'OSMO',
    url: 'https://app.osmosis.zone/pools',
    poolUrl: (poolId) => `https://app.osmosis.zone/pool/${poolId}`,
    adapter: adapters.osmosis
  },
  'astroport-luna': {
    name: 'Astroport',
    coinDenom: 'LUNA',
    url: 'https://app.astroport.fi/pools',
    poolUrl: (poolId) => `https://app.astroport.fi/pools/${poolId}/provide`,
    adapter: adapters.astroport
  },
  'astroport-inj': {
    name: 'Astroport',
    coinDenom: 'INJ',
    url: 'https://app.astroport.fi/pools',
    poolUrl: (poolId) => `https://app.astroport.fi/pools/${poolId}/provide`,
    adapter: adapters.astroport
  },
  forge: {
    name: 'Forge',
    coinDenom: 'EVMOS',
    url: 'https://app.forge.trade/#/pools',
    poolUrl: (poolId) => `https://app.forge.trade/#/add/${poolId}/500`,
    adapter: adapters.forge
  },
  crescent: {
    name: 'Crescent',
    coinDenom: 'CRE',
    url: 'https://app.crescent.network/',
    poolUrl: (poolId) => `https://app.crescent.network/orderbook?${poolId}`,
    adapter: adapters.crescent
  },
  dymension: {
    name: 'Dymension',
    coinDenom: 'DYM',
    url: 'https://portal.dymension.xyz/amm/pools',
    poolUrl: (poolId) => `https://portal.dymension.xyz/amm/pool/${poolId}`,
    adapter: adapters.dymension
  }
}

const DEX_CONFIG: Record<SelectedCoinDenom | 'STIBCX', DexConfig | null> = {
  STRD: null,
  ATOM: {
    type: 'osmosis',
    poolId: '803',
    fees: 0.03,
    ibcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    stakedIbcDenom: 'ibc/C140AFD542AE77BD7DCC83F13FDD8C5E5BB8C4929785E6EC2F4C636F98F17901'
  },
  SCRT: null,
  OSMO: {
    type: 'osmosis',
    poolId: '833',
    fees: 0.03,
    ibcDenom: 'uosmo',
    stakedIbcDenom: 'ibc/D176154B0C63D1F9C6DCFB4F70349EBF2E2B5A87A05902F57A6AE92B863E9AEC'
  },
  STARS: {
    type: 'osmosis',
    poolId: '810',
    fees: 0.03,
    ibcDenom: 'ibc/987C17B11ABC2B20019178ACE62929FE9840202CE79498E29FE8E5CB02B7C0A4',
    stakedIbcDenom: 'ibc/5DD1F95ED336014D00CE2520977EC71566D282F9749170ADC83A392E0EA7426A'
  },
  JUNO: {
    type: 'osmosis',
    poolId: '817',
    fees: 0.03,
    ibcDenom: 'ibc/46B44899322F3CD854D2D46DEEF881958467CDD4B3B10086DA49296BBED94BED',
    stakedIbcDenom: 'ibc/84502A75BCA4A5F68D464C00B3F610CE2585847D59B52E5FFB7C3C9D2DDCD3FE'
  },
  LUNA: {
    type: 'astroport-luna',
    poolId: 'terra1re0yj0j6e9v2szg7kp02ut6u8jjea586t6pnpq6628wl36fphtpqwt6l7p',
    fees: 0.05,
    ibcDenom: 'uluna',
    stakedIbcDenom: 'ibc/08095CEDEA29977C9DD0CE9A48329FDA622C183359D5F90CF04CC4FF80CBE431'
  },
  EVMOS: {
    type: 'forge',
    poolId: '0x2c68d1d6ab986ff4640b51e1f14c716a076e44c4/0xd4949664cd82660aae99bedc034a0dea8a0bd517',
    fees: 0.05,
    ibcDenom: '0xD4949664cD82660AaE99bEdc034a0deA8A0bd517',
    stakedIbcDenom: '0x2C68D1d6aB986Ff4640b51e1F14C716a076E44C4'
  },
  INJ: {
    type: 'astroport-inj',
    poolId: 'inj10fd06xl4q6jp9qlhemvm6ymmm83ppj2g8rzquw',
    fees: 0.05,
    ibcDenom: 'inj',
    stakedIbcDenom: 'ibc/AC87717EA002B0123B10A05063E69BCA274BA2C44D842AEEB41558D2856DCE93'
  },
  UMEE: {
    type: 'crescent',
    poolId: 'from=umee&to=stumee',
    fees: 0,
    // Umee currently does not support 'Add to DEX' and 'Pool Nudge' feature
    // At the same time, its adapter does not require ibcDenom and stakedIbcDenom
    // Umee currently only supports market rate
    ibcDenom: '',
    // Umee currently does not support 'Add to DEX' and 'Pool Nudge' feature
    // At the same time, its adapter does not require ibcDenom and stakedIbcDenom
    // Umee currently only supports market rate
    stakedIbcDenom: ''
  },
  // We currently only use Crescent as DEX for Umee.
  CRE: null,
  // Comdex is sunsetting cSwap and we're not replacing it with anything
  // https://x.com/ComdexOfficial/status/1805940026359914509
  CMDX: null,
  IBCX: {
    type: 'osmosis',
    poolId: '1034',
    fees: 0.01,
    ibcDenom: IBCX_CURRENCY.coinMinimalDenom,
    stakedIbcDenom: STAKED_IBCX_CURRENCY.coinMinimalDenom
  },
  // stIBCX has a different pool and pool rate
  STIBCX: {
    type: 'osmosis',
    poolId: '1039',
    fees: 0.03,
    ibcDenom: STAKED_IBCX_CURRENCY.coinMinimalDenom,
    stakedIbcDenom: STAKED_IBCX_CURRENCY.coinMinimalDenom
  },
  KUJI: null, // We currently only use KUJI as DEX
  SWTH: null, // We currently only use SWTH as DEX
  USK: null, // We currently only use USK as DEX
  IST: null, // We currently only use IST as DEX
  USC: null, // We currently only use USC as DEX
  BLD: null, // We currently only use BLD as DEX
  SOMM: {
    type: 'osmosis',
    poolId: '1120',
    fees: 0.03,
    ibcDenom: 'ibc/9BBA9A1C257E971E38C1422780CE6F0B0686F0A3085E2D61118D904BFE0F5F5E',
    stakedIbcDenom: 'ibc/5A0060579D24FBE5268BEA74C3281E7FE533D361C41A99307B4998FEC611E46B'
  },
  TIA: null, // Not a priority for stTIA launch
  DYDX: null, // No specified DEX,
  DYM: {
    type: 'dymension',
    poolId: '11',
    fees: 0.02,
    // Dymension adapter currently does not use this
    ibcDenom: '',
    // Dymension adapter currently does not use this
    stakedIbcDenom: ''
  },
  SAGA: {
    type: 'osmosis',
    poolId: '1674',
    fees: 0.03,
    ibcDenom: 'ibc/094FB70C3006906F67F5D674073D2DAFAFB41537E7033098F5C752F211E7B6C2',
    stakedIbcDenom: 'ibc/2CD9F8161C3FC332E78EF0C25F6E684D09379FB2F56EF9267E7EC139642EC57B'
  },
  ISLM: null, // No specified DEX yet
  BAND: null // No specified DEX yet
}

// A prettified shortcut for this common pattern (with extra checks):
// const dexInfo = DEX_INFO_LIST[DEX_CONFIG[selectedCoinDenom].type]
//
// Gets the DexInfo from the coin denom. At time of writing, intended to be used
// like so: getDexInfoFromDenom(selectedCoinDenom) to access dex's name or chain info like so:
// CHAIN_INFO_LIST[getDexInfoFromDenom(selectedCoinDenom).coinDenom] - (dexAccount already does this)
// getDexInfoFromDenom(selectedCoinDenom).name
//
// @TODO: Not sure where we should place this.
// A utils file like `dex-config-utils` in the same directory feels too much.
// A utils file under `@/utils` doesn't make sense; this should be colocated here.
// @/wallet-utils also feels a bit weird. It's not exactly wallet related? But maybe.
// Let's keep it here for now, we'll figure it out once we have more files related.
const getDexInfoFromDenom = (coinDenom: SelectedCoinDenom): DexInfo | null => {
  const config = DEX_CONFIG[coinDenom]
  return config && DEX_INFO_LIST[config.type]
}

// The average value being staked is pretty high (at least hundreds) so it makes sense to have a higher minimum threshold.
// To avoid slippage because one or two dexes don't support them, we've set the max threshold to less than 10k USD.
const DEX_NUDGE_THRESHOLD = { min: 5, max: 10_000 }

// Minimum APY for a pool to display its percentage on the last step of liquid staking.
const DEX_POOL_MINIMUM_APY = 0.05

export { DEX_CONFIG, DEX_INFO_LIST, DEX_NUDGE_THRESHOLD, DEX_POOL_MINIMUM_APY, getDexInfoFromDenom }
