import * as React from 'react'
import { SVGProps } from 'react'
const SvgBarStats = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.663 11.125c0-.621.519-1.125 1.158-1.125h2.315c.639 0 1.157.504 1.157 1.125v6.75c0 .621-.518 1.125-1.157 1.125H2.82c-.307 0-.601-.119-.819-.33a1.11 1.11 0 0 1-.339-.795v-6.75Zm6.945-4.5c0-.621.519-1.125 1.158-1.125h2.315c.638 0 1.157.504 1.157 1.125v11.25c0 .621-.519 1.125-1.157 1.125H9.766c-.307 0-.602-.119-.819-.33a1.11 1.11 0 0 1-.339-.795V6.625Zm6.945-4.5c0-.621.518-1.125 1.157-1.125h2.315c.64 0 1.158.504 1.158 1.125v15.75c0 .621-.519 1.125-1.158 1.125H16.71c-.307 0-.601-.119-.818-.33a1.11 1.11 0 0 1-.34-.795V2.125Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgBarStats
