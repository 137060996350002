import { useState } from 'react'
import { useInterval } from './useInterval'

const useSimpleCountdown = (initial: number) => {
  const [countdown, setCountdown] = useState(initial)

  useInterval(() => {
    setCountdown((countdown) => Math.max(countdown - 1, 0))
  }, 1000)

  return { countdown }
}

export { useSimpleCountdown }
