import axios from 'axios'
import BigNumber from 'bignumber.js'
import { CHAIN_INFO_LIST, DEX_CONFIG } from '@/config'
import { fatal } from '@/utils'
import { DexAdapter } from './types'

interface AstroportTokenData {
  chainId: string
  priceUsd: number | null
  symbol: string
  name: string
  tokenAddr: string
}

interface AstroportTokenPricesResponse {
  data: { tokens: AstroportTokenData[] }
}

const query = `
  query TokenQuery($chains: [String]!) {
    tokens(chains: $chains) {
      priceUsd
      tokenAddr
    }
  }
`

const astroport: DexAdapter = async ({ selectedCoinDenom }) => {
  const dexConfig = DEX_CONFIG[selectedCoinDenom]

  if (dexConfig == null) {
    throw fatal(`Missing dex config for ${selectedCoinDenom}`)
  }

  const variables = {
    chains: [CHAIN_INFO_LIST[selectedCoinDenom].chainId]
  }

  const response = await axios.post<AstroportTokenPricesResponse>('https://multichain-api.astroport.fi/graphql', {
    query,
    variables
  })

  const tokenData = response.data.data.tokens.find((token) => {
    return dexConfig.ibcDenom === token.tokenAddr
  })

  // We'll do an intentional, direct null-check for priceUsd, two birds in one stone.
  if (tokenData?.priceUsd == null) {
    throw fatal(`Missing token data for ${selectedCoinDenom}`)
  }

  const stTokenData = response.data.data.tokens.find((token) => {
    return dexConfig.stakedIbcDenom === token.tokenAddr
  })

  // We'll do an intentional, direct null-check for priceUsd, two birds in one stone.
  if (stTokenData?.priceUsd == null) {
    throw fatal(`Missing token data for st${selectedCoinDenom}`)
  }

  const stTokenValue = new BigNumber(stTokenData.priceUsd).dividedBy(tokenData.priceUsd).toNumber()

  return {
    tokenValue: new BigNumber(1).dividedBy(stTokenValue).toNumber(),
    stTokenValue: stTokenValue,
    stTokenValueInUsd: stTokenData.priceUsd
  }
}

export { astroport }
