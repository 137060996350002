import axios from 'axios'
import BigNumber from 'bignumber.js'
import { DEX_CONFIG } from '@/config'
import { fatal } from '@/utils'
import { DexAdapter } from './types'

interface UniswapQuoteResponse {
  quoteDecimals: number
}

const forge: DexAdapter = async ({ selectedCoinDenom, priceInUsd }) => {
  const dexConfig = DEX_CONFIG[selectedCoinDenom]

  if (dexConfig == null) {
    throw fatal(`Missing dex config for ${selectedCoinDenom}`)
  }

  const params = {
    protocols: 'v2,v3,mixed',
    tokenInChainId: 9001,
    tokenOutChainId: 9001,
    tokenInAddress: dexConfig.stakedIbcDenom,
    tokenOutAddress: dexConfig.ibcDenom,
    amount: 1e18,
    type: 'exactIn'
  }

  const uniswapQuoteResponse = await axios.get<UniswapQuoteResponse>(
    // @TODO: We should consider rolling out our own quote service
    // https://github.com/Forge-Trade/routing-api
    `https://21x5w4o8d6.execute-api.us-east-1.amazonaws.com/prod/quote`,
    { params }
  )

  const stTokenValue = Number(uniswapQuoteResponse.data.quoteDecimals)

  return {
    tokenValue: new BigNumber(1).dividedBy(stTokenValue).toNumber(),
    stTokenValue,
    stTokenValueInUsd: new BigNumber(stTokenValue).multipliedBy(priceInUsd).toNumber()
  }
}

export { forge }
