import React from 'react'
import Link from 'next/link'
import { Anchor, Box, Container, Group, Text } from '@mantine/core'
import { useTopBanner } from '@/contexts/top-banner'
import { useMediumScreen } from '@/hooks'
import { Icon } from '@/components'

const TopBanner = () => {
  const { banner } = useTopBanner()

  const { isMediumScreen } = useMediumScreen()

  if (banner == null) {
    return null
  }

  return <>
    <Box sx={(t) => ({ position: 'sticky', top: 0, left: 0, right: 0, zIndex: t.other.zIndex.topBanner })}>
      <Box sx={(t) => ({ background: t.colors.pink[6] })}>
        <Container size="sm" px={isMediumScreen ? 'lg' : 'sm'} py="sm">
          <Group spacing="sm" position="apart">
            <Group spacing="sm">
              <Icon name={banner.icon} sx={(t) => ({ color: t.white })} />

              <Text color="white" inline>
                {banner.text}
              </Text>
            </Group>

            <Link href={banner.url} passHref legacyBehavior>
              <Anchor>
                <Text color="white" inline>
                  See details &rarr;
                </Text>
              </Anchor>
            </Link>
          </Group>
        </Container>
      </Box>
    </Box>
  </>;
}

export { TopBanner }
