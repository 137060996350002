import * as React from 'react'
import { SVGProps } from 'react'
const SvgStake = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x={12.95}
      y={1}
      width={7}
      height={7}
      rx={1}
      transform="rotate(45 12.95 1)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={5.95}
      y={8}
      width={7}
      height={7}
      rx={1}
      transform="rotate(45 5.95 8)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={19.95}
      y={8}
      width={7}
      height={7}
      rx={1}
      transform="rotate(45 19.95 8)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={12.95}
      y={15}
      width={7}
      height={7}
      rx={1}
      transform="rotate(45 12.95 15)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgStake
