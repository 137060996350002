import React from 'react'
import { Tooltip } from '@mantine/core'
import { Icon } from '@/components'

interface ErrorBeaconProps {
  label: string
  size?: 'sm' | 'md'
  width?: number
}

const ErrorBeacon: React.FC<ErrorBeaconProps> = ({ label, size = 'md', width }) => {
  return (
    <Tooltip label={label} width={width} wrapLines={Boolean(width)} withArrow withinPortal>
      <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} size={size === 'sm' ? 12 : 16} />
    </Tooltip>
  )
}

export { ErrorBeacon }
