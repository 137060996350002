import * as React from 'react'
import { SVGProps } from 'react'
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.395 1h1.683c2.61 0 4.723 1.943 4.723 4.35 0 2.407-2.113 4.35-4.723 4.35h-1.683M7.406 9.689H5.723C3.113 9.689 1 7.745 1 5.339 1 2.932 3.102 1 5.712 1h1.683M5.293 5.35h10.203"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgLink
