import * as React from 'react'
import { SVGProps } from 'react'
const SvgPkg = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 16.757a3.026 3.026 0 0 0 1.653 2.698l8 4a3 3 0 0 0 2.684 0l8-4A3 3 0 0 0 23 16.77V7.23a3.026 3.026 0 0 0-1.665-2.686L13.337.546a3 3 0 0 0-2.684 0l-8 4A3.026 3.026 0 0 0 1 7.243v9.514Zm20 .013a1 1 0 0 1-.555.895h-.002L13 21.388v-9.77l8-4v9.153-.001ZM19.77 5.998l-7.327-3.664a1 1 0 0 0-.896 0l-2.321 1.16L17 7.383l2.77-1.384ZM6.99 4.613 4.226 5.995 12 9.882 14.764 8.5 6.99 4.613ZM3 7.618l8 4v9.774l-7.45-3.725h-.001a1 1 0 0 1-.549-.9v-9.15Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPkg
