import { useState, useEffect } from 'react'

// Returns the previous value of a state; updates it on next render
const usePrevious = <T>(value: T): T | undefined => {
  const [previous, setPrevious] = useState<T>()

  useEffect(() => {
    setPrevious(value)
  }, [value])

  return previous
}

export { usePrevious }
