interface State<T> {
  isPastThrottle: boolean
  value: T | null
}

// This is a custom throttle function that will guarantee that the promise
// is resolved only after the specified time. Especially helpful for promises that
// resolve too fast. Use-case is to make sure a query runs at least X seconds
// so that users have time to read something from the loading state.
function throttle<T>(callback: () => Promise<T>, ms: number) {
  let state: State<T> = {
    isPastThrottle: false,
    value: null
  }

  return new Promise<T>((resolve, reject) => {
    callback()
      .then((value) => {
        state.value = value

        if (state.isPastThrottle) {
          resolve(state.value)
        }
      })
      .catch(reject)

    setTimeout(() => {
      state.isPastThrottle = true

      if (state.value) {
        resolve(state.value)
      }
    }, ms)
  })
}

export { throttle }
