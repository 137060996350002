// @source https://github.com/streamich/react-use/blob/e53ca94a0b1f20270b0f75dc2ca1fecf1e119dde/src/useInterval.ts
import { useEffect } from 'react'
import { useLatestValue } from './useLatestValue'

const useInterval = (callback: Function, delay?: number | null) => {
  const ref = useLatestValue<Function>(callback)

  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => ref.current?.(), delay || 0)
      return () => clearInterval(interval)
    }

    return undefined
  }, [delay])
}

export { useInterval }
