import axios from 'axios'
import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { Coin } from '@cosmjs/amino'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { HostZoneResponse } from '@/queries'
import { assert } from '@/utils'
import { redis } from '@/redis'
import { getDenomsFromPoolId } from './utils'

interface UmeeBankBalancesResponse {
  balances: Coin[]
}

interface UmeeBalancesResponse {
  supplied: Record<string, number>
  borrowed: Record<string, number>
  collateral: Record<string, number>
}

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: Consider fetching this through Umee API or contracts if possible
// use 'https://umee-mainnet-lcd.autostake.com/ibc/apps/transfer/v1/denom_traces?pagination.limit=500'
const POOL_MAP: Record<string, string> = {
  stATOM: 'ibc/C8B3026C2844D204F3A49F91058DC947F01F2FC36AFF17850FFC8701504BDDEE',
  stOSMO: 'ibc/6B49A789937D4E50BF01F0F50DDEDF5C1103EDF01306B7021BDF23BDE65D99BA',
  ATOM: 'ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9',
  OSMO: 'ibc/5A7559B709E83C305CC2975ACA362CF67A170097A28C73B8557E4B3189FAEC56'
}

const adapter = async ({
  pools,
  address
}: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
  const bankBalancesResponse = await axios.get<UmeeBankBalancesResponse>(
    `https://api-umee-ia.cosmosia.notional.ventures/cosmos/bank/v1beta1/balances/${address}`
  )
  const balancesResponse = await axios.get<UmeeBalancesResponse>(
    `https://testnet-client-bff-ocstrhuppq-uc.a.run.app/user/balances/${address}`
  )

  return await Promise.all(
    pools.map(async (pool) => {
      const { stakedDenom, denom } = getDenomsFromPoolId(pool.poolId)
      const ibcDenom = POOL_MAP[denom]
      const ibcStakedDenom = POOL_MAP[stakedDenom]

      const instance = axios.create({
        baseURL: STRIDE_CHAIN_INFO.rest
      })

      const chainInfo = CHAIN_INFO_LIST[denom]

      const hostZoneResponse = await instance.get<HostZoneResponse>(
        `Stride-Labs/stride/stakeibc/host_zone/${chainInfo.chainId}`
      )
      // redemption rate is how many OSMO do I get for 1stOSMO
      const redemptionRate = hostZoneResponse.data.host_zone.redemption_rate

      const tokenValueInUsd = await redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${denom}`)

      assert(tokenValueInUsd, `Missing token value in usd for ${denom}`)

      const bankBalance = bankBalancesResponse.data.balances.find((balance) => balance.denom === ibcDenom)?.amount || 0
      const bankBalanceStaked =
        bankBalancesResponse.data.balances.find((balance) => balance.denom === ibcStakedDenom)?.amount || 0
      const lockedBalance = Number(balancesResponse.data.supplied[ibcDenom]) || 0
      const lockedBalanceStaked = Number(balancesResponse.data.supplied[ibcStakedDenom]) || 0

      return {
        poolId: pool.poolId,
        unbondedAmount: new BigNumber(bankBalance)
          .dividedBy(1e6) // balances are in micro denoms, TODO get from chain config
          .times(tokenValueInUsd)
          .plus(new BigNumber(bankBalanceStaked).dividedBy(1e6).times(redemptionRate).times(tokenValueInUsd))
          .toNumber(),
        bondedAmount: new BigNumber(lockedBalance)
          .dividedBy(1e6) // balances are in micro denoms, TODO get from chain config
          .times(tokenValueInUsd)
          .plus(new BigNumber(lockedBalanceStaked).dividedBy(1e6).times(redemptionRate).times(tokenValueInUsd))
          .toNumber()
      }
    })
  )
}

export { adapter }
