import * as React from 'react'
import { SVGProps } from 'react'
const SvgAirdrop = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 11.28C22 5.604 17.396 1 11.72 1 6.043 1 1.438 5.604 1.438 11.28"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59 17.178 1.44 11.305a8.533 8.533 0 0 1 3.397-.733 8.55 8.55 0 0 1 3.455.695 8.533 8.533 0 0 1 3.397-.733 8.55 8.55 0 0 1 3.455.695 8.534 8.534 0 0 1 3.396-.733 8.548 8.548 0 0 1 3.456.696l-6.933 5.784"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.72 1C7.178 5.364 8.293 11.27 8.293 11.27l1.878 4.655M11.722 1c4.542 4.364 3.464 10.008 3.464 10.008l-1.884 4.752"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.72 23.413a3.903 3.903 0 1 0 0-7.806 3.903 3.903 0 0 0 0 7.806Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgAirdrop
