import * as React from 'react'
import { SVGProps } from 'react'
const SvgEarn = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 9.375C1 11.653 4.694 13.5 9.25 13.5c2.83 0 5.329-.713 6.815-1.8M1 9.376C1 7.097 4.694 5.25 9.25 5.25c.338 0 .672.01 1 .03M1 9.375v11.25c0 2.278 3.694 4.125 8.25 4.125s8.25-1.847 8.25-4.125V13v.125M1 9.375v3.75m16.5 0v3.75C17.5 19.153 13.806 21 9.25 21S1 19.153 1 16.875v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125S1 15.403 1 13.125M17.25 1v8M21.25 5h-8"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgEarn
