import Rollbar from 'rollbar'
import { config } from '@/config/app-config'

const rollbarConfig: Rollbar.Configuration = {
  accessToken: config.rollbar.token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    },
    environment: config.rollbar.env
  }
}

const rollbar = new Rollbar(rollbarConfig)

export { rollbar, rollbarConfig }
