import BigNumber from 'bignumber.js'

import { DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { fatal, log } from '@/utils'
import { CosmWasmClient } from 'cosmwasm'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { SelectedCoinDenom } from '@/wallet-utils'
import { getDenomsFromPoolId } from './utils'

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: Consider fetching this through Astroport API or contracts if possible
const POOL_MAP = {
  'stLUNA/LUNA': {
    protocol: 'LUNA',
    // Astro Pair, aka pool contract
    pool_contract: 'terra1re0yj0j6e9v2szg7kp02ut6u8jjea586t6pnpq6628wl36fphtpqwt6l7p',
    // LP Token
    token_contract: 'terra14n22zd24nath0tf8fwn468nz7753rjuks67ppddrcqwq37x2xsxsddqxqc',
    // Stake LP Token
    stake_contract: 'terra1ksvlfex49desf4c452j6dewdjs6c48nafemetuwjyj6yexd7x3wqvwa7j9',
    denom: 'uluna',
    // denom of the staking token as ibc denom
    stakedDenom: 'ibc/08095CEDEA29977C9DD0CE9A48329FDA622C183359D5F90CF04CC4FF80CBE431'
  },
  'stINJ/INJ': {
    protocol: 'INJ',
    // Astro Pair, aka pool contract
    pool_contract: 'inj10fd06xl4q6jp9qlhemvm6ymmm83ppj2g8rzquw',
    // LP Token
    token_contract: 'inj1hxq5q8h7d8up6j4jcmxje42zpkzr409j6ay4wu',
    // Stake LP Token
    stake_contract: 'inj1z354nkau8f0dukgwctq9mladvdwu6zcj8k4928',
    denom: 'inj',
    // denom of the staking token as ibc denom
    stakedDenom: 'ibc/AC87717EA002B0123B10A05063E69BCA274BA2C44D842AEEB41558D2856DCE93'
  }
}

const adapter =
  () =>
  async ({
    pools,
    accounts
  }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const poolShares = Object.fromEntries(
      await Promise.all(
        Object.entries(POOL_MAP)
          .filter(([poolKey]) => pools.find((pool) => `${pool.stakedDenom}/${pool.denom}` === poolKey))
          .map(async ([poolKey, poolData]) => {
            // @TODO: https://github.com/Stride-Labs/interface/issues/537
            // @ts-ignore
            const protocol: Partial<SelectedCoinDenom> = poolData.protocol
            const protocolChain = CHAIN_INFO_LIST[protocol]

            // current system passes in one address at a time but we need to get the address for two protocols
            // TODO move addresses in general to the adapters
            const protocolAddress = accounts.find((account) => account.denom === protocol)?.address

            if (!protocolAddress) {
              log(`Trying to get pool tokens for Astroport, but unable to find the address for ${protocol}`)
              return []
            }

            const { stakedDenom, denom } = getDenomsFromPoolId(poolKey)

            const [client, stakedDenomUsdValue, denomUsdValue] = await Promise.all([
              CosmWasmClient.connect(protocolChain.rpc),
              redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + stakedDenom),
              redis.get<number>('sheet_COINGECKO_PRICE_DOLLAR_' + denom)
            ])

            if (!stakedDenomUsdValue || !denomUsdValue) {
              throw fatal(`Missing price for ${stakedDenom} or ${denom}`)
            }

            const [unstakedLpShares, stakedLpShares, lpInfo] = await Promise.all([
              client
                .queryContractSmart(poolData.token_contract, { balance: { address: protocolAddress } })
                .then((res) => res.balance),
              client
                .queryContractSmart(poolData.stake_contract, {
                  deposit: { user: protocolAddress, lp_token: poolData.token_contract }
                })
                .then((res) => res),
              client.queryContractSmart(poolData.pool_contract, { cumulative_prices: {} }).then((res) => res)
            ])

            const amountUnstakedTokensInPool = lpInfo.assets.find(
              ({
                info: {
                  // @ts-ignore
                  native_token: { denom: _denom }
                }
              }) => _denom === poolData.denom
            ).amount
            const amountStakedTokensInPool = lpInfo.assets.find(
              ({
                info: {
                  // @ts-ignore
                  native_token: { denom: _denom }
                }
              }) => _denom === poolData.stakedDenom
            ).amount

            const priceInUsdUnstakedToken = denomUsdValue
            const priceInUsdStakedToken = stakedDenomUsdValue

            const poolValue = new BigNumber(amountStakedTokensInPool)
              .dividedBy(10 ** protocolChain.stakeCurrency.coinDecimals) // micro denom to denom
              .times(priceInUsdStakedToken)
              .plus(
                new BigNumber(amountUnstakedTokensInPool)
                  .dividedBy(10 ** protocolChain.stakeCurrency.coinDecimals) // micro denom to denom
                  .times(priceInUsdUnstakedToken)
              )

            return [
              poolKey,
              {
                bondedAmount: new BigNumber(stakedLpShares).dividedBy(lpInfo.total_share).times(poolValue),
                unbondedAmount: new BigNumber(unstakedLpShares).dividedBy(lpInfo.total_share).times(poolValue)
              }
            ]
          })
      )
    )

    return pools.map((pool) => {
      const { bondedAmount, unbondedAmount } = poolShares[pool.stakedDenom + '/' + pool.denom]

      return {
        poolId: pool.poolId,
        unbondedAmount: unbondedAmount.toNumber(),
        bondedAmount: bondedAmount.toNumber()
      }
    })
  }

export { adapter }
