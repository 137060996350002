import * as React from 'react'
import { SVGProps } from 'react'
const SvgFees = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m2 9 8-8" stroke="currentColor" strokeWidth={1.333} strokeLinecap="round" strokeLinejoin="round" />
    <circle
      cx={2.5}
      cy={2.5}
      r={1.5}
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={9.5}
      cy={7.5}
      r={1.5}
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgFees
