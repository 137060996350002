import React from 'react'
import { Badge, Box, Container, Group, Stack } from '@mantine/core'

import { env } from '@/utils'
import { useMediumScreen } from '@/hooks'
import { GlobalAnnouncement } from '../GlobalAnnouncement'
import { TopBanner } from './TopBanner'
import { NavigationMobileMenu, NavigationMobileMenuProvider } from './NavigationMobileMenu'
import { NavigationWalletDropdown } from './NavigationWalletDropdown'
import { SidebarLite } from './SidebarLite'

interface GlobalLayoutProps {
  children: React.ReactNode
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children }) => {
  const { isMediumScreen } = useMediumScreen()
  return (
    <>
      <Group spacing={0} align="flex-start">
        <SidebarLite />

        <Stack sx={{ flex: 1 }}>
          <GlobalAnnouncement />

          <Box sx={{ width: '100%' }}>
            <TopBanner />

            <Container px={16} fluid>
              <Group align="center" position="apart" py={16} spacing={0}>
                <Box>
                  {['development', 'staging'].includes(env('STRIDE_ENV')) && (
                    <Box sx={{ position: 'fixed', top: 16, left: isMediumScreen ? 230 : 16, zIndex: 500 }}>
                      <Badge color={env('STRIDE_ENV') === 'development' ? 'gray' : 'yellow'} variant="filled" size="xs">
                        {env('STRIDE_ENV')}
                      </Badge>
                    </Box>
                  )}
                </Box>

                <Group spacing="sm">
                  <NavigationWalletDropdown />

                  <NavigationMobileMenuProvider>
                    <NavigationMobileMenu />
                  </NavigationMobileMenuProvider>
                </Group>
              </Group>
            </Container>

            <Box pb="xl">{children}</Box>
          </Box>
        </Stack>
      </Group>
    </>
  )
}

export { GlobalLayout }
