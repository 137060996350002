import React from 'react'
import { Button, UnstyledButton, Text, Popover } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { Icon } from '@/components/Icon'
import { ellipsis } from '@/utils'
import { useGlobalLayout } from './GlobalLayoutProvider'
import { useStrideWallet, useWallet } from '@/contexts/wallet'

const NavigationWalletDropdown: React.FC = () => {
  const strideAccount = useStrideWallet()

  const { connect, disconnect, isFetchingStrideAccount } = useWallet()

  const { isConnectWalletDropdownOpen, toggleConnectWalletDropdown, closeConnectWalletDropdown } = useGlobalLayout()

  const clipboard = useClipboard()

  const handleCopyWalletAddress = () => {
    clipboard.copy(strideAccount?.address)
    closeConnectWalletDropdown()
  }

  const handleDisconnectWallet = () => {
    disconnect()
    closeConnectWalletDropdown()
  }

  if (!strideAccount) {
    return (
      <Button
        onClick={() => connect()}
        leftIcon={<Icon name="wallet" />}
        variant="outline"
        color="dark"
        loading={isFetchingStrideAccount}>
        Connect wallet
      </Button>
    )
  }

  return (
    <Popover
      target={
        <Button onClick={toggleConnectWalletDropdown} leftIcon={<Icon name="wallet" />} variant="outline" color="dark">
          {strideAccount.nsName || ellipsis(strideAccount.address || '', 20)}
        </Button>
      }
      opened={isConnectWalletDropdownOpen}
      onClose={closeConnectWalletDropdown}
      width={200}
      position="bottom"
      placement="end"
      spacing="sm">
      <Text pb="xs" inline size="xs" weight={600} sx={(t) => ({ color: t.colors.gray[6] })}>
        {strideAccount?.nsName || ellipsis(strideAccount?.address || '', 20)}
      </Text>
      <UnstyledButton py="xs" onClick={handleCopyWalletAddress} style={{ width: '100%' }}>
        <Text inline>Copy wallet address</Text>
      </UnstyledButton>
      <UnstyledButton py="xs" onClick={handleDisconnectWallet} style={{ width: '100%' }}>
        <Text inline>Disconnect wallet</Text>
      </UnstyledButton>
    </Popover>
  )
}

export { NavigationWalletDropdown }
