// Generic react imports
import React from 'react'
import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import { Box, createStyles } from '@mantine/core'

interface ScrollAreaProps {
  // @reference https://github.com/radix-ui/primitives/issues/2307
  // TL;DR: To enable maxHeight for ScrollArea, we need to apply it to the direct child of RadixScrollArea.Viewport
  height: number
}

const ScrollArea = ({ height, children }: React.PropsWithChildren<ScrollAreaProps>) => {
  const { classes } = useStyles()

  return (
    <RadixScrollArea.Root className={classes.root}>
      <RadixScrollArea.Viewport className={classes.viewport}>
        <Box sx={{ maxHeight: height }}>{children}</Box>
      </RadixScrollArea.Viewport>

      <RadixScrollArea.Scrollbar orientation="vertical" className={classes.scrollbar}>
        <RadixScrollArea.Thumb className={classes.thumb} />
      </RadixScrollArea.Scrollbar>
    </RadixScrollArea.Root>
  )
}

// @source https://www.radix-ui.com/primitives/docs/components/scroll-area
const useStyles = createStyles((t) => ({
  root: {
    width: '100%',
    borderRadius: t.radius.sm,
    overflow: 'hidden',
    backgroundColor: 'white'
  },

  viewport: {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit'
  },

  scrollbar: {
    display: 'flex',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    padding: 2,
    background: '#eee',
    transition: 'background 160ms ease-out',
    '&:hover': { background: '#ddd' },
    '&[data-orientation="vertical"]': { width: 10 }
  },

  thumb: {
    flex: 1,
    background: '#bbb',
    borderRadius: 10,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      minWidth: 44,
      minHeight: 44
    }
  }
}))

export { ScrollArea }
