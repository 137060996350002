import { format } from 'date-fns'
import { GasPrice, SigningStargateClient, Coin, coins } from '@cosmjs/stargate'
import { Uint53 } from '@cosmjs/math'
import { Account } from '../types'
import { EncodeObject } from '@cosmjs/proto-signing'
import { assertSimulationSuccess } from './errors'
import { assert } from '@/utils'
import { CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION, READABLE_DENOMS, STRIDE_CHAIN_INFO } from '@/config'
import BigNumber from 'bignumber.js'
import { isSelectedCoinMinimalDenom } from '../type-guards'

export const formatTxTimestamp = (timestamp: string | Date): string => {
  return format(new Date(timestamp), 'MMMM d, yyyy h:mm O')
}

// This is a superset of Stagate's calculateFee function; adds just the right amount of multiplier.
export const calculateFee = (account: Account, gas: number) => {
  // Starting with Cosmos SDK 0.47, we see many cases in which 1.3 is not enough anymore
  // E.g. https://github.com/cosmos/cosmos-sdk/issues/16020
  // As of 03/21/24, Evmos fails due to "out of gas" errors - the fix is set the gas limit higher.
  // @TODO: Once we have more of these, consider adding another config to `chain-config.ts` named `gasLimitMultiplier`
  const gasLimit = Math.round(account.currency.coinDenom === 'EVMOS' ? gas * 2 : gas * 1.5)
  const { denom, amount: gasPriceAmount } = GasPrice.fromString(`0.1${account.currency.coinMinimalDenom}`)
  const amount = gasPriceAmount.multiply(new Uint53(gasLimit)).ceil().toString()

  return {
    amount: coins(amount, denom),
    gas: String(gasLimit)
  }
}

// `calculateFee` above but directly accepts a minimal denom - allows for full flexibility, necessary for the "Add to DEX" flow.
// It's a lot of work to do all at once, but let's slowly replace instances of `calculateFee` with this.
export const calculateFeeWithMinimalDenom = (minimalDenom: string, gas: number) => {
  // Starting with Cosmos SDK 0.47, we see many cases in which 1.3 is not enough anymore
  // E.g. https://github.com/cosmos/cosmos-sdk/issues/16020
  // As of 03/21/24, Evmos fails due to "out of gas" errors - the fix is set the gas limit higher.
  // @TODO: Once we have more of these, consider adding another config to `chain-config.ts` named `gasLimitMultiplier`
  const gasLimit = Math.round(minimalDenom === 'aevmos' ? gas * 2 : gas * 1.5)
  const { denom, amount: gasPriceAmount } = GasPrice.fromString(`0.1${minimalDenom}`)
  const amount = gasPriceAmount.multiply(new Uint53(gasLimit)).ceil().toString()

  return {
    amount: coins(amount, denom),
    gas: String(gasLimit)
  }
}

// This is the same as `calculateFee`, but provides convenience access to the deductible amount.
// This is intended so we can deduct the gas fee from the transaction amount, allowing users without
// other tokens (which is most users) for gas to still make transactions.
//
// For most cases, we should be passing the minimal denom of the token being used in the transaction here.
//
// This is a part of the "gas auto-allocation" feature. This works by auto-allocating tokens for gas fee (via tx.simulate) from the
// transaction amount so you don’t end up failing the transaction. Only if the user does not have STRD. To visualize: Given 0.15 TIA,
//
// @TODO: Consider making a complete abstraction of this, along with `deductFeeFromTxAmountWhileEmptyStrd` and `simulate`
// So we don't have to duplicate so many functions for every flow we want this to be supported on. We've spent more than enough time
// on this for the time being, so I apologize that it sucks. But we can make it better in the future.
export const calculateFeeWithHelper = (minimalDenom: string, gas: number) => {
  const fee = calculateFeeWithMinimalDenom(minimalDenom, gas)

  assert(isSelectedCoinMinimalDenom(minimalDenom), `Unknown minimal denom ${minimalDenom}`)

  const denom = READABLE_DENOMS[minimalDenom]

  // For chains that don't support auto allocation, `0` as deductible will basically render
  // deductFeeFromTxAmountWhileEmptyStrd` useless, ergo the entire mechanic becomes useless.
  if (!CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION[denom]) {
    return { fee, deductible: BigInt(0) }
  }

  // calculateFee(...).amount from Stargate is of type Coin[] which is annoying (but probably necessary)
  // so we have manually access the first element here and do some type-assertions manually.
  const amount = fee.amount.at(0)

  assert(amount, 'Fee amount is not defined')

  // Make sure we increase the value deducted to the transaction amount ever so slightly to account for any fluctuations in the gas fee
  // Amount here is likely less than 1m tokens, so we can safely use BigNumber here. And we need BigNumber to mostly remove the decimal
  // places for us.
  const deductible = BigInt(new BigNumber(amount.amount).multipliedBy(1.1).decimalPlaces(0).toString())

  return { fee, deductible }
}

// This is intended to be used with `calculateFeeWithHelper` to deduct the gas fee from the transaction amount.
// When the transaction amount is very small, it may result to negative values. In this case, we'll try to deduct
// nothing and let the transaction fail with a code 5 (INSUFFICIENT_FUNDS) error.
//
// @TODO: When this happens, we should properly display an error message to the user. This is likely an edge case
// because this will occur for users that are trying to transact with very small amounts of tokens (< 0.01).
// Alternatively, we should also consider adding a higher minimum value to transact with.
//
// @TODO: Consider merging this with `calculateFeeWithHelper` entirely.
//
// @TODO: Whenever the user's ending balance is less than the gas estimate, we'll have to deduct the gas estimate from the total value.
//
// @TODO: Rename to `allocateFeeFromTxAmount` (gas-fee auto allocation) since it makes it simpler to explain.
// To elaborate: Auto-allocate tokens for gas fee from the transaction amount.
//
// This is a part of the "gas auto-allocation" feature. This works by auto-allocating tokens for gas fee (via tx.simulate) from the
// transaction amount so you don’t end up failing the transaction. Only if the user does not have STRD. To visualize: Given 0.15 TIA,
// we only send 0.138 TIA and use the remaining for gas.
// @TODO: Consider making a complete abstraction of this, along with `calculateFeeWithHelper` and `simulate`
// So we don't have to duplicate so many functions for every flow we want this to be supported on.
export const deductFeeFromTxAmount = (amountInMicroDenoms: bigint, deductible: bigint) => {
  const amountWithSafetyDeduction = amountInMicroDenoms - deductible
  return amountWithSafetyDeduction > 0 ? amountWithSafetyDeduction : amountInMicroDenoms
}

// We can probably assume that all transactions will cost <= 0.01
// @TODO: Move to `@/config/chain-config.ts`
const STRD_GAS_ESTIMATE = BigInt(10_000)

// Same as deductFeeFromTxAmount but takes into account the STRD balance on Stride.
// If there's any hint of STRD, we'll assume it's being used for gas, and deduct nothing.
// Make sure to only use this for transactions being done on Stride
//
// @TESTING Test in two scenarios of the same flow: One, run a transaction with STRD. Second, run a transaction without STRD.
//
// This is a part of the "gas auto-allocation" feature. This works by auto-allocating tokens for gas fee (via tx.simulate) from the
// transaction amount so you don’t end up failing the transaction. Only if the user does not have STRD. To visualize: Given 0.15 TIA,
// we only send 0.138 TIA and use the remaining for gas.
// @TODO: Consider making a complete abstraction of this, along with `calculateFeeWithHelper` and `simulate`
// So we don't have to duplicate so many functions for every flow we want this to be supported on.
//
// Confidence: We’re not doing anything too clever so it does not cause weird unintended behavior. It just deducts gas where
// it thinks it’s necessary. On cases where users have alternative tokens, the surplus should be trivially small given Cosmos
// gas is pretty cheap! On cases where users simply don’t have enough, then that’s outside our scope. Overall, this guarantees
// that users are going to be able to do any transaction even if they don’t have any STRD.
//
// @TODO: Only when the user's ending balance is less than the gas estimate, we'll have to deduct the gas estimate from the total value.
//
// Taken from ClassicLiquidStake utils:
// @TODO: I accidentally increased the deduction amount and we ended up crashing Liquid Staking because we
// were basically Liquid Staking a negative value. We might want to keep value to 0 if it ends up being negative.
// This is a safety rail for the safety rail. But we need to explore this further. My hunch is we probably should
// update the minimum value. But we might want to check how Keplr handles this first.
export const deductFeeFromTxAmountWhileEmptyStrd = (
  balances: Coin[],
  amountInMicroDenoms: bigint,
  deductible: bigint
) => {
  // STRD on Stride
  const strdBalance = balances.find((coin) => {
    return coin.denom === STRIDE_CHAIN_INFO.stakeCurrency.coinMinimalDenom
  })

  // If user has enough STRD for this transaction, then we can assume it is being used and we don't need to deduct anything.
  if (strdBalance && BigInt(strdBalance.amount) > STRD_GAS_ESTIMATE) {
    return amountInMicroDenoms
  }

  return deductFeeFromTxAmount(amountInMicroDenoms, deductible)
}

// Wrap simulate function so our standard errors pass through.
//
// Simulate may fail in certain cases, like due to rate limiting
// @TODO: Make sure we use this everywhere. We're not doing this yet
// due to time constraints
export const simulate = async (
  client: SigningStargateClient,
  signerAddress: string,
  messages: readonly EncodeObject[],
  memo: string | undefined
): Promise<number> => {
  try {
    return await client.simulate(signerAddress, messages, memo)
  } catch (e) {
    assertSimulationSuccess(e)
    throw e
  }
}
