import { useMemo } from 'react'
import { Anchor, Box, Center, Text } from '@mantine/core'

import { useMediumScreen } from '@/hooks'
import { useSelectedCoin } from '@/contexts/wallet'
import { useAnnouncementFlags } from './queries'

const GlobalAnnouncement = () => {
  const { data } = useAnnouncementFlags()

  const selectedCoinDenom = useSelectedCoin()

  const { isMediumScreen } = useMediumScreen()

  // We need to provide a way to display custom messages for specific host zone.
  // These messages can be used to inform users about important updates or issues related to affected host zone.
  // We can add a custom message for a host zone by adding a new entry to the CUSTOM_MESSAGES object.
  // We should move this to a more generic location in the future but this works for now.

  const CUSTOM_MESSAGES: Record<
    string,
    {
      showMessage: boolean
      message: JSX.Element
    }
  > = {
    ATOM: {
      showMessage: false,
      message: (
        <>
          Some stATOM unbondings are delayed until 3pm ET on Wednesday, August 7th (learn more{' '}
          <Anchor href="https://www.stride.zone/blog/statom-unbondings" target="_blank">
            here
          </Anchor>
          ).
        </>
      )
    }
  }

  const message = useMemo(() => {
    if (selectedCoinDenom in CUSTOM_MESSAGES) {
      const { showMessage, message } = CUSTOM_MESSAGES[selectedCoinDenom]

      if (showMessage) {
        return message
      }
    }

    if (data?.custom) {
      return data.customMessage
    }

    if (data?.maintenance && data?.upgrade) {
      return 'Stride is currently undergoing maintenance and a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.upgrade) {
      return 'Stride is currently undergoing a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.maintenance) {
      return 'Stride is currently undergoing maintenance, and certain functionality may be affected. Everything should be normal soon!'
    }

    return ''
  }, [data, selectedCoinDenom])

  if (!message) {
    return null
  }

  return (
    <Box sx={(t) => ({ background: t.colors.yellow[1], padding: t.other.globalAnnouncement.padding })}>
      <Center>
        <Text inline={isMediumScreen} sx={(t) => ({ color: t.colors.gray[7] })}>
          {message}
        </Text>
      </Center>
    </Box>
  )
}

export { GlobalAnnouncement }
