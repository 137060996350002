import { TextHeading } from '@/components'
import { STRIDE_CHAIN_INFO } from '@/config'
import keplr from '@/images/wallets/keplr-missing-logo.svg'
import { WalletName, WalletRepo } from '@cosmos-kit/core'
import {
  Box,
  Button,
  Center,
  Group,
  Image,
  Modal,
  Space,
  Stack,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import isUserAgentMobile from 'is-mobile'
import NextImage from "next/legacy/image"
import React, { ReactNode, createContext, useContext, useState } from 'react'

type View = 'selection' | 'keplr:unsupported'

// @TODO: Move to `page-components`
const WalletSelectModal = ({
  walletRepo,
  onSelectWallet
}: {
  walletRepo: WalletRepo
  onSelectWallet: (walletType: WalletName | undefined) => void
}) => {
  const [view, setView] = useState<View>('selection')

  const [isDisclaimerExpanded, setIsDisclaimerExpanded] = useState(false)

  const toggleDisclaimerExpanded = () => {
    setIsDisclaimerExpanded((isDisclaimerExpanded) => !isDisclaimerExpanded)
  }

  const handleClose = () => onSelectWallet(undefined)

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  return (
    <Modal
      opened={true}
      onClose={handleClose}
      title={view === 'selection' ? 'Select a wallet' : ''}
      size="lg"
      zIndex={theme.other.zIndex.walletModal}
      styles={(t) => ({
        title: {
          fontSize: t.fontSizes.lg,
          fontWeight: 600,
          color: t.colors.gray[9]
        }
      })}>
      {view === 'keplr:unsupported' && <WalletUnsupported onClose={handleClose} />}

      {view === 'selection' && (
        <Stack spacing="sm">
          {walletRepo?.wallets
            .filter(({ walletInfo }) => {
              const mode = isUserAgentMobile() ? 'wallet-connect' : 'extension'
              return walletInfo.mode === mode
            })
            .map(({ walletName, walletInfo }) => {
              const handleClick = () => {
                if (walletName === 'keplr-mobile') {
                  setView('keplr:unsupported')
                } else {
                  onSelectWallet(walletName)
                }
              }

              return (
                <UnstyledButton key={walletName} onClick={handleClick}>
                  <Box
                    sx={(t) => ({
                      padding: t.spacing.sm,
                      background: t.colors.gray[1],
                      border: `1px solid ${t.colors.gray[3]}`,
                      borderRadius: t.radius.lg
                    })}>
                    <Group align="center" noWrap>
                      <Image
                        src={
                          walletInfo.name === 'cosmos-extension-metamask'
                            ? '/metamask.png'
                            : walletInfo.name === 'xdefi-extension'
                            ? '/xdefi.svg'
                            : typeof walletInfo.logo === 'string'
                            ? walletInfo.logo
                            : (walletInfo.logo as any).major
                        }
                        alt={walletInfo.name}
                        sx={(t) => ({
                          height: t.other.globalWalletConnectionSelectWalletModal.logoSize,
                          width: t.other.globalWalletConnectionSelectWalletModal.logoSize
                        })}
                      />

                      <Stack spacing="xs">
                        <Title
                          order={isMediumScreen ? 3 : 4}
                          sx={(t) => ({ lineHeight: t.other.globalWalletConnectionSelectWalletModal.textLineHeight })}>
                          {walletInfo.name === 'cosmos-extension-metamask' ? 'MetaMask' : walletInfo.prettyName}
                        </Title>
                      </Stack>
                    </Group>
                  </Box>
                </UnstyledButton>
              )
            })}

          <Box p="sm" sx={(t) => ({ background: t.colors.gray[1], borderRadius: t.radius.lg })}>
            <Stack spacing="sm">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                By connecting a wallet, you acknowledge that you have read and understand the Stride{' '}
                <Text sx={{ display: 'inline', cursor: 'pointer' }} underline onClick={toggleDisclaimerExpanded}>
                  Protocol Disclaimer
                </Text>
                .
              </Text>

              {isDisclaimerExpanded && (
                <>
                  <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                    Stride is a decentralized peer-to-peer blockchain that people can use to liquid stake their IBC
                    enabled tokens. The Stride blockchain is made up of free, public, and open-source software. Your use
                    of Stride involves various risks, including, but not limited, to losses while digital assets are
                    being supplied to Stride and losses due to validator related mechanisms, such as slashing. Before
                    liquid staking on the Stride blockchain, you should review the relevant documentation to make sure
                    you understand how Stride works. Additionally, just as you can access email protocols, such as SMTP,
                    through multiple email clients, you can access pools on Stride through several web or mobile
                    interfaces. You are responsible for doing your own diligence on those interfaces to understand the
                    fees and risks they present.
                  </Text>

                  <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                    AS DESCRIBED IN THE STRIDE LICENSES, THE STRIDE PROTOCOL IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND
                    WITHOUT WARRANTIES OF ANY KIND. Although Stride Labs ( “Stride Labs” ) developed much of the initial
                    code for the Stride protocol, it does not provide, own, or control the Stride protocol, which is run
                    by a decentralized validator set. Upgrades and modifications to the protocol are managed in a
                    community-driven way by holders of the ST governance token. No developer or entity involved in
                    creating the Stride protocol will be liable for any claims or damages whatsoever associated with
                    your use, inability to use, or your interaction with other users of the Stride protocol, including
                    any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of
                    profits, cryptocurrencies, tokens, or anything else of value.
                  </Text>
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </Modal>
  )
}

interface WalletUnsupportedProps {
  onClose: () => void
}

const WalletUnsupported: React.FC<WalletUnsupportedProps> = ({ onClose }) => {
  return (
    <>
      <Center>
        <Box
          sx={(t) => ({
            padding: t.spacing.xs,
            maxWidth: t.other.globalWalletConnectionWcQrModal.logoMaxWidth,
            borderRadius: t.radius.md,
            border: `1px dashed ${t.colors.gray[4]}`,
            lineHeight: t.other.globalWalletConnectionWcQrModal.titleLineHeight
          })}>
          <NextImage src={keplr} alt="Keplr Logo" />
        </Box>
      </Center>
      <Space h="md" />

      <TextHeading align="center" sx={(t) => ({ color: t.colors.gray[9], fontSize: 24 })}>
        Unsupported Wallet
      </TextHeading>

      <Space h="xs" />

      <Text align="center" sx={(t) => ({ color: t.colors.gray[7] })}>
        Mobile is not supported. In the mean time, we recommend that you try {STRIDE_CHAIN_INFO.chainName} on your
        desktop device.
      </Text>

      <Space h="lg" />

      <Group position="apart">
        <Box />

        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Group>
    </>
  )
}

export interface WalletSelectContext {
  selectWallet: (walletRepo: WalletRepo) => Promise<WalletName | undefined>
}

export const WalletSelect = createContext<WalletSelectContext>({
  selectWallet: async () => undefined
})

export const WalletSelectProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

  const selectWallet = (walletRepo: WalletRepo) => {
    // mount modal with a callback
    return new Promise<WalletName | undefined>((resolve) => {
      mountModal(walletRepo, (walletName) => {
        setModal(undefined)
        resolve(walletName)
      })
    })
  }

  function mountModal(walletRepo: WalletRepo, handleSelectWallet: (walletName: WalletName | undefined) => void) {
    const mounted = <WalletSelectModal walletRepo={walletRepo} onSelectWallet={handleSelectWallet} />
    setModal(mounted)
  }

  return (
    <WalletSelect.Provider value={{ selectWallet }}>
      <>
        {modal}
        {children}
      </>
    </WalletSelect.Provider>
  )
}

export const useWalletSelect = (): WalletSelectContext => useContext(WalletSelect)
