import { SelectedCoinDenom } from '@/wallet-utils'

export const MAX_AMOUNT_USD = 20
export const MAX_AMOUNT_USD_TEST = 0.05

// @TODO: Consider moving this to `chain-config` as `CHAIN_SUPPORTS_DUST_SWEEPER`
// @TODO: Maybe enable TIA and DYM
export const UNSUPPORTED_DENOMS: SelectedCoinDenom[] = [
  // These two are supported by the route we'll use through the Skip API
  // however, we need to adjust some code to accommodate for these. During simulation,
  // we would get an error saying that we don't have enough spendable balance
  // despite having more than enough.
  'DYM',
  'EVMOS',
  'DYDX',
  // These tokens are not supported by the route we'll use through the Skip API
  'INJ',
  'LUNA',
  'CMDX',
  'ISLM',
  'BAND' //@TODO: Confirm if BAND is supported by the Skip API
]

// @TODO: Consider moving this to DustSweeper directory
export const STEP_TEXT: Record<string, string> = {
  '1': 'Submit IBC transaction...',
  '2': 'Transfer tokens to Osmosis...',
  '3': 'Swap tokens on Osmosis...',
  '4': 'Transfer tokens to Stride...'
}
