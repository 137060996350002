import { strideAccountParser } from 'stridejs'
import { Any } from 'cosmjs-types/google/protobuf/any'
import { BaseAccount } from 'cosmjs-types/cosmos/auth/v1beta1/auth'
import { Account } from '@cosmjs/stargate'
import { Uint64 } from '@cosmjs/math'
import { injectiveAccountParser } from '@injectivelabs/sdk-ts'
import { assert } from '@/utils'
import { decodePubkey } from '../haqq/pubkey'
import { EthAccount } from './ethermint'

function accountFromBaseAccount(input: BaseAccount): Account {
  const { address, pubKey, accountNumber, sequence } = input

  const pubkey = pubKey ? decodePubkey(pubKey) : null

  return {
    address,
    pubkey,
    accountNumber: Uint64.fromString(accountNumber.toString()).toNumber(),
    sequence: Uint64.fromString(sequence.toString()).toNumber()
  }
}

export function accountParser(input: Any): Account {
  switch (input.typeUrl) {
    case '/cosmos.auth.v1beta1.BaseAccount':
      return accountFromBaseAccount(BaseAccount.decode(input.value))

    case '/injective.types.v1beta1.EthAccount': {
      return injectiveAccountParser(input)
    }

    // https://github.com/Team-Kujira/kujira.js/blob/44085a0d1c8ca3e2d4efca4daf3c2eb70fde9af4/src/registry.ts#L127
    case '/ethermint.types.v1.EthAccount': {
      const account = EthAccount.decode(input.value)
      const baseEthAccount = account.baseAccount
      assert(baseEthAccount, 'EthAccount baseAccount is undefined.')
      const pubKeyEth = baseEthAccount.pubKey

      return {
        address: baseEthAccount.address,
        pubkey: pubKeyEth
          ? {
              type: '/ethermint.crypto.v1.ethsecp256k1.PubKey',
              value: Buffer.from(pubKeyEth.value).toString('base64')
            }
          : null,
        accountNumber: Number(baseEthAccount.accountNumber.toString()),
        sequence: Number(baseEthAccount.sequence.toString())
      }
    }

    default: {
      return strideAccountParser(input)
    }
  }
}
