import * as React from 'react'
import { SVGProps } from 'react'
const SvgClaim = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.315 5.424h1.976a2.282 2.282 0 0 1 2.282 2.282v12.165a2.282 2.282 0 0 1-2.282 2.282H4.129a2.282 2.282 0 0 1-2.282-2.282V7.706A2.282 2.282 0 0 1 4.13 5.424h2.11"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.21 1.847v13.477l-2.788-2.788M10.207 15.319l2.788-2.788"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgClaim
