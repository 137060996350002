import * as React from 'react'
import { SVGProps } from 'react'
const SvgTest = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.813 17.17H4.367c-2.044 0-3.295-2.242-2.224-3.98l3.98-6.446c.081-.171.117-.36.117-.559V.9h4.664l.036 5.294c0 .19.045.379.117.55l3.98 6.446c1.07 1.738-.18 3.98-2.225 3.98Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgTest
