import { useEffect, useRef } from 'react'

type EffectCallback = () => Cleanup

type Cleanup = void | (() => void)

// useEffect that runs on mount and works with Suspense / Fast Refresh
const useMount = (effectFn: EffectCallback) => {
  const isMountedRef = useRef(false)

  useEffect(() => {
    if (isMountedRef.current) return
    isMountedRef.current = true
    return effectFn()
  }, [])
}

export { useMount }
