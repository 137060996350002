import React from 'react'
import { Tooltip, Group, Sx, MantineNumberSize } from '@mantine/core'
import { Icon } from '@/components'

interface InfoBeaconProps {
  width?: number
  sx?: Sx
  size?: MantineNumberSize
  content: string
}

const InfoBeacon: React.FC<InfoBeaconProps> = ({ content, width, sx, size }) => {
  return (
    <Tooltip transition="fade" label={content} position="bottom" width={width} withArrow wrapLines>
      <Icon name="infoCircle" sx={sx} size={size} />
    </Tooltip>
  )
}

interface InfoBeaconContainerProps {
  children: React.ReactNode
}

const InfoBeaconContainer: React.FC<InfoBeaconContainerProps> = ({ children }) => {
  return (
    <Group align="center" spacing="xs">
      {children}
    </Group>
  )
}

export { InfoBeacon, InfoBeaconContainer }
