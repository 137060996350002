import { Pubkey } from '@cosmjs/amino'
import { encodePubkey } from './pubkey'
import { SignMode } from 'cosmjs-types/cosmos/tx/signing/v1beta1/signing'
import { GetTxRequest, ServiceClientImpl, SimulateRequest } from 'cosmjs-types/cosmos/tx/v1beta1/service'
import { AuthInfo, Fee, Tx, TxBody } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { Any } from 'cosmjs-types/google/protobuf/any'
import { createProtobufRpcClient, QueryClient, TxExtension } from '@cosmjs/stargate'

// @source https://github.com/cosmos/cosmjs/blob/f71cdc35a815f76842055ab73b89b24428698527/packages/stargate/src/modules/tx/queries.ts
// A direct copy of @cosmjs/stargate/modules/tx/queries.ts, but with our custom `encodePubkey` function (which introduces EthSecp256k1)
export function setupTxExtension(base: QueryClient): TxExtension {
  // Use this service to get easy typed access to query methods
  // This cannot be used for proof verification
  const rpc = createProtobufRpcClient(base)
  const queryService = new ServiceClientImpl(rpc)

  return {
    tx: {
      getTx: async (txId: string) => {
        const request: GetTxRequest = {
          hash: txId
        }
        const response = await queryService.GetTx(request)
        return response
      },
      simulate: async (messages: readonly Any[], memo: string | undefined, signer: Pubkey, sequence: number) => {
        const tx = Tx.fromPartial({
          authInfo: AuthInfo.fromPartial({
            fee: Fee.fromPartial({}),
            signerInfos: [
              {
                publicKey: encodePubkey(signer),
                sequence: BigInt(sequence),
                modeInfo: { single: { mode: SignMode.SIGN_MODE_UNSPECIFIED } }
              }
            ]
          }),
          body: TxBody.fromPartial({
            messages: Array.from(messages),
            memo: memo
          }),
          signatures: [new Uint8Array()]
        })
        const request = SimulateRequest.fromPartial({
          txBytes: Tx.encode(tx).finish()
        })
        const response = await queryService.Simulate(request)
        return response
      }
    }
  }
}
