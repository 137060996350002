import * as React from 'react'
import { SVGProps } from 'react'
const SvgRealRewards = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M99.052 23.631c-20.021 1.47-21.212 2.662-22.683 22.683-1.469-20.02-2.662-21.212-22.683-22.682C73.707 22.162 74.899 20.97 76.369.948c1.47 20.022 2.662 21.213 22.683 22.683Zm-23.25 37.995c-33.035 2.424-35 4.391-37.427 37.426-2.424-33.035-4.392-35-37.427-37.427 33.035-2.424 35-4.392 37.427-37.427 2.424 33.035 4.392 35.001 37.427 37.428Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgRealRewards
