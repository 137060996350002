import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useSelectedCoin } from '@/contexts/wallet'
import { assert } from '@/utils'
import { CHAIN_SUPPORTS_REFERRAL } from '@/config'

interface ReferralContextType {
  referral: string | null
  memo: string
}

const ReferralContext = createContext<ReferralContextType | null>(null)

const ReferralProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [referral, setReferral] = useState<ReferralContextType['referral']>(null)

  const router = useRouter()

  const denom = useSelectedCoin()

  // We've launched a referral program on stride that only works with TIA, ATOM, & DYDX.
  // First we'll check if `referral` is present on the url. After than we'll save it
  // to StakeProvider's internal state and remove `referral` from query parameters.
  useEffect(() => {
    if (router.isReady) {
      const { referral } = router.query

      if (referral && typeof referral === 'string' && CHAIN_SUPPORTS_REFERRAL[denom]) {
        setReferral(referral.substring(0, 6))
      }
    }
  }, [router.isReady, denom])

  return (
    <ReferralContext.Provider
      value={{
        referral,
        memo: referral ? `Referral:${referral}` : ''
      }}>
      {children}
    </ReferralContext.Provider>
  )
}

const useReferral = () => {
  const context = useContext(ReferralContext)

  assert(context, 'Forgot to mount ReferralProvider')

  return context
}

export { ReferralProvider, useReferral }
