import * as React from 'react'
import { SVGProps } from 'react'
const SvgDustSweeper = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.114 9.64c6.222.99 7.269 2.037 8.26 8.259.023.15.242.15.266 0 .99-6.222 2.038-7.269 8.26-8.26.15-.024.15-.242 0-.266-6.222-.99-7.27-2.038-8.26-8.26-.024-.15-.243-.15-.267 0-.99 6.222-2.037 7.269-8.259 8.26-.152.024-.152.242 0 .266ZM18.15 13.792c-.016-.1-.162-.1-.178 0-.657 4.126-1.35 4.82-5.478 5.478-.1.015-.1.161 0 .177 4.126.657 4.82 1.35 5.478 5.478.016.1.162.1.177 0 .658-4.127 1.351-4.82 5.478-5.478.1-.016.1-.162 0-.177-4.126-.657-4.82-1.351-5.478-5.478Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgDustSweeper
