import { DefiIntegration, DefiIntegrationAdapterParameters, DefiIntegrationBalancesAdapterReturnType } from '../types'
import { assert, fatal } from '@/utils'
import { CosmWasmClient } from 'cosmwasm'
import { CHAIN_INFO_LIST } from '@/config'
import { redis } from '@/redis'
import { SelectedCoinDenom } from '@/wallet-utils'
import { getDenomsFromPoolId } from './utils'

interface ForgePoolMap {
  id: string
  stakedDenom: string
  denom: string
}

// @WARNING: When we add more pools (via Redis), it's important to
// update this list as well. Otherwise, user's tokens on the new liquidity
// pool will not show up. On the bright side, nothing will break.
// @TODO: Consider fetching this through Forge API or contracts if possible
const FORGE_POOL_META: Record<string, ForgePoolMap> = {
  'stATOM/ATOM': {
    id: '0xa81437305992465a4200a265329d4434c2f2e2d2',
    stakedDenom: '0xb5124fa2b2cf92b2d469b249433ba1c96bdf536d',
    denom: '0xc5e00d3b04563950941f7137b5afa3a534f0d6d6'
  },
  'stEVMOS/axlUSDC': {
    id: '0x709acb2224bc4dc768f9ff10402851a697ee0ba4',
    stakedDenom: '0x15c3eb3b621d1bff62cba1c9536b7c1ae9149b57',
    denom: '0x2c68d1d6ab986ff4640b51e1f14c716a076e44c4'
  },
  'stEVMOS/stATOM': {
    id: '0x3b39ccfb4114bfba70fe4e4834f953a1433d70ee',
    stakedDenom: '0x2c68d1d6ab986ff4640b51e1f14c716a076e44c4',
    denom: '0xb5124fa2b2cf92b2d469b249433ba1c96bdf536d'
  },
  'stEVMOS/STRD': {
    id: '0x5a372b4c9fbac40695db3337fca4a46a1dc66942',
    stakedDenom: '0x2c68d1d6ab986ff4640b51e1f14c716a076e44c4',
    denom: '0x8fa78ceb7f04118ec6d06aac37ca854691d8e963'
  }
}

// @TODO: This isn't working at all. It's likely this was based on another Adapter
// (I suspect Shade), and either [we forgot to put in the details] or [this was supposedly
// a "coming soon"]. Eitherway, we need to talk to Evmos team and ask how this works.
const adapter =
  () =>
  async ({
    pools,
    accounts
  }: DefiIntegrationAdapterParameters): Promise<DefiIntegrationBalancesAdapterReturnType[]> => {
    const poolShares = Object.fromEntries(
      await Promise.all(
        Object.entries(FORGE_POOL_META)
          .filter(([poolKey]) => pools.find((pool) => `${pool.stakedDenom}/${pool.denom}` === poolKey))
          .map(async ([poolKey, poolData]) => {
            const { stakedDenom, denom } = getDenomsFromPoolId(poolKey)
            // @TODO: https://github.com/Stride-Labs/interface/issues/537
            // @ts-ignore
            const protocol: Partial<SelectedCoinDenom> = poolData.protocol

            const protocolChain = CHAIN_INFO_LIST[protocol]

            // current system passes in one address at a time but we need to get the address for two protocols
            // TODO move addresses in general to the adapters
            const protocolAddress = accounts.find((account) => {
              const accountChain = CHAIN_INFO_LIST[account.denom]
              return accountChain.stakeCurrency.coinDenom === protocol
            })?.address

            // @TODO: This isn't working at all. It's likely this was based on another Adapter
            // (I suspect Shade), and either [we forgot to put in the details] or [this was supposedly
            // a "coming soon"]. Eitherway, we need to talk to Evmos team and ask how this works.
            if (!protocolAddress) {
              return []
            }

            const [_, stakedDenomUsdValue, denomUsdValue] = await Promise.all([
              CosmWasmClient.connect(protocolChain.rpc),
              redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${stakedDenom}`),
              redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${denom}`)
            ])

            if (!stakedDenomUsdValue || !denomUsdValue) {
              throw fatal(`Missing price for ${stakedDenom} or ${denom}`)
            }

            return [
              poolKey,
              {
                bondedAmount: 0, // @TODO @josefleventon Hardcoded to 0 for now, see previous TODO.
                unbondedAmount: 0 // @TODO @josefleventon Hardcoded to 0 for now, see previous TODO.
              }
            ]
          })
      )
    )

    return pools.map((pool) => {
      const { bondedAmount, unbondedAmount } = poolShares[pool.stakedDenom + '/' + pool.denom]

      return {
        poolId: pool.poolId,
        unbondedAmount: unbondedAmount.toNumber(),
        bondedAmount: bondedAmount.toNumber()
      }
    })
  }

const getForgePoolPage: DefiIntegration['poolPage'] = (pool) => {
  const meta = FORGE_POOL_META[pool.poolId]
  assert(meta, `Unable to find pool meta configuration for Forge pool ${pool.poolId}`)
  return `https://forge.trade/#/add/${meta.stakedDenom}/${meta.denom}`
}

export { adapter, getForgePoolPage }
