import axios from 'axios'
import BigNumber from 'bignumber.js'
import { DEX_CONFIG } from '@/config'
import { assert } from '@/utils'
import { DexAdapter } from './types'
import { z } from 'zod'

const osmosis: DexAdapter = async ({ selectedCoinDenom, priceInUsd }) => {
  const dexConfig = DEX_CONFIG[selectedCoinDenom]

  assert(dexConfig, `Missing dex config for ${selectedCoinDenom}`)

  const params = {
    base_asset_denom: dexConfig.stakedIbcDenom,
    quote_asset_denom: dexConfig.ibcDenom
  }

  const response = await axios.get(
    `https://osmosis-api.polkachu.com/osmosis/gamm/v2/pools/${dexConfig.poolId}/prices`,
    { params }
  )

  const { spot_price } = OsmosisPoolManagerV2PricesSchema.parse(response.data)

  return {
    tokenValue: new BigNumber(1).dividedBy(spot_price).toNumber(),
    stTokenValue: Number(spot_price),
    stTokenValueInUsd: new BigNumber(spot_price).multipliedBy(priceInUsd).toNumber()
  }
}

const OsmosisPoolManagerV2PricesSchema = z.object({
  spot_price: z.string()
})

export { osmosis }
