import { addDays, format } from 'date-fns'

const formatUnbondingDate = (date: number | Date) => {
  return format(date, 'MMM d, yyyy')
}

const formatUnbondingRange = (base: string | number) => {
  const baseDate = new Date(base)
  return `${formatUnbondingDate(baseDate)} - ${formatUnbondingDate(addDays(baseDate, 4))}`
}

export { formatUnbondingDate, formatUnbondingRange }
