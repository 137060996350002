import * as React from 'react'
import { SVGProps } from 'react'
const SvgLightning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.988 7.05a1 1 0 0 1 .89.55 1 1 0 0 1-.08 1.04l-8 11a1 1 0 0 1-1.81-.59v-6h-5a1 1 0 0 1-.89-.64 1 1 0 0 1 .08-1l8-11a1 1 0 0 1 1.634.023 1 1 0 0 1 .176.617v6h5Zm-7 5v3.92l5-6.92h-4a1 1 0 0 1-1-1V4.13l-5 6.92h4a1 1 0 0 1 1 1Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLightning
