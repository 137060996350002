import * as React from 'react'
import { SVGProps } from 'react'
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={8} fill="currentColor" />
    <path
      d="m5.333 8 1.905 1.905 3.81-3.81"
      stroke="#fff"
      strokeWidth={1.524}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgCheckCircle
